import React from "react"
import Box from "@material-ui/core/Box"
import LocalActivityIcon from "@material-ui/icons/LocalActivity"
import Typography from "Theme/Typography"
import Button from "Theme/Button"
import { withStyles } from "@material-ui/core"


const Styles = withStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2.5),
    borderBottomLeftRadius: theme.spacing(0.8),
    borderBottomRightRadius: theme.spacing(0.8),
    backgroundColor: "#42454E",
    borderTop: "3px solid #42A8F3",
  },
  icon: {
    fontSize: theme.spacing(7),
  },
  button: {
    fontSize: "13px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    lineHeight: 1.2,
  },
}))

const CreateContestCard = ({ classes }) => (
  <Box className={classes.container}>
    <LocalActivityIcon className={classes.icon} />
    <Box paddingY={1.5}>
      <Typography>
        Create a Contest
      </Typography>
    </Box>
    <Box paddingBottom={1.5}>
      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </Typography>
    </Box>
    <Button
      variant="outlined"
      className={classes.button}
    >
      Create contest
    </Button>
  </Box>
)


export default Styles(CreateContestCard)
