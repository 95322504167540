
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as CanvasIcon } from "../fontello/icons/svg/ic_canvas.svg"


const Canvas = ({ ...props }) => (
  <SvgIcon
    component={CanvasIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Canvas
