
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as DownloadIcon } from "../fontello/icons/svg/ic_download.svg"


const Download = ({ ...props }) => (
  <SvgIcon
    component={DownloadIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Download
