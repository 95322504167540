
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as MenuIcon } from "../fontello/icons/svg/ic_menu.svg"


const Menu = ({ ...props }) => (
  <SvgIcon
    component={MenuIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Menu
