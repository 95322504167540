import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import MapInfoBlock from "Components/MapInfoRow"

const useStyles = makeStyles((theme) => ({
  mainImage: {
    flex: "1 1 100%",
    minHeight: theme.spacing(12),
  },
  image: {
    backgroundSize: "cover",
    "&:first-child": {
      margin: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}))

const ActivityCollectionCard = ({
  title,
  mapsCount,
  mapSize,
  likes,
  mapsImages,
}) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      borderRadius="borderRadius"
      bgcolor="background.BG3"
    >
      <Box
        key={mapsImages[0]}
        display="flex"
        flexDirection="column"
        justifyContent="end"
        borderRadius="borderRadius"
        paddingY={1}
        paddingX={2}
        minHeight={50}
        marginTop={1}
        marginLeft={1}
        flex="1 1 25%"
        className={clsx(classes.image, classes.mainImage)}
        style={{
          backgroundImage: `linear-gradient(
            180deg,
            rgba(32, 33, 38, 0.2) 0%,
            rgba(32, 33, 38, 0.6446) 44.01%,
            rgba(32, 33, 38, 0.92) 100%
          ),
          url(${mapsImages[0]})`,
        }}
      >
        <MapInfoBlock
          title={title}
          mapsCount={mapsCount}
          mapSize={mapSize}
          likes={likes}
        />
      </Box>
      {mapsImages.map(
        (image, index) =>
          index !== 0 && (
            <Box
              key={image}
              borderRadius="borderRadius"
              minHeight={50}
              marginTop={1}
              marginRight={1}
              flex="1 1 25%"
              className={classes.image}
              style={{
                backgroundImage: `linear-gradient(
              180deg,
              rgba(32, 33, 38, 0.2) 0%,
              rgba(32, 33, 38, 0.6446) 44.01%,
              rgba(32, 33, 38, 0.92) 100%
            ),
            url(${image})`,
              }}
            />
          ),
      )}
    </Box>
  )
}

export default ActivityCollectionCard
