import { withStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import FavoriteIcon from "@material-ui/icons/Favorite"
import CarouselButtons from "Components/CarouselButtons"
import { useState } from "react"
import Button from "Theme/Button"


const Styles = withStyles((theme) => ({
  moneyContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: "end",
    backgroundColor: "#2078FC",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  moneyIcon: {
    fontSize: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  favoriteIcon: {
    fontSize: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  divider: {
    width: "1px",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: theme.spacing(1.6),
  },
}))


const TopPlacement = ({ classes, place, images, mapSize, mapRating, pro }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedImage = images[selectedIndex]
  const handlePrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    } else {
      setSelectedIndex(images.length - 1)
    }
  }
  const handleNext = () => {
    if (selectedIndex === (images.length - 1)) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop={5}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight={450}
        borderRadius={8}
        style={{
          backgroundImage: `url(${selectedImage})`,
          backgroundSize: "cover",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingX={6}
          paddingY={4}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <FavoriteIcon className={classes.favoriteIcon} />
            <Typography
              variant="subtitle1"
              color="textPrimary"
            >
              {place} Place
            </Typography>
          </Box>
          <CarouselButtons
            onPrev={handlePrev}
            onNext={handleNext}
            currentValue={selectedIndex + 1}
            maxValue={images.length}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          marginX={15}
        >
          <Box className={classes.moneyContainer}>
            <AccountBalanceWalletIcon className={classes.moneyIcon} />
            <Typography color="textPrimary">$25</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            borderTop="3px solid #2078FC"
            bgcolor="#35383E"
            paddingX={3}
            paddingY={1}
          >
            <Box
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="h5"
                color="textPrimary"
              >
                Maar Dun
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box
                className={classes.avatar}
                component="img"
                src="https://pickaface.net/gallery/avatar/MissGriffith529ca4c121402.png"
              />
              <Box
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Typography color="textPrimary">
                    MartianMallCop
                  </Typography>
                  {pro && (
                    <Box
                      bgcolor="#2078FC"
                      borderRadius={10}
                      paddingX={1}
                      marginLeft={1}
                    >
                      <Typography>PRO</Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Typography>
                    4th nom • 1st win
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        paddingX={17}
        paddingTop={3}
        paddingBottom={1}
      >
        <Typography>
          Beginning our lineup is a map I won't even pretend to know how to
          pronounce. MAAR DÚN's creator, MartianMallCop is no stranger to
          level-design, and has once again carved their way to the top. Perhaps
          the most stunning quality of MAAR DÚN isn't the intense 2v2 gameplay
          or vampiric aesthetics, but the development time. MartianMallCop has
          spent the better part of 2 years perfecting the map. That kind of
          quality is what you can always count on in our community.
        </Typography>
      </Box>
      <Box paddingX={17}>
        <Button className={classes.button}>View map</Button>
      </Box>
    </Box>
  )
}


export default Styles(TopPlacement)
