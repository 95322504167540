export const ROUTES = Object.freeze({
  INDEX: "/",

  AUTH: "auth",
  // SIGNUP: "signup",
  LOGIN: "login",
  WELCOME: "welcome",
  // RESET_PASSWORD: "reset-password",
  // NEW: "new"

  HOME: "home",
  EVENTS: "contests",
  FORUMS: "forums",

  MAPS: "maps",
  MODES: "modes",
  PREFABS: "prefabs",

  GUIDES: "guides",

  COLLECTIONS: "collections",
  CONTESTS: "contests",
  PRO: "pro",
  COMMUNITY_FAVORITES: "community-favorites",

  // for testing purposes
  TEST: "test",
  FORGE_MAP: "forge-map",
  PROFILE: "profile",
  ACTIVITY: "activity",
  ACHIEVEMENTS: "achievements",
  INFORMATION: "information",

  RESET_PASSWORD: "reset-password",
  SET_PASS: "set-password",
})
