import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import ConditionalWrap from "conditional-wrap"
import { Else, If, Then, When } from "react-if"
import Card, { CardActions, CardContent, CardMedia } from "Theme/Card"
import { Link } from "react-router-dom"
import Typography from "Theme/Typography"
import GameTypeIcon from "Components/GameTypeIcon"
import DownloadIcon from "Theme/icons/Download"
import ForumsIcon from "Theme/icons/Forums"
import HeartIcon from "Theme/icons/Heart"
import PeopleIcon from "Theme/icons/People"

const useStyles = makeStyles((theme) => ({
  root: {},
  primary: {
    height: "484px",
  },
  active: {
    cursor: "pointer",
    transition: theme.transitions.create(["transform", "box-shadow"]),

    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows[6],
    },
  },
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  starIcon: {
    paddingRight: -theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    height: "100%",
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoGroup: {
    display: "flex",
    flexDirection: "row",

    "& > *:not(& > *:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.palette.text.secondary,
    ...theme.typography.caption,

    "& > *:first-child": {
      marginRight: theme.spacing(0.5),
    },
  },
}))

const MapCard = ({
  map: {
    title,
    link,
    image,
    className,
    gameType,
    downloadsCount = 999,
    messagesCount = 999,
    likesCount = 999,
    variant,
    teamMode = [0, 0],
  },
  ...otherProps
}) => {
  const classes = useStyles()

  const isPrimary = variant === "primary"

  return (
    <Card
      className={clsx(classes.root, className, link && classes.active, isPrimary && classes.primary)}
      {...otherProps}
    >
      <ConditionalWrap
        condition={!!link}
        wrap={(children) => (
          <Link
            draggable={false}
            className={clsx(classes.link, className)}
            to={link}
          >
            {children}
          </Link>
        )}
      >
        <CardMedia>
          <img
            src={image}
            className={classes.backgroundImage}
            alt="Map as background"
          />
        </CardMedia>

        <CardContent className={classes.body}>
          <Typography
            variant={isPrimary ? "h3" : "h5"}
            color="textPrimary"
          >
            {title}
          </Typography>

          <CardActions className={classes.footer}>
            {/* #TEMP */}
            <Box className={classes.infoGroup}>
              <Box className={classes.info}>
                <GameTypeIcon
                  type={gameType}
                  fontSize="inherit"
                  color="textSecondary"
                />

                <Typography variant="caption">{gameType}</Typography>
              </Box>

              <When condition={variant === "primary"}>
                <Box className={classes.info}>
                  <PeopleIcon fontSize="inherit" />
                  <Typography variant="caption">
                    {teamMode[0]}v{teamMode[1]}
                  </Typography>
                </Box>
              </When>
            </Box>

            <Box className={classes.infoGroup}>
              <If condition={variant === "primary"}>
                <Then>
                  <Box className={classes.info}>
                    <DownloadIcon fontSize="inherit" />

                    <Typography variant="caption">{downloadsCount}</Typography>
                  </Box>

                  <Box className={classes.info}>
                    <ForumsIcon fontSize="inherit" />

                    <Typography variant="caption">{messagesCount}</Typography>
                  </Box>
                </Then>

                <Else>
                  <Box className={classes.info}>
                    <PeopleIcon fontSize="inherit" />
                    <Typography variant="caption">
                      {teamMode[0]}v{teamMode[1]}
                    </Typography>
                  </Box>
                </Else>
              </If>

              <Box className={classes.info}>
                <HeartIcon fontSize="inherit" />

                <Typography variant="caption">{likesCount}</Typography>
              </Box>
            </Box>
          </CardActions>
        </CardContent>
      </ConditionalWrap>
    </Card>
  )
}

export default MapCard
