
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as PlusIcon } from "../fontello/icons/svg/ic_plus.svg"


const Plus = ({ ...props }) => (
  <SvgIcon
    component={PlusIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Plus
