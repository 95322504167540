import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import Slider from "Theme/Slider"
import Tooltip from "Theme/Tooltip"


const PlayersCountSlider = ({ value, ...props }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      display="flex"
      paddingRight={4}
    >
      <Typography>Min:</Typography>
      <Box paddingLeft={0.5}>
        <Typography
          color="textPrimary"
          style={{
            minWidth: 70,
            whiteSpace: "nowrap",
          }}
        >
          {value[0]} players
        </Typography>
      </Box>
    </Box>
    <Slider
      value={value}
      min={2}
      max={16}
      valueLabelDisplay="on"
      ValueLabelComponent={Tooltip}
      {...props}
    />
    <Box
      display="flex"
      paddingLeft={4}
    >
      <Typography>Max:</Typography>
      <Box paddingLeft={0.5}>
        <Typography
          color="textPrimary"
          style={{
            minWidth: 70,
            whiteSpace: "nowrap",
          }}
        >
          {value[1]} players
        </Typography>
      </Box>
    </Box>
  </Box>
)

export default PlayersCountSlider
