import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { default as MapImage1 } from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import MapCard from "Components/MapCard"
import PlateContainer from "Components/Plate/PlateContainer"
import PlateItem from "Components/Plate/PlateItem"
import ProfileHeader from "Components/ProfileHeader"
import Tab from "Components/Tab"
import { ROUTES } from "Scenes/constants"
import { Redirect, Route, useLocation } from "react-router"
import { Switch } from "react-router-dom"
import { Core, Infection } from "Theme/icons"
import Achievements from "./Achievements"
import Activity from "./Activity"
import Collections from "./Collections"
import Information from "./Information"
import Maps from "./Maps"


const {
  INDEX,
  MAPS,
  ACTIVITY,
  COLLECTIONS,
  ACHIEVEMENTS,
  INFORMATION,
  HOME } = ROUTES


const maps = [
  {
    id: "map1",
    title: "Map1",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Strongholds",
    icon: Core,
    comments: 766,
    path: `${INDEX}${MAPS}/1`,
    favorite: true,
    awarded: true,
    liked: true,
  },
  {
    id: "map2",
    title: "Map2",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/2`,
  },
  {
    id: "map3",
    title: "Map3",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/3`,
  },
  {
    id: "map4",
    title: "Map4",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/4`,
  },
  {
    id: "map5",
    title: "Map5",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/5`,
  },
  {
    id: "map6",
    title: "Map6",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/6`,
  },
  {
    id: "map7",
    title: "Map7",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/7`,
  },
  {
    id: "map8",
    title: "Map8",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/8`,
  },
  {
    id: "map9",
    title: "Map9",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/9`,
  },
]


function Profile({ path }) {
  const location = useLocation()

  return (
    <Route path={path}>
      <Container>
        <ProfileHeader />
        <Box display="flex">
          <Tab
            isActive={location.pathname === `${path}/${HOME}`}
            to={`${path}/${HOME}`}
            name="Home"
          />
          <Tab
            isActive={location.pathname === `${path}/${MAPS}`}
            to={`${path}/${MAPS}`}
            name="Maps"
          />
          <Tab
            isActive={location.pathname === `${path}/${COLLECTIONS}`}
            to={`${path}/${COLLECTIONS}`}
            name="Collections"
          />
          <Tab
            isActive={location.pathname === `${path}/${ACTIVITY}`}
            to={`${path}/${ACTIVITY}`}
            name="Activity"
          />
          <Tab
            isActive={location.pathname === `${path}/${ACHIEVEMENTS}`}
            to={`${path}/${ACHIEVEMENTS}`}
            name="Achievements"
          />
          <Tab
            isActive={location.pathname === `${path}/${INFORMATION}`}
            to={`${path}/${INFORMATION}`}
            name="Information"
          />
        </Box>

        <Switch>
          <Maps path={`${path}/${MAPS}`} />

          <Collections path={`${path}/${COLLECTIONS}`} />

          <Activity path={`${path}/${ACTIVITY}`} />

          <Achievements path={`${path}/${ACHIEVEMENTS}`} />

          <Information path={`${path}/${INFORMATION}`} />

          <Route path={`${path}/${HOME}`}>
            <Box>
              <Box>
                <PlateContainer marginTop={3}>
                  <PlateItem
                    x={2}
                    y={2}
                  >
                    <MapCard
                      title={maps[0].title}
                      image={maps[0].image}
                      author={maps[0].author}
                      path={maps[0].path}
                      liked={maps[0].liked}
                      favorite={maps[0].favorite}
                      awarded={maps[0].awarded}
                      icon={maps[0].icon}
                      gameType={maps[0].gameType}
                      mapSize={maps[0].mapSize}
                      downloads={maps[0].downloads}
                      comments={maps[0].comments}
                      likes={maps[0].likes}
                    />
                  </PlateItem>
                  <PlateItem
                    x={2}
                    y={1}
                  >
                    <MapCard
                      title={maps[1].title}
                      image={maps[1].image}
                      author={maps[1].author}
                      path={maps[1].path}
                      liked={maps[1].liked}
                      favorite={maps[1].favorite}
                      awarded={maps[1].awarded}
                      icon={maps[1].icon}
                      gameType={maps[1].gameType}
                      mapSize={maps[1].mapSize}
                      downloads={maps[1].downloads}
                      comments={maps[1].comments}
                      likes={maps[1].likes}
                    />
                  </PlateItem>
                  {maps.slice(2).map((map) => (
                    <PlateItem
                      key={map.id}
                      x={1}
                      y={1}
                    >
                      <MapCard
                        title={map.title}
                        image={map.image}
                        author={map.author}
                        path={map.path}
                      />
                    </PlateItem>
                  ))}
                </PlateContainer>
              </Box>
            </Box>
          </Route>
          <Redirect
            exact
            to={`${path}/${HOME}`}
          />
        </Switch>
      </Container>
    </Route>
  )
}

export default Profile
