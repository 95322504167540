import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import ExternalLink from "Components/ExternalLink"
import TwitterIcon from "@material-ui/icons/Twitter"
import YouTubeIcon from "@material-ui/icons/YouTube"
import RssFeedIcon from "@material-ui/icons/RssFeed"

const useStyles = makeStyles((theme) => ({
  externalLink: {
    marginRight: theme.spacing(2),

    "& svg": {
      color: theme.palette.text.tertiary,
    },
  },
}))

const SocialLinks = () => {
  const classes = useStyles()

  return (
    <Box>
      <ExternalLink
        className={classes.externalLink}
        to="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      >
        <YouTubeIcon fontSize="small" />
      </ExternalLink>
      <ExternalLink
        className={classes.externalLink}
        to="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      >
        <TwitterIcon fontSize="small" />
      </ExternalLink>
      <ExternalLink
        className={classes.externalLink}
        to="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
      >
        <RssFeedIcon fontSize="small" />
      </ExternalLink>
    </Box>
  )
}

export default SocialLinks
