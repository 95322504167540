import { makeStyles } from "@material-ui/core"
import React from "react"
import clsx from "clsx"

   const useStyles = makeStyles((theme=>({
    root: {
      textDecoration: "none",
      color: "inherit",
    },
   })))

const ExternalLink = ({ to, children, className }) => {
  const classes = useStyles()
  return (
    <a
      className={clsx(className, classes.root)}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )}
export default ExternalLink
