import CardContent from "@material-ui/core/CardContent"
import { withStyles } from "@material-ui/core/styles"

const StyledCardContent = withStyles(theme => ({
  root: {
    position: "relative",
    padding: theme.spacing(2, 3, 2, 3),

    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}))(CardContent)


export default StyledCardContent
