
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as FilterIcon } from "../fontello/icons/svg/ic_filter.svg"


const Filter = ({ ...props }) => (
  <SvgIcon
    component={FilterIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Filter
