
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as InfectionIcon } from "../fontello/icons/svg/ic_infection.svg"


const Infection = ({ ...props }) => (
  <SvgIcon
    component={InfectionIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Infection
