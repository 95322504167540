import {
  Box,

  Divider,
  Grid,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { Route } from "react-router-dom"
import Aside from "./Components/Aside"
import Comments from "./Components/Comments"
import Description from "./Components/Description"
import Subheader from "./Components/Subheader"

const mockSrc =
  "https://forgehub-nodejs-develop-bucket-cdn.s3.us-west-1.amazonaws.com/Uploads/Entity/media/cf635593-9a4d-4bfa-a83a-56ac4f8a6ea1/hero_halo-5-guardians-11-jpg.77902.jpg"

const useStyles = makeStyles((theme) => ({
  topGradient: {
    width: "100%",
    position: "absolute",
    height: "50%",
    background:
      "linear-gradient(0deg, rgba(46, 48, 54, 0) 20%, rgba(46, 48, 54, 0.85) 100%)",
  },
  bottomGradient: {
    width: "100%",
    position: "absolute",
    height: "100%",
    bottom: 0,
    background:
      "linear-gradient(rgba(46, 48, 54, 0.03) 50%, rgb(46, 48, 54) 100%)",
  },
  img: {
    height: "100%",
    minWidth: "100%",
    objectFit: "none",
  },
  scroll: {
    overflowX: "auto",
    overflowY: "hidden",
    paddingBottom: 8,

    "&::-webkit-scrollbar": {
      width: "10px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.primary,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(97,97,97, 0.55)",
      borderRadius: "5px",
    },
  },
  image: {
    borderRadius: 5,
    cursor: "pointer",
  },
}))

const SingleMap = ({ path }) => {
  const classes = useStyles()

  return (
    <Route path={path}>
      <Box>

        <Box
          position="relative"
          height={450}
          ml={-3}
          mr={-2.5}
          mt={-3.5}
        >
          <Box className={classes.topGradient} />
          <Box height={"100%"}>
            <img
              src={mockSrc}
              className={classes.img}
              alt="Map as background"
            />
          </Box>
          <Box className={classes.bottomGradient} />
        </Box>
        <Subheader />
        <Box
          display="flex"
          className={clsx(classes.scroll)}
        >
          {Array(15)
            .fill(mockSrc, 0, 15)
            .map((imgSrc, i) => (
              <Box
                key={i}
                mr={1}
                height={85}
              >
                <img
                  src={imgSrc}
                  height={"100%"}
                  alt="map from slider"
                  className={classes.image}
                />
              </Box>
            ))}
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={9}
        >
          <Description />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Aside />
        </Grid>
      </Grid>
      <Box mb={2} />
      <Divider />
      <Comments />
    </Route>
  )
}

export default SingleMap
