import React from "react"
import TextField from "@material-ui/core/TextField"
import { InputAdornment, withStyles } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"

const Styles = withStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.75),
  },
}))

const SearchField = ({ classes, id, placeholder, onChange }) => (
  <TextField
    id={id}
    onChange={onChange}
    placeholder={placeholder}
    classes={classes}
    variant="outlined"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      classes: classes,
    }}
  />
)

export default Styles(SearchField)
