import { Box, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"

import Button from "Theme/Button"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import UserCard from "Components/UserCard"

const useStyles = makeStyles((theme) => ({
  followButton: {
    width: "100%",
  },
  followIcon: {
    transform: "rotateY(180deg)",
    margin: theme.spacing(0, 0.5),
  },
  textRow: {
    width: "50%",
    verticalAlign: "center",
  },
}))

const Aside = () => {
  const classes = useStyles()
  return (
    <Box>
      <Box mb={2}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          Map Creator
        </Typography>
        <Paper>
          <UserCard
            alt="Remy Sharp"
            src="https://static.bangkokpost.com/media/content/20200903/c1_3738615.jpg"
            name={"The Rock"}
            description="10 maps - 2 awards"
          />
          <Box
            width={"100%"}
            p={2}
            pt={0}
          >
            <Button className={classes.followButton}>
              <PersonAddIcon
                className={classes.followIcon}
                fontSize="inherit"
              />{" "}
              <span className={classes.followText}>follow</span>
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box mb={2}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          Awards
        </Typography>
        <Box
          display={"flex"}
          justifyContent="space-between"
        >
          <Paper>
            <Box
              height={100}
              width={100}
            />
          </Paper>
          <Paper>
            <Box
              height={100}
              width={100}
            />
          </Paper>
          <Paper>
            <Box
              height={100}
              width={100}
            />
          </Paper>
        </Box>
      </Box>
      <Box mb={2}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          Details
        </Typography>
        <Paper>
          <Box
            display="flex"
            justifyContent={"space-between"}
            p={1}
          >
            <Box
              className={classes.textRow}
              display="flex"
              alignItems={"center"}
            >
              <Box mr={1}>
                <PersonAddIcon fontSize="small" />
              </Box>

              <Typography
                variant="body2"
                color="textPrimary"
              >
                Halo
              </Typography>
            </Box>

            <Typography className={classes.textRow}>Title</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent={"space-between"}
            p={1}
          >
            <Box
              className={classes.textRow}
              display="flex"
              alignItems={"center"}
            >
              <Box mr={1}>
                <PersonAddIcon fontSize="small" />
              </Box>

              <Typography
                variant="body2"
                color="textPrimary"
              >
                Mar 17, 2022
              </Typography>
            </Box>

            <Typography className={classes.textRow}>Last Update</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent={"space-between"}
            p={1}
          >
            <Box
              className={classes.textRow}
              display="flex"
              alignItems={"center"}
            >
              <Box mr={1}>
                <PersonAddIcon fontSize="small" />
              </Box>

              <Typography
                variant="body2"
                color="textPrimary"
              >
                25
              </Typography>
            </Box>

            <Typography className={classes.textRow}>Downloads</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
          >
            <Box
              className={classes.textRow}
              display="flex"
              alignItems={"center"}
            >
              <Box mr={1}>
                <PersonAddIcon fontSize="small" />
              </Box>

              <Typography
                variant="body2"
                color="textPrimary"
              >
                2-8
              </Typography>
            </Box>

            <Typography className={classes.textRow}>Players</Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default Aside
