import SvgIcon from "@material-ui/core/SvgIcon"
import { withStyles } from "@material-ui/core/styles"


const StyledSvgIcon = withStyles(theme => ({
  root: {},
  fontSizeSmall: {
    fontSize: "16px",
  },
}))(SvgIcon)


export default StyledSvgIcon
