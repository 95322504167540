
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as WikiIcon } from "../fontello/icons/svg/ic_wiki.svg"


const Wiki = ({ ...props }) => (
  <SvgIcon
    component={WikiIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Wiki
