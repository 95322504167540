import Box from "@material-ui/core/Box"
import CollectionCard from "Components/CollectionCard"
import { Route } from "react-router"

export default function Collections({ path }) {
  return (
    <Route path={`${path}`}>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box>Collections</Box>
        {[0, 1, 2, 3].map((index) => (
          <Box
            key={index}
            padding={1}
          >
            <CollectionCard
              title="Big team battle"
              mapsCount={30}
              likesCount="24k"
              teamMode={[4, 4]}
              maps={[]}
            />
          </Box>
        ))}
      </Box>
    </Route>
  )
}
