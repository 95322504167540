import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { alpha } from "@material-ui/core/styles/colorManipulator"

const StyledButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0.75),
    borderRadius: theme.spacing(0.75),
    textTransform: "none",
    boxShadow: "none!important",
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    fontSize: "14px",
    lineHeight: "16px",
    height: "40px",
    border: "none",
    transition: theme.transitions.create(["color", "background-color"]),
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  outlined: {
    background: alpha(theme.palette.common.white, 0.05),
    border: "none",
    backdropFilter: "blur(8px)",
    color: "#CBD1E2",

    "&:hover": {
      background: alpha(theme.palette.common.white, 0.08),
      border: "none",
    },

    "&:active": {
      background: alpha(theme.palette.common.white, 0.03),
      border: "none",
    },
  },

  outlinedPrimary: {
    background: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,

    "&:hover": {
      background: alpha(theme.palette.primary.main, 0.2),
      color: theme.palette.primary.light,
      border: "none",
    },

    "&:active": {
      background: alpha(theme.palette.primary.main, 0.05),
      color: theme.palette.primary.dark,
      border: "none",
    },
  },

  outlinedSecondary: {
    background: alpha(theme.palette.secondary.main, 0.1),
    color: theme.palette.secondary.main,
    backdropFilter: "blur(8px)",

    "&:hover": {
      background: alpha(theme.palette.secondary.main, 0.2),
      color: theme.palette.secondary.light,
      border: "none",
    },

    "&:active": {
      background: alpha(theme.palette.secondary.main, 0.05),
      color: theme.palette.secondary.dark,
      border: "none",
    },
  },

  outlinedError: {
    background: alpha(theme.palette.red.main, 0.1),
    color: theme.palette.red.main,
    backdropFilter: "blur(8px)",

    "&:hover": {
      background: alpha(theme.palette.red.main, 0.2),
      color: theme.palette.red.light,
      border: "none",
    },

    "&:active": {
      background: alpha(theme.palette.red.main, 0.05),
      color: theme.palette.red.dark,
      border: "none",
    },
  },

  contained: {
    background: "#4D515D",
    color: theme.palette.primary.contrastText,

    "&:hover": {
      background: "#84848A",
      color: theme.palette.primary.contrastText,
    },

    "&:active": {
      background: "#343842",
      color: theme.palette.primary.contrastText,
    },
  },

  containedPrimary: {
    background: theme.palette.primary.light,

    "&:hover": {
      background: theme.palette.primary.main,
    },

    "&:active": {
      background: theme.palette.primary.dark,
    },
  },

  containedSecondary: {
    background: theme.palette.secondary.light,

    "&:hover": {
      background: theme.palette.secondary.main,
    },

    "&:active": {
      background: theme.palette.secondary.dark,
    },
  },

  containedError: {
    background: theme.palette.error.main,

    "&:hover": {
      background: theme.palette.error.light,
    },

    "&:active": {
      background: theme.palette.error.dark,
    },
  },

  text: {
    background: "none",
    border: "none",
    color: theme.palette.text.secondary,

    "&:hover": {
      background: alpha(theme.palette.common.white, 0.05),
    },

    "&:active": {
      background: alpha(theme.palette.common.white, 0.03),
    },
  },

  textPrimary: {
    color: theme.palette.primary.main,
    background: "none",

    "&:hover": {
      background: alpha(theme.palette.primary.dark, 0.05),
      color: theme.palette.primary.light,
      backdropFilter: "blur(8px)",
    },

    "&:active": {
      backdropFilter: "blur(8px)",
      color: theme.palette.primary.dark,
      background: alpha(theme.palette.primary.main, 0.05),
    },
  },

  textSecondary: {
    color: theme.palette.secondary.main,
    background: "none",

    "&:hover": {
      background: alpha(theme.palette.secondary.main, 0.05),
      backdropFilter: "blur(8px)",
    },

    "&:active": {
      backdropFilter: "blur(8px)",
      color: theme.palette.secondary.dark,
      background: alpha(theme.palette.secondary.main, 0.05),
    },
  },

  textError: {
    color: theme.palette.red.main,
    background: "none",

    "&:hover": {
      background: alpha(theme.palette.red.main, 0.05),
      backdropFilter: "blur(8px)",
    },

    "&:active": {
      backdropFilter: "blur(8px)",
      color: theme.palette.red.dark,
      background: alpha(theme.palette.red.main, 0.05),
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: `${alpha(theme.palette.action.disabled, 0.5)}!important`,
    border: "none!important",
  },
}))(Button)

export default StyledButton
