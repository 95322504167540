import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import clsx from "clsx"
import GameTypeAddTypeCard from "Components/GameTypeAddTypeCard"
import GameTypeCard from "Components/GameTypeCard"

const useStyles = makeStyles((theme) => ({
  rootSecondarySelected: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  rootPrimarySelected: {
    border: `2px solid ${theme.palette.green.main}`,
  },
  iconContainerSecondarySelected: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
  },
  iconContainerPrimarySelected: {
    backgroundColor: theme.palette.green.main,
    color: "#ffffff",
  },
  textSecondarySelected: {
    fontWeight: 700,
  },
  textPrimarySelected: {
    fontWeight: 700,
  },
  label: {
    fontWeight: 700,
    lineHeight: 1.6,
    fontSize: 10,
  },
  labelBoxPrimarySelected: {
    opacity: 1,
  },
}))

const GameTypesGrid = ({
  gameTypes,
  onSelect,
  canAddNew,
  addCustomGameType,
  setCustomGameTypeInputValue,
}) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      paddingTop={1}
    >
      {gameTypes.map((gameType, index) => (
        <Box
          key={gameType.id}
          paddingRight={2}
          paddingBottom={2}
        >
          <GameTypeCard
            label={gameType.label}
            icon={gameType.icon}
            onClick={onSelect(index)}
            rootClassName={clsx({
              [classes.rootSecondarySelected]: gameType.secondarySelected,
              [classes.rootPrimarySelected]: gameType.primarySelected,
            })}
            labelBoxClassName={clsx({
              [classes.labelBoxPrimarySelected]: gameType.primarySelected,
            })}
            iconContainerClassName={clsx({
              [classes.iconContainerSecondarySelected]:
                gameType.secondarySelected,
              [classes.iconContainerPrimarySelected]: gameType.primarySelected,
            })}
            textClassName={clsx({
              [classes.textSecondarySelected]: gameType.secondarySelected,
              [classes.textPrimarySelected]: gameType.primarySelected,
            })}
            doShake={gameType.doShake}
          />
        </Box>
      ))}
      {canAddNew && (
        <GameTypeAddTypeCard
          gameTypes={gameTypes}
          addCustomGameType={addCustomGameType}
          setCustomGameTypeInputValue={setCustomGameTypeInputValue}
        />
      )}
    </Box>
  )
}

export default GameTypesGrid
