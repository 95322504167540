import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import {
  Box,
  CircularProgress,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core"
import Button from "Theme/Button"
import UserCard from "Components/UserCard"
import CloseIcon from "@material-ui/icons/Close"
import Rate from "Components/Rate"
import HelpIcon from "@material-ui/icons/Help"


const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 14,
  },
  percentage: {
    fontSize: 22,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
    "&:hover": {
      background: "transparent",
      color: theme.palette.text.primary,
    },
  },
  dividers: {
    borderTop: `3px solid ${theme.palette.secondary.main}`,
  },
}))

const CommunityRating = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              color="textPrimary"
            >
              Oracle
            </Typography>
            <UserCard
              alt="Remy Sharp"
              src="https://static.bangkokpost.com/media/content/20200903/c1_3738615.jpg"
              name={"The Rock"}
              description="10 maps - 2 awards"
            />

            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers
          classes={{ dividers: classes.dividers }}
        >
          <DialogContentText
            id="alert-dialog-description"
            color="textPrimary"
            variant="subtitle2"
          >
            Please rate your experience of this map based on individual criteria
            listed below
          </DialogContentText>
          <Box>
            <Rate
              category="Art"
              description="Overall experience of visual atmosphere"
            />
            <Rate
              category="Technical"
              description="Overall experience of visual atmosphere"
            />
            <Rate
              category="Coolness"
              description="Overall experience of visual atmosphere"
            />
          </Box>
          <Box mx={-1}>
            <Divider />
          </Box>
          <Box>
            <Typography>Optional Feedback</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={4}
              variant="outlined"
            />
            <Box
              fontSize="9"
              display="flex"
              alignItems="center"
              mt={1}
            >
              <Box
                color="gray"
                mr={0.5}
              >
                <HelpIcon
                  fontSize="inherit"
                  color="inherit"
                />
              </Box>
              <Typography>
                Written feedback is reviewing by the author before it's publicly
                visible
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="space-between"
            width={"100%"}
          >
            <Button
              onClick={handleClose}
              color="primary"
            >
              cancel
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              autoFocus
              variant="contained"
            >
              Rate map
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box
        display={"flex"}
        justifyContent="space-between"
        mb={1}
      >
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          Community Rating
        </Typography>
        <Button
          className={classes.button}
          onClick={handleClickOpen}
        >
          Rate map
        </Button>
      </Box>
      <Paper>
        <Box
          display="flex"
          p={2}
          height={190}
        >
          <Box
            width="40%"
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              display={"flex"}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="relative"
                display="inline-flex"
                color="#3cd598"
              >
                <CircularProgress
                  variant="determinate"
                  color="inherit"
                  thickness={2.5}
                  value={88}
                  size={100}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle"
                    component="div"
                    color="textPrimary"
                    className={classes.percentage}
                  >
                    {"88"}
                  </Typography>
                </Box>
              </Box>
              <Box
                mt={1}
                lineHeight={16}
              >
                <Typography
                  variant="body2"
                  color="textPrimary"
                  lineHeight="inherit"
                >
                  Excellent
                </Typography>
              </Box>
              <Box lineHeight={16}>
                <Typography
                  variant="body2"
                  lineHeight="inherit"
                >
                  233 reviews
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
          />
          <Box width="60%">
            <Typography color="textPrimary">Gameplay</Typography>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={70}
            />
            <Typography color="textPrimary">Art</Typography>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={70}
            />
            <Typography color="textPrimary">Technical</Typography>
            <LinearProgress
              color="secondary"
              variant="determinate"
              value={70}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default CommunityRating
