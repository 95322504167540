import React, {
  useContext,
  useMemo } from "react"
import { ParallaxContext } from "./context"
import Box from "@material-ui/core/Box"
import { motion,
  useTransform } from "framer-motion"


const ParallaxItem = ({
  component,
  children,
  inputRange = [0, 300],
  outputRange = [0, 40],
  ...otherProps
}) => {
  const { scrollY } = useContext(ParallaxContext)

  const ParallaxItemComponent = useMemo(_ =>
    component || Box
  ,[component])

  const y = useTransform(scrollY, inputRange, outputRange)

  return (
    <ParallaxItemComponent
      component={motion.div}
      style={{ y }}
      {...otherProps}
    >
      { children }
    </ParallaxItemComponent>
  )
}


export default ParallaxItem
