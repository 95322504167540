import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "Theme/Typography"
import CustomSlider from "Theme/Slider"
import React, { useState } from "react"
import HelpIcon from "@material-ui/icons/Help"


const Rate = ({ category, description }) => {
  const [value, setValue] = useState(78)

  const onChange = (e, val) => {
    setValue(val)
  }

  return (
    <Box>
      <Box display={"flex"}>
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {category}
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title={
            <Box>
              <Typography color="textPrimary">
                Things to consider:
              </Typography>
              <Typography>Nothing</Typography>
            </Box>
          }
        >
          <Box
            fontSize="9"
            display="flex"
            alignItems="center"
            color="gray"
            pl={1}
          >
            <HelpIcon
              fontSize="inherit"
              color="inherit"
            />
          </Box>
        </Tooltip>
      </Box>
      <Typography>{description}</Typography>
      <Box display={"flex"}>
        <Box width={"90%"}>
          <CustomSlider
            aria-label="slider"
            valueLabelDisplay="off"
            defaultValue={78}
            value={value}
            onChange={onChange}
          />
        </Box>
        <Box
          width={"10%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={2}
          pb={0.25}
        >
          <Typography
            variant="subtitle2"
            color="textPrimary"
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Rate
