import { Route } from "react-router-dom"
import { LoginForm } from "Components/LoginForm"

const LoginScene = function({ path }) {
  return (
    <Route path={path}>
      <LoginForm />
    </Route>
  )
}

export default LoginScene
