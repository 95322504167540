import React from "react"
import { Chip, makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import EventType from "./EventType"
import InfoCard from "./InfoCard"
import { Elimination, People } from "Theme/icons"
import Typography from "Theme/Typography"
import Card, { CardActions, CardContent, CardMedia } from "Theme/Card"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    position: "relative",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  mediaBox: ({ mapImage }) => ({
    height: "100%",
    backgroundImage: `linear-gradient(
        270deg,
        rgba(32, 33, 38, 0.2) 0%,
        rgba(32, 33, 38, 0.6446) 44.01%,
        rgba(32, 33, 38, 0.92) 100%
      ),
      url(${mapImage})`,
  }),
  container: {
    display: "flex",
  },
  details: {
    width: "100%",
    padding: theme.spacing(0, 3, 2, 3),
  },
  gameTypeIcon: {
    color: theme.palette.text.tertiary,
    width: 16,
    height: 16,
  },
  teamModeIcon: {
    color: theme.palette.text.tertiary,
    width: 12,
  },
}))

const EventCard = ({ event: { title, ending, prize, type, creator, mapType, teamMode = [0, 0], mapImage } }) => {
  const classes = useStyles({ mapImage })
  return (
    <Card className={classes.root}>
      <CardMedia>
        <Box className={classes.mediaBox} />
      </CardMedia>
      <CardContent className={classes.container}>
        <EventType type={type} />
      </CardContent>
      <CardActions className={classes.details}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="h2"
              color="textPrimary"
            >
              {title}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gridGap={8}
              paddingTop={1}
            >
              <Elimination className={classes.gameTypeIcon} />
              <Typography
                variant="caption"
                color="tertiary"
              >
                {mapType}
              </Typography>
              <People className={classes.teamModeIcon} />
              <Typography
                variant="caption"
                color="tertiary"
              >
                {teamMode[0]}v{teamMode[1]} by
              </Typography>
              <Typography
                variant="body2bold"
                color="tertiary"
              >
                {creator}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            gridGap={8}
          >
            <InfoCard
              title="End"
              value={
                <Typography
                  variant="body2bold"
                  color="textPrimary"
                >
                  {ending}
                </Typography>
              }
            />
            <InfoCard
              title="Prize pool"
              value={
                <Chip
                  label={
                    <Typography
                      variant="body2bold"
                      color="textPrimary"
                    >
                      {prize}
                    </Typography>
                  }
                  color="secondary"
                  component="strong"
                  size="small"
                />
              }
            />
          </Box>
        </Box>
      </CardActions>
    </Card>
  )
}

export default EventCard
