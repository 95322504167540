import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import { Divider, withStyles } from "@material-ui/core"
import PeopleIcon from "@material-ui/icons/People"


const Styles = withStyles((theme) => ({
  imageContainer: {
    borderRadius: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    marginBottom: theme.spacing(5),
    position: "relative",
    backgroundSize: "auto",
    minWidth: 300,
    minHeight: 200,
  },
  dateContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 50%)",
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: "#42454E",
  },
  info: {
    display: "flex",
    borderTop: "1px solid #5A5E69",
    marginTop: theme.spacing(2),
  },
}))

const DateCard = ({ label, value, props }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    marginX={1}
    {...props}
  >
    <Box
      bgcolor="#32353A"
      borderRadius={5}
      paddingY={1}
      paddingX={1.5}
      minWidth={45}
    >
      <Typography
        variant="subtitle1"
        color="textPrimary"
      >
        {value}
      </Typography>
    </Box>
    <Typography>
      {label}
    </Typography>
  </Box>
)

const ContestInfoCard = ({ icon: Icon, value, label }) => (
  <Box
    display="flex"
    paddingY={2}
    paddingX={4}
    minWidth={100}
  >
    <Box
      style={{
        opacity: 0.6,
      }}
    >
      <Icon />
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      paddingLeft={2}
    >
      <Typography variant="body2bold">
        {value}
      </Typography>
      <Typography>
        {label}
      </Typography>
    </Box>
  </Box>
)

const ContestCard = ({ classes, contest  }) => (
  <Box
    display="flex"
    bgcolor="#42454E"
    borderRadius={5}
    padding={3}
    boxShadow="rgb(0 0 0 / 15%) -10px -10px 20px 0px, rgb(0 0 0 / 20%) 10px 10px 30px 0px;"
  >
    <Box
      className={classes.imageContainer}
      style={{
        backgroundImage: `url(${contest.image}`,
      }}
    >
      <Box className={classes.dateContainer}>
        <DateCard
          label="weeks"
          value={3}
        />
        <DateCard
          label="days"
          value={10}
        />
      </Box>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      paddingLeft={2}
    >
      <Typography
        variant="subtitle1"
        color="textPrimary"
      >
        ForgeHub Grand Prix
      </Typography>
      <Box
        display="flex"
        marginBottom={1}
      >
        <Typography>
          by
        </Typography>
        <Typography>
          Punisher2510
        </Typography>
      </Box>
      <Typography>
        Are you ready? Are you set? The next thrilling ForgeHub community forge contest is officially crossing the finish line! So start your engines, and get ready for the joyride of your life as you partake in the ForgeHub...
      </Typography>
      <Box className={classes.info}>
        <ContestInfoCard
          icon={PeopleIcon}
          label="contestants"
          value="29"
        />
        <Divider variant="vertical" />
        <ContestInfoCard
          icon={PeopleIcon}
          label="submissions"
          value="35"
        />
        <Divider variant="vertical" />
        <ContestInfoCard
          icon={PeopleIcon}
          label="prize"
          value="$250"
        />
      </Box>
    </Box>
  </Box>
)


export default Styles(ContestCard)
