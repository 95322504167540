import { Grid } from "@material-ui/core"
import HighlightedCreator from "Components/CreatorCard"


const CreatorCardsGrid = ({ creators }) =>
  creators.map((creator) => (
    <Grid
      item
      xs={4}
      key={creator.id}
    >
      <HighlightedCreator {...creator} />
    </Grid>
  ))


export default CreatorCardsGrid
