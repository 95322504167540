import { Chip } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import SvgIcon from "Theme/SvgIcon"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Instagram from "@material-ui/icons/Instagram"
import LanguageIcon from "@material-ui/icons/Language"
import Twitter from "@material-ui/icons/Twitter"
import YouTube from "@material-ui/icons/YouTube"
import makeStyles from "@material-ui/styles/makeStyles"
import Achievement from "Components/Achievement"
import Avatar from "Components/Avatar"
import IconGroup from "Components/IconGroup"


const useStyles = makeStyles((theme) => ({
  textClass: {
    fontSize: 11,
  },
  flag: {
    borderRadius: 3,
  },
  color: {
    color: theme.palette.text.tertiary,
  },
  textAccent: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    display: "inline",
  },
  icons: {
    color: theme.palette.text.tertiary,
    fontSize: 20,
    "& svg:nth-child(n)": {
      marginRight: theme.spacing(3),
    },

  },
}))

// TODO: this is a temporary solution to make the component render correctly

const FlagSvg = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="6"
      width="18"
      height="6"
      fill="#D9D9D9"
    />
    <rect
      width="18"
      height="6"
      fill="#DD2727"
    />
  </svg>
)



function ProfileHeader() {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex">
        <Tooltip
          title="Jakarta, Indonesia"
          placement="right-start"
        >
          <Avatar src={`${process.env.PUBLIC_URL}/photo.png`} />
        </Tooltip>
        <Box
          width={342}
          ml={3}
        >
          <Box display="flex">
            <Typography
              variant="h2"
              color="textPrimary"
            >
              scorpionova1020
            </Typography>
            <Box
              width="fit-content"
              ml={3}
              display="flex"
              alignItems="center"
            >
              <Chip
                color="secondary"
                label="PRO"
                component="strong"
                size="small"
              />
              {/* <InfoLabel
                value="PRO"
                markedValue
                textClass={classes.textClass}
              />
               */}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={240}
            mt={1}
          >
            <Box
              display="flex"
              alignItems="baseline"
            >
              <Box
                display="flex"
                height="fit-content"
              >
                <SvgIcon
                  classes={{ root: classes.flag }}
                  component={FlagSvg}
                />
              </Box>
              <Box ml={1}>
                <Typography
                  variant="body2"
                  color="textTertiary"
                >
                  Arthur Conan
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              className={classes.color}
            >
              Level Designer
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              variant="body2"
              className={classes.color}
            >
              Creating great experiences for people at{" "}
              <Typography
                variant="body2"
                color="secondary"
                component={"strong"}
                className={classes.textAccent}
              >
                @ForgeHub
              </Typography>
              . Let's work together!
            </Typography>
          </Box>
          <IconGroup>
            <YouTube
              color="inherit"
              fontSize="inherit"
            />
            <Twitter
              color="inherit"
              fontSize="inherit"
            />
            <Instagram
              color="inherit"
              fontSize="inherit"
            />
            <LanguageIcon
              color="inherit"
              fontSize="inherit"
            />
          </IconGroup>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          mb={2.5}
        >
          <Box
            display="flex"
            alignItems="baseline"
            mr={3}
          >
            <Box mr={2}>
              <Typography variant="overline">MAPS</Typography>
            </Box>
            <Typography
              variant="h6"
              color="textSecondary"
            >
              256
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="baseline"
            mr={3}
          >
            <Box mr={2}>
              <Typography variant="overline">FOLLOWERS</Typography>
            </Box>
            <Typography
              variant="h6"
              color="textSecondary"
            >
              25
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="baseline"
          >
            <Box mr={2}>
              <Typography variant="overline">FOLLOWING</Typography>
            </Box>
            <Typography
              variant="h6"
              color="textSecondary"
            >
              26
            </Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent="space-between"
        >
          <Achievement />
          <Achievement />
          <Achievement />
          <Achievement />
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileHeader
