
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as SearchIcon } from "../fontello/icons/svg/ic_search.svg"


const Search = ({ ...props }) => (
  <SvgIcon
    component={SearchIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Search
