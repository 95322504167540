import { ROUTES } from "Scenes/constants"
import { Redirect, Route, Switch } from "react-router-dom"
const { COLLECTIONS } = ROUTES

const CollectionsScene = function({ path }) {
  return (
    <Route path={path}>
      <Switch>
        <Route path={path}>
          <h1>All Collections</h1>
        </Route>
        <Redirect
          exact
          to={`${path}/${COLLECTIONS}`}
        />
      </Switch>
    </Route>
  )
}

export default CollectionsScene
