
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as CloseIcon } from "../fontello/icons/svg/ic_close.svg"


const Close = ({ ...props }) => (
  <SvgIcon
    component={CloseIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Close
