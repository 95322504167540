
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as MinigameIcon } from "../fontello/icons/svg/ic_minigame.svg"


const Minigame = ({ ...props }) => (
  <SvgIcon
    component={MinigameIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Minigame
