
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as NodeIcon } from "../fontello/icons/svg/ic_node.svg"


const Node = ({ ...props }) => (
  <SvgIcon
    component={NodeIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Node
