
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as CreditIcon } from "../fontello/icons/svg/ic_credit.svg"


const Credit = ({ ...props }) => (
  <SvgIcon
    component={CreditIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Credit
