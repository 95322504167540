
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ChevronRightIcon } from "../fontello/icons/svg/ic_chevron-right.svg"


const ChevronRight = ({ ...props }) => (
  <SvgIcon
    component={ChevronRightIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default ChevronRight
