
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ContestsIcon } from "../fontello/icons/svg/ic_contests.svg"


const Contests = ({ ...props }) => (
  <SvgIcon
    component={ContestsIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Contests
