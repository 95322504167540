
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as RankingsIcon } from "../fontello/icons/svg/ic_rankings.svg"


const Rankings = ({ ...props }) => (
  <SvgIcon
    component={RankingsIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Rankings
