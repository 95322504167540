import React from "react"
import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"


const Styles = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#2B2E33",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiInputBase-input": {
      color: "#ffffffb3",
    },
    "& .MuiIconButton-root": {
      transition: "all 0.2s ease 0s",
      "&:hover": {
        color: "#8c8c8c",
        backgroundColor: "transparent",
      },
    },
  },
}))

const Autocomplete = ({ classes, options, placeholder = "Select...", onChange }) => (
  <MaterialAutocomplete
    className={classes.root}
    disableClearable
    options={options}
    getOptionLabel={(item) => item.label}
    renderInput={(params) => <TextField
      {...params}
      placeholder={placeholder}
                             />}
    popupIcon={<KeyboardArrowDownIcon fontSize="small" />}
    onChange={(event, newValue) => onChange && onChange(newValue)}
  />
)


export default Styles(Autocomplete)
