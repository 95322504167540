import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"
import clsx from "clsx"

const Styles = withStyles((theme) => ({
  boldText: {
    fontWeight: 900,
  },
}))

function InfoLabel({ markedValue, value, classes, textClass }) {
  return (
    <Box
      borderRadius={25}
      bgcolor={markedValue ? "secondary.main" : "transparent"}
      paddingX={1.5}
    >
      <Typography
        variant="body2"
        color="textPrimary"
        className={clsx(classes.boldText, textClass)}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default Styles(InfoLabel)
