
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as FollowIcon } from "../fontello/icons/svg/ic_follow.svg"


const Follow = ({ ...props }) => (
  <SvgIcon
    component={FollowIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Follow
