
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as CommunityIcon } from "../fontello/icons/svg/ic_community.svg"


const Community = ({ ...props }) => (
  <SvgIcon
    component={CommunityIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Community
