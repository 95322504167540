import { Box, Divider, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import StarIcon from "@material-ui/icons/Star"
import PowerCard from "Components/PowerCard"
import CommunityRating from "./CommunityRating"

const useStyles = makeStyles((theme) => ({
  chip: {
    background: "rgba(255, 255, 255, 0.1)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "3px",
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  chipText: {
    marginLeft: theme.spacing(0.5),
  },
  descriptionTitle: {
    textTransform: "uppercase",
  },
}))

const Description = () => {
  const classes = useStyles()

  return (
    <Box>
      <Typography
        variant="subtitle1"
        className={classes.descriptionTitle}
      >
        About
      </Typography>
      <Box mb={2} />

      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget
        faucibus erat. Fusce id porttitor nisl. Nullam non quam efficitur,
        tempus lorem sed, venenatis tortor. Suspendisse varius vel lorem vitae
        vulputate. Lorem ipsum dolor sit.
      </Typography>
      <Box mb={2} />

      <Typography variant="body1">
        Ut vitae ullamcorper augue, sed gravida nisi. Donec pretium at nulla in
        gravida. Sed scelerisque, lectus eget imperdiet tincidunt, lorem velit
        porta nisl, a volutpat odio magna ac ipsum. Ut malesuada magna ex, vitae
        molestie.
      </Typography>
      <Box mb={2} />

      <Typography variant="body1">
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Duis convallis neque quis porttitor eleifend. Maecenas
        dolor velit, dignissim nec nibh vel, cursus dapibus dolor. Donec non
        arcu ex. Nunc hendrerit.
      </Typography>
      <Box mb={2} />

      <Typography
        variant="subtitle1"
        className={classes.descriptionTitle}
      >
        Supported game modes
      </Typography>
      <Box mb={2} />
      <Box>
        <Box className={classes.chip}>
          <StarIcon fontSize="inherit" />{" "}
          <Typography
            className={classes.chipText}
            color="textPrimary"
          >
            CTF
          </Typography>
        </Box>
        <Box className={classes.chip}>
          <StarIcon fontSize="inherit" />{" "}
          <Typography
            className={classes.chipText}
            color="textPrimary"
          >
            Infection
          </Typography>
        </Box>
        <Box className={classes.chip}>
          <StarIcon fontSize="inherit" />{" "}
          <Typography
            className={classes.chipText}
            color="textPrimary"
          >
            Slayer
          </Typography>
        </Box>
      </Box>
      <Box mb={2} />
      <Divider />
      <Box mb={2} />
      <Typography
        variant="subtitle1"
        className={classes.descriptionTitle}
      >
        power weapon
      </Typography>
      <Box mb={2} />
      <Grid container>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Assault rifle"
            details="x3 (30s)"
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <Typography
        variant="subtitle1"
        className={classes.descriptionTitle}
      >
        powerups
      </Typography>
      <Box mb={2} />
      <Grid container>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Active Camo"
            details="x3 (30s)"
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <PowerCard
            src="https://www.pikpng.com/pngl/m/111-1111557_halo-assault-rifle-drawing-assault-rifle-clipart.png"
            name="Active Camo"
            details="x3 (30s)"
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <CommunityRating />

    </Box>
  )
}

export default Description
