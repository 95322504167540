import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "Theme/Typography"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Link } from "react-router-dom"
import { format } from "date-fns"
import { ROUTES } from "Scenes/constants"
import SocialLinks from "Components/SocialLinks"

const {
  HOME,
  INDEX,
} = ROUTES

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    position: "relative",
    color: "inherit",
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  externalLink: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    position: "relative",
  },
  footerLogo: {
    position: "absolute",
    top: 0,
    transform: "translate(-50%, -50%)",
    left: "50%",
  },
}))

function Footer() {
  const classes = useStyles()
  return (
    <footer>
      <Box className={classes.footerBox}>
        <img
          src="https://staging.forgehub.com/dist/69c83538f8508b2e726d1e0a810d76a3.svg"
          alt="Forgehub logo"
          className={classes.footerLogo}
        />
        <Divider variant="middle" />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        mt={4}
      >
        <Typography variant="body1">
          <Link
            to={`${INDEX}${HOME}`}
            className={classes.link}
          >
            Terms
          </Link>{" "}
          •{" "}
          <Link
            to={`${INDEX}${HOME}`}
            className={classes.link}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent="center"
        mt={1}
        pl={2}
      >
        <SocialLinks />
      </Box>
      <Box
        display={"flex"}
        justifyContent="center"
        mt={1}
      >
        <Typography variant="body1">
          ForgeHub © {format(new Date(), "yyyy")}
        </Typography>
      </Box>
    </footer>
  )
}

export default Footer
