import withStyles from "@material-ui/styles/withStyles"
import MapCard from "Components/MapCard"

const Styles = withStyles((theme) => ({
  mapContainer: {
    margin: 0,
    boxShadow: "none",
    minHeight: theme.spacing(24),
  },
}))


// #TODO WHAT?!
const ActivityMapCard = ({
  classes,
  title,
  image,
}) => (
  <MapCard
    title={title}
    image={image}
    disabled
    containerClass={classes.mapContainer}
  />
)


export default Styles(ActivityMapCard)
