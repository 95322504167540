import Box from "@material-ui/core/Box"

export default function PlateItem({ y = 1, x = 1, children, ...props }) {
  return (
    <Box
      gridRow={`span ${y}`}
      gridColumn={`span ${x}`}
      height={"100%"}
      {...props}
    >
      {children}
    </Box>
  )
}
