import CardMedia from "@material-ui/core/CardMedia"
import { withStyles } from "@material-ui/core/styles"


const StyledCardMedia = withStyles(_ => ({
  root: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: "0",

    "&:after": {
      content: "''",
      display: "block",
      background: `
        linear-gradient(
          180deg,
          rgba(32, 33, 38, 0.2) 0%,
          rgba(32, 33, 38, 0.6446) 44.01%,
          rgba(32, 33, 38, 0.92) 100%)
        `,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
}))(CardMedia)


export default StyledCardMedia
