
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as Check_1Icon } from "../fontello/icons/svg/ic_check-1.svg"


const Check_1 = ({ ...props }) => (
  <SvgIcon
    component={Check_1Icon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Check_1
