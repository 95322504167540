
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as NewIcon } from "../fontello/icons/svg/ic_new.svg"


const New = ({ ...props }) => (
  <SvgIcon
    component={NewIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default New
