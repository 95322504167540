import React, { useEffect } from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { useAnimation, motion } from "framer-motion"

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    transition: "all 0.2s ease",
    border: "none",
    "&:hover": {
      backgroundColor: "#4D515D",
    },
  },
  iconContainer: {
    transition: "all 0.2s ease",
    backgroundColor: "#32353A",
    color: "#9298A9",
    fontSize: 26,
  },
  labelBox: {
    backgroundColor: theme.palette.green.main,
    borderRadius: theme.spacing(1.5),
    opacity: 0,
    transition: "0.2s ease-in all",
  },
}))

const GameTypeCard = ({
  icon: Icon,
  label,
  onClick,
  rootClassName,
  labelBoxClassName,
  iconContainerClassName,
  children,
  doShake,
}) => {
  const classes = useStyles()
  const controls = useAnimation()

  const variants = {
    shake: (i) => ({
      rotate: i % 2 === 0 ? [-1, 3.1, 0] : [1, -3.1, 0],
      transition: {
        delay: 0,
        repeat: 4,
        duration: 0.15,
      },
    }),
    reset: {
      rotate: 0,
    },
  }

  useEffect(() => {
    if (doShake) {
      controls.start("shake")
      doShake()
    }
  }, [controls, doShake])

  return (
    <motion.div
      variants={variants}
      animate={controls}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={1}
        borderRadius={8}
        bgcolor="#42454E"
        position="relative"
        width={125}
        height={90}
        onClick={onClick}
        className={clsx(classes.root, rootClassName)}
      >
        <Box
          position="absolute"
          top="-9px"
          className={clsx(classes.labelBox, labelBoxClassName)}
          px={1.5}
        >
          <Typography color="textPrimary">
            PRIMARY
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="fit-content"
          height="fit-content"
          padding={0.7}
          mt={1}
          borderRadius="100%"
          className={clsx(classes.iconContainer, iconContainerClassName)}
        >
          <Icon
            color="inherit"
            fontSize="inherit"
          />
        </Box>
        <Box paddingTop={1}>
          <Typography color="textPrimary">
            {label}
          </Typography>
        </Box>
        {children}
      </Box>
    </motion.div>
  )
}

export default GameTypeCard
