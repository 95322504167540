import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "Theme/Button"
import { Warning } from "Theme/icons"
import Progress from "Theme/Progress"
import Tooltip from "Theme/Tooltip"
import { makeStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles/colorManipulator"


const useStyles = makeStyles((theme) => ({
  saveButton: {
    width: "100%",
    backgroundColor: "#00AE70",
    "&:hover": {
      backgroundColor: "#00C692",
    },
    "&:active": {
      backgroundColor: "#00964E",
    },
  },
  publishButton: {
    color: alpha(theme.palette.white.main, 0.5),
  },
  progressBar: {
    background: "linear-gradient(90deg, #EDAC24 0%, #ED8824 100%);",
  },
  warningIcon: {
    color: "#EDAC24",
  },
}))

const SaveOrSubmitMap = ({ percent }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={6}
      bgcolor="#35383E"
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={3}
      >
        <Box paddingBottom={1}>
          <Button
            variant="contained"
            className={classes.saveButton}
          >
            Save
          </Button>
        </Box>
        <Button className={classes.publishButton}>Publish</Button>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        padding={3}
        borderTop="1px solid #484B54"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={1}
        >
          <Typography color="textPrimary">
            {percent}% setup
          </Typography>
          <Tooltip value="Why am I getting this?">
            <Box style={{ cursor: "pointer" }}>
              <Warning className={classes.warningIcon} />
            </Box>
          </Tooltip>
        </Box>
        <Progress
          variant="determinate"
          value={percent}
          classes={{ bar: classes.progressBar }}
        />
      </Box>
    </Box>
  )
}


export default SaveOrSubmitMap
