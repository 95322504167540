
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as TwitterIcon } from "../fontello/icons/svg/ic_twitter.svg"


const Twitter = ({ ...props }) => (
  <SvgIcon
    component={TwitterIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Twitter
