import React, { useCallback, useMemo } from "react"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useHistory, useLocation } from "react-router-dom"
import Button from "Theme/Button"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "Theme/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "Theme/TextField"
import { Check as CheckIcon, Close as CloseIcon, Password as PasswordIcon } from "Theme/icons"
import useSetNewPassword from "./hooks"
import { Controller, useForm } from "react-hook-form"
import { useEffect } from "react"
import { ROUTES } from "Scenes/constants"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
  closeIcon: {
    width: "16px",
    height: "16px",
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(5.5),
    right: theme.spacing(5.5),
    cursor: "pointer",
    color: theme.palette.grey.secondary,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.5),
  },
  textField: {
    transition: "all 0.2s ease 0s",

    "& input::placeholder": {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  inputGroup: {
    width: "320px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  adornmentIcon: {
    overflow: "visible",
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  control: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1.5),
    whiteSpace: "nowrap",
  },
}))

const schema = Yup.object({
  password: Yup.string().required("Please enter your password"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})

const SetPasswordForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const { search } = useLocation()
  const code = useMemo(() => new URLSearchParams(search).get("code"), [search])
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { setNewPassword, isLoading } = useSetNewPassword()

  useEffect(() => {
    if (!code) {
      window.location.hash = ROUTES.RESET_PASSWORD
    }
  }, [code, history])

  const onSubmit = useCallback(() => {
    const data = getValues()
    setNewPassword(
      {
        code,
        password: data.password,
      },
      {
        onSuccess: () => {
          window.location.hash = ""
          window.location.search = ""
          window.location.pathname = "/home"
        },
      },
    )
  }, [setNewPassword, code, getValues])

  return (
    <Dialog
      maxWidth={false}
      open
    >
      <DialogContent className={classes.root}>
        <CloseIcon
          onClick={() => {
            history.push("")
          }}
          className={classes.closeIcon}
        />
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            color="textPrimary"
          >
            Set new password
          </Typography>
          <Box className={classes.inputGroup}>
            {["password", "confirmPassword"].map((fieldKey) => (
              <Controller
                key={fieldKey}
                name={fieldKey}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors[fieldKey]}
                    placeholder={fieldKey === "password" ? "New Password" : "Repeat password"}
                    variant="outlined"
                    type="password"
                    helperText={
                      errors[fieldKey]?.message ? (
                        <Typography
                          variant="body2bold"
                          color="error"
                          style={{ textTransform: "none" }}
                        >
                          {errors[fieldKey].message}
                        </Typography>
                      ) : null
                    }
                    InputProps={{
                      className: classes.textField,
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon
                            className={classes.adornmentIcon}
                            style={{ height: "10px" }}
                            fontSize="small"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitControl}
            >
              Save password
              <CheckIcon />
            </Button>
          </Box>
        </form>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export default SetPasswordForm
