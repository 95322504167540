import MapCard from "Components/MapCard"
import PlateContainer from "Components/Plate/PlateContainer"
import PlateItem from "Components/Plate/PlateItem"
import MapImage1 from "./images/map1.png"
import MapImage2 from "./images/map2.png"
import MapImage3 from "./images/map3.png"


const MapCardsGrid = _ => (
  <PlateContainer>
    <PlateItem
      component={MapCard}
      x={2}
      y={2}
      image={MapImage1}
      title={"Sir Alonne Grifball"}
      link="#"
      gameType="elimination"
      variant="primary"
      commentsCount={756}
      likesCount={756}
      downloads={124}
    />

    <PlateItem
      component={MapCard}
      x={2}
      y={1}
      image={MapImage2}
      title={"Tim Sieve"}
      link="#"
      gameType="assault"
      commentsCount={756}
      likesCount={756}
      downloads={124}
    />

    <PlateItem
      component={MapCard}
      x={1}
      y={1}
      image={MapImage3}
      title={"Sir Alonne Grifball"}
      link="#"
      gameType="assault"
      commentsCount={756}
      likesCount={756}
      downloads={124}
    />

    <PlateItem
      component={MapCard}
      x={1}
      y={1}
      image={MapImage1}
      title={"Sir Alonne Grifball"}
      link="#"
      gameType="stronghold"
      commentsCount={756}
      likesCount={756}
      downloads={124}
    />
  </PlateContainer>
)


export default MapCardsGrid
