import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import { withStyles } from "@material-ui/core"
import { Events } from "Theme/icons"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import { GolfCourse } from "@material-ui/icons"

const EVENT = {
  CONTEST: "contest",
  TOURNAMENT: "tournament",
}

const Styles = withStyles((theme) => ({
  root: {
    background: alpha(theme.palette.background.dark, 0.25),
    backdropFilter: "blur(8px)",
    borderRadius: theme.spacing(2.75),
    padding: theme.spacing(0.75, 1.75),
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1.25),
    color: theme.palette.text.tertiary,
  },
}))

const EventType = ({ type, classes, ...props }) => {
  let icon
  switch (type) {
    case EVENT.CONTEST:
      icon = <Events className={classes.icon} />
      break
    case EVENT.TOURNAMENT:
    default:
      icon = <GolfCourse className={classes.icon} />
      break
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      {...props}
    >
      {icon}
      <Typography variant="overline">{type}</Typography>
    </Box>
  )
}

export default Styles(EventType)
