
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as FollowedIcon } from "../fontello/icons/svg/ic_followed.svg"


const Followed = ({ ...props }) => (
  <SvgIcon
    component={FollowedIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Followed
