
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as SubmissionIcon } from "../fontello/icons/svg/ic_submission.svg"


const Submission = ({ ...props }) => (
  <SvgIcon
    component={SubmissionIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Submission
