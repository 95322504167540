
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as AddIcon } from "../fontello/icons/svg/ic_add.svg"


const Add = ({ ...props }) => (
  <SvgIcon
    component={AddIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Add
