
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ChevronLeftIcon } from "../fontello/icons/svg/ic_chevron-left.svg"


const ChevronLeft = ({ ...props }) => (
  <SvgIcon
    component={ChevronLeftIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default ChevronLeft
