
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as CollectionIcon } from "../fontello/icons/svg/ic_collection.svg"


const Collection = ({ ...props }) => (
  <SvgIcon
    component={CollectionIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Collection
