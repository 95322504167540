import MuiTab from "@material-ui/core/Tab"
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "unset",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textTransform: "capitalize",
  },
  wrapper: ({ row }) => ({
    flexDirection: row ? "row" : "column",
    alignItems: "center",
    justifyContent: "center",
    // necessary for icon to be flexibly according to row property
    [row && "&.MuiTab-wrapper > *:first-child"]: {
      marginBottom: 0,
    },
  }),

}))

const Tab = ({ to, row, ...props }) => {
  const classes = useStyles({ row })

  return (
    <MuiTab
      classes={{
        root: classes.root,
        wrapper: classes.wrapper,
      }}
      {...props}
    />
  )
}

export default Tab
