
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as CoverimageIcon } from "../fontello/icons/svg/ic_coverimage.svg"


const Coverimage = ({ ...props }) => (
  <SvgIcon
    component={CoverimageIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Coverimage
