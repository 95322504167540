
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ChevronUpIcon } from "../fontello/icons/svg/ic_chevron-up.svg"


const ChevronUp = ({ ...props }) => (
  <SvgIcon
    component={ChevronUpIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default ChevronUp
