import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

const StyledTextField = withStyles((theme) => ({
  root: {
    "& .Mui-error": {
      marginLeft: 0,
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      border: `2px solid ${theme.palette.error.main} !important`,
    },
  },
}))(TextField)

export default StyledTextField
