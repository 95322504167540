import MapCard from "Components/MapCard"
import { motion } from "framer-motion"

function PreviewCard({ src, title, open }) {
  const styles = open
    ? { height: "auto", opacity: 1 }
    : { height: 0, opacity: 0 }

  return (
    <motion.div
      animate={{ ...styles, delay: 0.7 }}
    >
      <MapCard
        title={title}
        image={
          Boolean(src) ? src : `${process.env.PUBLIC_URL}/placeholder_img.svg`
        }
        disabled
      />
    </motion.div>
  )
}

export default PreviewCard
