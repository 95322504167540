import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { Box, makeStyles } from "@material-ui/core"
import ImageCard from "Components/ImageCard"

const useStyles = makeStyles((theme) => ({
  imageCardRoot: {
    margin: theme.spacing(0, 2.5, 2.5, 0),
  },
}))

const SortableItem = SortableElement(ImageCard)

const SortableList = SortableContainer(
  ({ items, onClick, uploadComponent, className }) => {
    const classes = useStyles()
    return (
      <Box
        display={"flex"}
        flexWrap="wrap"
        className={className}
      >
        {items.length > 0 &&
          items.map(({ id, image, ...rest }, index) => (
            <SortableItem
              key={id}
              src={image}
              handleClick={onClick(index)}
              rootClass={classes.imageCardRoot}
              index={index}
              {...rest}
            />
          ))}
        {uploadComponent}
      </Box>
    )
  },
)

export default SortableList
