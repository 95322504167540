
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as Canvas_1Icon } from "../fontello/icons/svg/ic_canvas-1.svg"


const Canvas_1 = ({ ...props }) => (
  <SvgIcon
    component={Canvas_1Icon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Canvas_1
