import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Typography from "Theme/Typography"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Box from "@material-ui/core/Box"
import { Maps, Community, Contest } from "Theme/icons"
import SocialLinks from "Components/SocialLinks"
import { format } from "date-fns"
import SignUpHeroImage from "./images/sign-up-hero.png"

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${SignUpHeroImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    cursor: "default",
  },
  icon: {
    overflow: "visible",
    color: theme.palette.text.tertiary,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#2E3036",
  },
  layoutFooterText: {
    lineHeight: `${theme.spacing(2.5)}px`,
  },
  listItem: {
    marginTop: theme.spacing(1),
  },
}))

function SignUpDrawer() {
  const classes = useStyles()

  const listContent = useMemo(
    () => [
      {
        title: "Maps",
        description: "Explore & follow your favorite creators and maps",
        icon: <Maps className={classes.icon} />,
      },
      {
        title: "Contests",
        description: "Start earning money for your time in the games",
        icon: <Contest className={classes.icon} />,
      },
      {
        title: "Community",
        description: " Choose the best maps together with the community",
        icon: <Community className={classes.icon} />,
      },
    ],
    [classes],
  )

  return (
    <Box className={classes.root}>
      <Box>
        {/* TODO: replace by BackgroundImage */}
        <Box
          position="relative"
          height="320px"
          className={classes.hero}
        />
        <Box
          px={7}
          py={7}
        >
          <List disablePadding>
            {listContent.map(({ title, description, icon }) => (
              <ListItem
                key={title}
                alignItems="flex-start"
                disableGutters
              >
                <ListItemAvatar>{icon}</ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 900,
                      }}
                      color="textPrimary"
                    >
                      {title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        px={7}
        py={7}
      >
        <SocialLinks />
        <Box
          display={"flex"}
          alignItems="center"
          mt={2}
        >
          <Typography
            className={classes.layoutFooterText}
            variant="body2"
          >
            ForgeHub © {format(new Date(), "yyyy")}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SignUpDrawer
