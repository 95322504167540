import React, { useState } from "react"
import { useIntervalWhen } from "rooks"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import Typography from "Theme/Typography"
import clsx from "clsx"
import Button from "Theme/Button"
import { CommunityFavoritesDescriptionPlug, CommunityFavoritesImagePlug } from "./slots"

const SLIDER_DELAY = 1000000

const useStyles = makeStyles((theme) => ({
  root: {},
  carouselContainer: {
    display: "flex",
    borderRadius: theme.spacing(1),
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: "blur(16px)",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0),
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    width: "64px",
    height: "64px",
    objectFit: "cover",
  },
  title: {
    marginBottom: theme.spacing(0.5),
    transition: theme.transitions.create("color"),
  },
  item: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    opacity: "0.8",
    transition: "all 0.2s ease 0s",
    alignItems: "stretch",

    "&:hover": {
      opacity: 1,
    },
  },
  selected: {
    opacity: 1,

    "& $title": {
      color: theme.palette.secondary.main,
    },

    "& $imageContainer": {
      position: "relative",

      "&:after": {
        content: "''",
        width: "100%",
        background: theme.palette.secondary.main,
        position: "absolute",
        height: "3px",
        borderRadius: "3px",
        bottom: 0,

        transition: theme.transitions.create("all"),

        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        borderTop: "3px solid transparent",
      },
    },
  },
  selectedBorder: {
    borderTop: "3px solid #35bde4",
  },
}))

const CommunityFavorites = ({ favorites }) => {
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedItem = favorites[selectedIndex]

  const handleNext = () => {
    if (selectedIndex === favorites.length - 1) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const handleSelect = (index) => {
    setSelectedIndex(index)
  }

  useIntervalWhen((_) => handleNext(), SLIDER_DELAY, true)

  return (
    <>
      <CommunityFavoritesImagePlug deps={[selectedItem]}>
        <img
          key={selectedItem?.image}
          src={selectedItem?.image}
          alt=""
        />
      </CommunityFavoritesImagePlug>

      <CommunityFavoritesDescriptionPlug deps={[selectedItem]}>
        <Typography
          variant="h6"
          color="secondary"
        >
          {selectedItem.month}
        </Typography>
        <Box py={2}>
          <Typography
            variant="h2"
            color="textPrimary"
          >
            {selectedItem.title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
        >
          {selectedItem.description}
        </Typography>
        <Box 
          pt={2} 
        >
          <Button
            variant="contained"
            color="secondary"
          >
            Read more
          </Button>
        </Box>
      </CommunityFavoritesDescriptionPlug>

      <Box
        display="flex"
        flexDirection="column"
        alignfavorites="flex-end"
        className={classes.root}
      >
        <Box className={classes.carouselContainer}>
          {favorites.map((item) => (
            <Box
              key={item.index}
              className={clsx(classes.item, item.index === selectedItem.index && classes.selected)}
              onClick={() => handleSelect(item.index)}
            >
              <Box
                display="flex"
                alignItems="stretch"
              >
                <Box className={classes.imageContainer}>
                  <img
                    className={classes.image}
                    src={item.image}
                    alt=""
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  marginY={2}
                  paddingX={2}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                  >
                    {item.month}
                  </Typography>

                    <Typography
                      variant="h6"
                      color={item.index === selectedItem.index ? "textPrimary" : "textSecondary"}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
    </>
  )
}

export default CommunityFavorites
export * from "./slots"
