import React, {
  useMemo,
  useRef } from "react"
import Box from "@material-ui/core/Box"
import { ParallaxContext } from "./context"
import { useElementScroll } from "framer-motion"


const PrallaxContainer = ({
  component,
  children,
  ...otherProps
}) => {
  const containerRef = useRef(null)

  const { scrollY } = useElementScroll(containerRef)

  const ContainerComponent = useMemo(_ =>
    component || Box
  ,[component])

  return (
    <ContainerComponent
      ref={containerRef}
      {...otherProps}
    >
      <ParallaxContext.Provider
        value={{
          scrollY,
        }}
      >
        { children }
      </ParallaxContext.Provider>
    </ContainerComponent>
  )
}


export default PrallaxContainer
