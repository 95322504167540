import React from "react"
import AlertMUI from "@material-ui/lab/Alert"

const Alert = ({ onClose, severity = "success", children, ...props }) => (
  <AlertMUI
    onClose={onClose}
    severity={severity}
    {...props}
  >
    {children}
  </AlertMUI>
)


export default Alert
