
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ChevronDownIcon } from "../fontello/icons/svg/ic_chevron-down.svg"


const ChevronDown = ({ ...props }) => (
  <SvgIcon
    component={ChevronDownIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default ChevronDown
