import { Box, Divider, IconButton, InputAdornment, OutlinedInput, Typography } from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import Comment from "Components/Comment"


 const Comments = () => (
   <Box
     width={"60%"}
     mx="auto"
     my={2}
   >
     <Box my={2}>
       <Typography
         variant="subtitle1"
         color="textPrimary"
       >
         Comments
       </Typography>
     </Box>
     <Box my={2}>
       <OutlinedInput
         fullWidth
         type={"text"}
         placeholder="Write a comment"
         endAdornment={
           <InputAdornment position="end">
             <IconButton
               aria-label="send comment"
               edge="end"
             >
               <SendIcon />
             </IconButton>
           </InputAdornment>
         }
       />
     </Box>
     <Box my={2}>
       <Comment
         name={"Olivia Arribas"}
         avatar={
           "https://static.bangkokpost.com/media/content/20200903/c1_3738615.jpg"
         }
         text={"BheapBox"}
         date="2 hours ago"
         votes={2}
       />
     </Box>
     <Divider />
     <Box my={2}>
       <Comment
         name={"Artur Baber"}
         avatar={
           "https://static.bangkokpost.com/media/content/20200903/c1_3738615.jpg"
         }
         text={"I love vodka and balalaika"}
         date="6 hours ago"
         votes={2}
       />
     </Box>
   </Box>
 )


export default Comments
