import React from "react"
import { Tooltip, withStyles } from "@material-ui/core"


const StyledCustomTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: "11px 10px 62px 0px rgba(0,0,0,0.66);",
    marginBottom: "10px",
  },
}))(Tooltip)

const CustomTooltip = ({ open, value, children, ...props }) => (
  <StyledCustomTooltip
    open={open}
    enterTouchDelay={0}
    placement="top"
    title={value}
    arrow
    {...props}
  >
    {children}
  </StyledCustomTooltip>
)


export default CustomTooltip
