import { createSlotAndPlug } from "react-view-slot"


export const [ FooterSlot, FooterPlug ] = createSlotAndPlug("footer")

export const [ DrawerSlot, DrawerPlug ] = createSlotAndPlug("drawer")

export const [ HeaderSlot, HeaderPlug ] = createSlotAndPlug("layoutheader")

export const [ BackgroundSlot, BackgroundPlug ] = createSlotAndPlug("background")
