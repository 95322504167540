import { makeStyles, useTheme } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import HeartIcon from "Theme/icons/Heart"
import PeopleIcon from "Theme/icons/People"
import Typography from "Theme/Typography"
import { useState } from "react"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"
import PlateContainer from "Components/Plate/PlateContainer"
import PlateItem from "Components/Plate/PlateItem"
import Card, { CardActions, CardContent } from "Theme/Card"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 262,
  },
  body: {
    minHeight: "100%",
    backgroundColor: theme.palette.background.BG3,
    position: "relative",
    padding: 0,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "initial",
  },
  savedIcon: {
    cursor: "pointer",
    opacity: 1,
    transition: theme.transitions.create("opacity"),
  },
  savedIconHidden: {
    opacity: 0,
  },
  image: {
    "&:first-child": {
      height: "128px",
      borderBottomLeftRadius: "unset",
      borderBottomRightRadius: "unset",
      maxWidth: "unset",
      margin: 0,
    },
    "&:not(&:first-child)": {
      height: "56px",
      alignSelf: "flex-end",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    "&:last-child": {
      // marginRight: theme.spacing(1),
    },
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  info: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.tertiary,
    ...theme.typography.caption,

    "& > *:first-child": {
      marginRight: theme.spacing(0.5),
    },
  },
}))

const CollectionCard = ({ title, mapsCount, likesCount, teamMode, maps, saved = false, ...otherProps }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [hover, setHover] = useState(false)
  const [isCollectionSaved, setSaved] = useState(saved)

  const handleMouseOver = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const handleSaveClick = () => {
    setSaved(!isCollectionSaved)
    // TODO: Show SnackBar with next message: The "Name" collection has been saved to your collection list. Go to Collections
  }

  return (
    <Card
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      {...otherProps}
      className={classes.root}
    >
      <CardContent className={classes.body}>
        <Box
          position="absolute"
          top={theme.spacing(2)}
          left={theme.spacing(2)}
          flex="1 1"
          onClick={handleSaveClick}
          className={clsx(classes.savedIcon, !(hover || isCollectionSaved) && classes.savedIconHidden)}
        >
          {/* TODO: use BattlePro icons */}
          {isCollectionSaved ? <BookmarkIcon color="secondary" /> : <BookmarkBorderIcon color="secondary" />}
        </Box>
        <PlateContainer
          min="1fr"
          max="100%"
          gap={theme.spacing(1)}
          // flex="1 1"
        >
          {maps.slice(0, 4).map((map, index) => (
            <PlateItem
              key={map.id}
              borderRadius="borderRadius"
              x={index ? 1 : 3}
              y={index ? 1 : 2}
              // minHeight={50}
              // marginTop={1}
              // marginLeft={1}
              className={classes.image}
              style={{
                backgroundImage: `url(${map.image})`,
                backgroundSize: "cover",
              }}
            />
          ))}
        </PlateContainer>
        <Box
          paddingX={2}
          paddingTop={2}
        >
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.title}
          >
            {title}
          </Typography>
          <CardActions className={classes.footer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="caption"
                color="tertiary"
              >
                {mapsCount} maps
              </Typography>
              <Box display="flex">
                <Box className={classes.info}>
                  <PeopleIcon fontSize="inherit" />
                  <Typography variant="caption">
                    {teamMode[0]}v{teamMode[1]}
                  </Typography>
                </Box>
                <Box
                  paddingLeft={2}
                  className={classes.info}
                >
                  <HeartIcon fontSize="inherit" />
                  <Typography variant="caption">{likesCount}</Typography>
                </Box>
              </Box>
            </Box>
          </CardActions>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CollectionCard
