
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as UsernameIcon } from "../fontello/icons/svg/ic_username.svg"


const Username = ({ ...props }) => (
  <SvgIcon
    component={UsernameIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Username
