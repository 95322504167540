
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as PrefabIcon } from "../fontello/icons/svg/ic_prefab.svg"


const Prefab = ({ ...props }) => (
  <SvgIcon
    component={PrefabIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Prefab
