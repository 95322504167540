import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"


const LastSubmissionCard = ({ image, mapName, authorName, contest }) => (
  <Box display="flex">
    <Box
      borderRadius={5}
      width={60}
      height={60}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    />
    <Box
      display="flex"
      flexDirection="column"
      marginLeft={2}
    >
      <Typography>
        {mapName}
      </Typography>
      <Box display="flex">
        <Box pr={1}>
          <Typography variant="body1">
            by
          </Typography>
        </Box>
        <Typography variant="body1">
          {authorName}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        color="textPrimary"
      >
        {contest}
      </Typography>
    </Box>
  </Box>
)


export default LastSubmissionCard
