
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as MapIcon } from "../fontello/icons/svg/ic_map.svg"


const Map = ({ ...props }) => (
  <SvgIcon
    component={MapIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Map
