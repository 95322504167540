
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as DiscordIcon } from "../fontello/icons/svg/ic_discord.svg"


const Discord = ({ ...props }) => (
  <SvgIcon
    component={DiscordIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Discord
