import React from "react"
import ListItem from "@material-ui/core/ListItem"
import Box from "@material-ui/core/Box"
import { useTheme } from "@material-ui/core"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import clsx from "clsx"
import ListItemText from "@material-ui/core/ListItemText"
import Link from "react-router-dom/Link"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    position: "relative",
    color: "inherit",
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  listItem: {
    "& .MuiSvgIcon-root": {
      transition: theme.transitions.create("color", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    "&:hover": {
      backgroundColor: "unset",
      "& .MuiSvgIcon-root": {
        color: theme.palette.text.primary,
      },
    },
  },
  itemIcon: {
    minWidth: "unset",
    color: theme.palette.text.secondary,
  },
  itemIconActive: {
    color: theme.palette.text.primary,
  },
  itemText: {
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))

const DrawerListItem = ({ title, to, icon: Icon, selected, minimized }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Link
      to={to}
      className={classes.link}
    >
      <Box
        component={ListItem}
        marginBottom={1}
        height={theme.spacing(6)}
        className={classes.listItem}
        button
      >
        {selected && (
          <Box
            position="absolute"
            borderRadius={`0px ${theme.shape.borderRadius * 10}px ${theme.shape.borderRadius * 10}px 0px`}
            width={theme.spacing(0.375)}
            height={theme.spacing(3)}
            bgcolor={theme.palette.secondary.main}
            left={0}
          />
        )}
        <Box
          component={ListItemIcon}
          paddingLeft={1}
          paddingRight={2}
          className={clsx(
            classes.itemIcon,
            selected && classes.itemIconActive,
          )}
        >
          <Icon />
        </Box>
        <Box
          component={ListItemText}
          display={minimized ? "none" : "block"}
          disableTypography
          primary={
            <Typography
              variant="h6"
              color={selected ? "textPrimary" : "textSecondary"}
              className={classes.itemText}
            >
              {title}
            </Typography>
          }
        />
      </Box>
    </Link>
  )
}


export default DrawerListItem
