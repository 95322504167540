import { withStyles } from "@material-ui/core/styles"
import { Checkbox } from "@material-ui/core"


const StyledCustomCheckbox = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$checked": {
      color: theme.palette.text.primary,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  checked: {},
}))(Checkbox)


export default StyledCustomCheckbox
