import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/styles/makeStyles"
import MapImage1 from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import Autocomplete from "Components/Autocomplete"
import ContestCard from "Components/ContestCard"
import CreateContestCard from "Components/CreateContestCard"
import GoProCard from "Components/GoProCard"
import LastSubmissionCard from "Components/LastSubmissionCard"
import { ROUTES } from "Scenes/constants"
import { Redirect, Route, Switch } from "react-router-dom"

const { CONTESTS } = ROUTES


const sortTypes = [
  {
    label: "Days Left",
    value: "days-left",
  },
  {
    label: "Rating",
    value: "rating",
  },
  {
    label: "Views",
    value: "views",
  },
]

const contest = {
  image: MapImage1,
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    backgroundColor: "#2D3035",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))





const ContestsScene = function({ path }) {
  const classes = useStyles()
  return (
    <Route path={path}>
      <Switch>
        <Route path={path}>
      <Grid
        container
        className={classes.container}
        spacing={3}
      >
        <Grid
          item
          xs={9}
        >
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              variant="h4"
              color="textPrimary"
            >
              Contests
            </Typography>
            <Box width={200}>
              <Autocomplete options={sortTypes} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
        />

        <Grid
          item
          xs={9}
        >
          <Box pb={3}>
            <ContestCard contest={contest} />
          </Box>
          <Box pb={3}>
            <ContestCard contest={contest} />
          </Box>
          <Box pb={3}>
            <ContestCard contest={contest} />
          </Box>
          <Box pb={3}>
            <ContestCard contest={contest} />
          </Box>
        </Grid>

        <Grid
          item
          xs={3}
        >
          <Box marginBottom={2}>
            <GoProCard />
          </Box>
          <Box marginBottom={2}>
            <CreateContestCard />
          </Box>

          <Box marginBottom={1}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
            >
              Last submissions
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            borderRadius={5}
            bgcolor="#35383E"
            padding={3}
          >
            <LastSubmissionCard
              image={MapImage1}
              mapName="Sir Alone Grifball"
              authorName="WackyOhio"
              contest="ForgeHub Grand Prix"
            />
            <Divider className={classes.divider} />
            <LastSubmissionCard
              image={MapImage2}
              mapName="Sir Alone Grifball"
              authorName="WackyOhio"
              contest="ForgeHub Grand Prix"
            />
            <Divider className={classes.divider} />
            <LastSubmissionCard
              image={MapImage3}
              mapName="Sir Alone Grifball"
              authorName="WackyOhio"
              contest="ForgeHub Grand Prix"
            />
            <Divider className={classes.divider} />
            <LastSubmissionCard
              image={MapImage1}
              mapName="Sir Alone Grifball"
              authorName="WackyOhio"
              contest="ForgeHub Grand Prix"
            />
          </Box>
        </Grid>
      </Grid>
    </Route>
        <Redirect
          exact
          to={`${path}/${CONTESTS}`}
        />
      </Switch>
    </Route>
  )
}

export default ContestsScene
