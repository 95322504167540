import React from "react"
import Box from "@material-ui/core/Box"
import DonutLargeIcon from "@material-ui/icons/DonutLarge"
import Typography from "Theme/Typography"
import Button from "Theme/Button"
import { withStyles } from "@material-ui/core"


const Styles = withStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.white.main,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

const GoProCard = ({ classes }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    alignItems="center"
    padding={2.5}
    borderRadius={5}
    bgcolor="#2FAFE9"
  >
    <DonutLargeIcon fontSize="large" />
    <Box paddingY={1.5}>
      <Typography>
        Earn cash prizes with a winning map
      </Typography>
    </Box>
    <Button className={classes.button}>
      Go pro
    </Button>
  </Box>
)


export default Styles(GoProCard)
