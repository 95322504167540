import { yupResolver } from "@hookform/resolvers/yup"
import { IconButton, Input, makeStyles, TextField } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import AddIcon from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import Alert from "Components/Alert"
import Snackbar from "Components/Snackbar"
import { useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Button from "Theme/Button"
import Typography from "Theme/Typography"
import * as Yup from "yup"

const useStyles = makeStyles((theme) => ({
  titleInput: {
    fontSize: theme.typography.h2.fontSize,
    paddingBottom: theme.spacing(2.5),
    fontWeight: "bold",
  },
  addFieldButton: {
    fontSize: theme.typography.body1.fontSize,
    textTransform: "none",
    padding: theme.spacing(0.125, 2.5),
    color: theme.palette.grey.main,
  },
  field: {
    maxWidth: theme.spacing(50),
    marginRight: theme.spacing(1),
  },
}))

const schema = Yup.object({
  title: Yup.string().min(1).max(25).trim().required(),
  coForgers: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().nullable().notRequired(),
      }).notRequired(),
    )
    .notRequired(),
})

const AboutStep = ({ onNext }) => {
  const classes = useStyles()
  const [alertOpen, setAlertOpen] = useState(false)
  const {
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      title: "",
      coForgers: [
        {
          value: "",
        },
      ],
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { fields, remove, prepend } = useFieldArray({
    name: "coForgers",
    control,
  })


  const handleNextStep = () => {
    if (isValid) {
      onNext()
    } else {
      console.error(errors)
    }
  }

  const addConForger = () => {
    if (fields.length < 10) {
      prepend({ value: "" })
    }
  }

  const removeField = (index) => () => {
    remove(index)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        padding={4}
        borderRadius={6}
        bgcolor="#35383E"
        minWidth={660}
        component="form"
      >
        <Typography>Title</Typography>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              className={classes.titleInput}
              required
              variant="standard"
              {...field}
            />
          )}
        />

        <Box paddingY={2}>{/* <Divider /> */}</Box>
        <Typography>Co-Forger</Typography>
        <Box
          display="flex"
          flexDirection="column"
          paddingBottom={2}
        >
          {fields.map((field, index) => (
            <Controller
              name={`coForgers.${index}.value`}
              control={control}
              key={field.id}
              render={({ field: { onChange, value } }) => (
                <Box
                  display="flex"
                  alignItems="center"
                  mb={2}
                >
                  <TextField
                    onChange={onChange}
                    variant="outlined"
                    value={value}
                    className={classes.field}
                  />
                  {fields.length > 1 && (
                    <IconButton
                      onClick={removeField(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            />
          ))}

          <Box>
            <Button
              startIcon={<AddIcon />}
              onClick={addConForger}
              className={classes.addFieldButton}
            >
              <Typography noWrap>
                Add co-forger
              </Typography>
            </Button>
          </Box>
        </Box>
        <Typography>Description</Typography>
        <TextField
          id="outlined-multiline-static"
          variant="outlined"
          multiline
          minRows={5}
        />
        <Box
          display="flex"
          justifyContent="end"
          paddingTop={2}
        >
          <Button
            variant="contained"
            disabled={!isValid}
            onClick={handleNextStep}
          >
            Continue
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={alertOpen}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
        >
          Sorry, only letters (a-z) numbers (0-9), hyphens (-) and periods (.) are allowed
        </Alert>
      </Snackbar>
    </>
  )
}

export default AboutStep
