
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as MapsIcon } from "../fontello/icons/svg/ic_maps.svg"


const Maps = ({ ...props }) => (
  <SvgIcon
    component={MapsIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Maps
