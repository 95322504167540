import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"


const Styles = withStyles((theme) => ({
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}))


const MapInfoRow = ({
  classes,
  title,
  mapsCount,
}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    <Typography
      variant="h5"
      color="textPrimary"
      className={classes.title}
    >
      {title}
    </Typography>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="caption">{mapsCount} maps</Typography>
    </Box>
  </Box>
)

export default Styles(MapInfoRow)
