import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"

import IconButton from "@material-ui/core/IconButton"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import makeStyles from "@material-ui/core/styles/makeStyles"

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Map3 from "Assets/img/map3.png"
import clsx from "clsx"
import AboutStep from "Components/AboutStep"
import ArmoryStep from "Components/ArmoryStep"
import DetailsStep from "Components/DetailsStep"
import GeneralStep from "Components/GeneralStep"
import MediaStep from "Components/MediaStep"
import SaveOrSubmitMap from "Components/SaveOrSubmitMap"
import React from "react"
import { useCallback } from "react"
import { useState } from "react"
import { Case, Default, Switch } from "react-if"
import { Route } from "react-router-dom"
import Grow from "Theme/Transitions/Grow"
import Typography from "Theme/Typography"
import BackButton from "./BackButton"
import PreviewCard from "./PreviewCard"
import CustomConnector from "./StepConnector"
import StepIcon from "./StepIcon"

const useStyles = makeStyles((theme) => ({
  content: {
    width: `calc(100% - ${theme.spacing(7.5)}px)`,
    height: "80vh",
    transition: "1.3s ease all",
  },
  isOpen: {
    width: "80%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    height: 90,
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  bottom: {
    width: "100%",
    position: "absolute",
    height: "100%",
    bottom: 0,
    background:
      "linear-gradient(rgba(21,21,28,0.4) 0%, rgba(21,21,28,0.7) 25%, rgba(21,21,28,0.8) 40%, rgba(21,21,28,1) 50%, rgba(21,21,28,1) 75%, rgba(21,21,28,1) 100%)",
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "none",
  },
  stepperBody: {
    backgroundColor: "transparent",
  },
  drawer: {
    marginLeft: theme.spacing(-3),
    transition: "1.3s ease all",
    width: theme.spacing(7.5),
  },
  drawerOpen: {
    width: "20%",
  },
  label: {
    margin: 0,
  },
  margin: {
    margin: theme.spacing(2),
    transition: "1.3s ease all",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "1.3s ease all",
  },
  typographyMargin: {
    transition: "1.3s ease all",
    margin: 0,
    marginRight: theme.spacing(-6.25),
  },
  typographyMarginOpen: {
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
}))

function getSteps() {
  return ["General", "About", "Media", "Details", "Armory", "Summary"]
}

const ForgeMap = ({ path }) => {
  const classes = useStyles()
  const steps = getSteps()
  const [activeStep, setActiveStep] = useState(0)
  const [open, setOpen] = React.useState(true)
  const title = "Forge Map"
  const src = Map3

  const handleDrawerToggle = () => {
    setOpen((isOpen) => !isOpen)
  }

  const handleNextStep = useCallback(() => {
    setActiveStep((prevStep) => ++prevStep)
  }, [setActiveStep])

  const handleBackStep = useCallback(() => {
    setActiveStep((prevStep) => --prevStep)
  }, [setActiveStep])

  return (
    <Route path={path}>
      <Box display={"flex"}>
        <Box className={clsx(classes.drawer, { [classes.drawerOpen]: open })}>
          <div
            className={clsx(classes.drawerHeader, {
              [classes.drawerHeaderOpen]: open,
            })}
          >
            <Box
              className={clsx(classes.typographyMargin, {
                [classes.typographyMarginOpen]: open,
              })}
            >
              <Grow
                in={open}
                timeout={{ enter: 700, exit: 700 }}
              >
                <Typography
                  variant="h6"
                  color="textPrimary"
                >
                  Preview
                </Typography>
              </Grow>
            </Box>

            <IconButton onClick={handleDrawerToggle}>{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </div>
          <Box
            className={clsx(classes.margin, {
              [classes.marginZero]: !open,
            })}
          >
            <PreviewCard
              title={title}
              src={src}
              open={open}
            />
          </Box>

          <Divider />

          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className={classes.stepperBody}
            connector={<CustomConnector />}
          >
            {steps.map((label) => (
              <Step
                key={label}
                expanded
              >
                <StepLabel StepIconComponent={StepIcon}>
                  <Grow
                    in={open}
                    timeout={{ enter: 700, exit: 700 }}
                  >
                    <span className={classes.label}>{label}</span>
                  </Grow>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box className={clsx(classes.content, { [classes.isOpen]: open })}>
          <Box
            position="relative"
            height={"100%"}
            mr={-6}
          >
            <Box height={"100%"}>
              {Boolean(src) && (
                <img
                  src={src}
                  className={classes.img}
                  alt="Map as background"
                />
              )}
            </Box>
            <Box className={classes.bottom}>
              <Box
                display="flex"
                flexDirection="column"
                m={4}
                mx={12}
              >
                <Box>
                  <BackButton onBack={() => (activeStep > 0 ? handleBackStep() : false)} />
                  <Typography
                    variant="h3"
                    color="textPrimary"
                  >
                    Submit map
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  mt={3}
                >
                  <Box width="50%">
                    <Switch>
                      <Case condition={activeStep === 0}>
                        <GeneralStep onNext={handleNextStep} />
                      </Case>
                      <Case condition={activeStep === 1}>
                        <AboutStep onNext={handleNextStep} />
                      </Case>
                      <Case condition={activeStep === 2}>
                        <MediaStep
                          onNext={handleNextStep}
                          setDrawerPreviewData={() => {}}
                        />
                      </Case>
                      <Case condition={activeStep === 3}>
                        <DetailsStep onNext={handleNextStep} />
                      </Case>
                      <Case condition={activeStep === 4}>
                        <ArmoryStep onNext={handleNextStep} />
                      </Case>
                      <Default>
                        <Typography variant="subtitle1">No steps found</Typography>
                      </Default>
                    </Switch>
                  </Box>
                  <Box width="20%" />
                  <Box
                    width="30%"
                    maxWidth={"350px"}
                  >
                    <SaveOrSubmitMap percent={40} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Route>
  )
}

export default ForgeMap
