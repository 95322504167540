import { HashRouter, Redirect, Route, Switch } from "react-router-dom"
import Footer from "Components/Footer"
import Header from "Components/Header"
import MainDrawer from "Components/MainDrawer"
import { ParallaxContainer } from "Components/Parallax"
import MainLayout, { DrawerPlug, FooterPlug, HeaderPlug } from "Components/MainLayout"
import { HeaderControlsPlug } from "Components/Header"
import AuthScene from "./Auth"
import CollectionsScene from "./Collections"
import CommunityFavoritesScene from "./CommunityFavorites"
import ContestsScene from "./Contests"
import ForgeMapScene from "./ForgeMap"
import HomeScene from "./Home"
import MapsScene from "./Maps"
import PrefabsScene from "./Prefabs"
import ProfileScene from "./Profile"
import TestScene from "./Test"
import LoginScene from "./Login"
import ResetPasswordScene from "./ResetPassword"
// Absolute import
import { ROUTES } from "Scenes/constants"
import HeaderMainControls from "Components/HeaderMainControls"

const {
  HOME,
  AUTH,
  INDEX,
  MAPS,
  PREFABS,
  COLLECTIONS,
  CONTESTS,
  COMMUNITY_FAVORITES,
  FORGE_MAP,
  PROFILE,
  TEST,
  LOGIN,
} = ROUTES

const App = () => (
  <>
    <MainLayout bodyElement={ParallaxContainer}>
      <Switch>
        <AuthScene path={`${INDEX}${AUTH}`} />

        <Route path={`${INDEX}`}>
          <DrawerPlug>
            <MainDrawer />
          </DrawerPlug>

          <HeaderPlug>
            <Header />
          </HeaderPlug>

          <HeaderControlsPlug>
            <HeaderMainControls />
          </HeaderControlsPlug>

          <FooterPlug>
            <Footer />
          </FooterPlug>

          <Switch>
            <HomeScene path={`${INDEX}${HOME}`} />

            <MapsScene path={`${INDEX}${MAPS}`} />

            <PrefabsScene path={`${INDEX}${PREFABS}`} />

            <CollectionsScene path={`${INDEX}${COLLECTIONS}`} />

            <ContestsScene path={`${INDEX}${CONTESTS}`} />

            <CommunityFavoritesScene path={`${INDEX}${COMMUNITY_FAVORITES}`} />

            <CommunityFavoritesScene path={`${INDEX}${COMMUNITY_FAVORITES}`} />

            <ForgeMapScene path={`${INDEX}${FORGE_MAP}`} />

            <ProfileScene path={`${INDEX}${PROFILE}`} />

            <LoginScene path={`${INDEX}${LOGIN}`} />

            <TestScene path={`${INDEX}${TEST}`} />

            <Redirect to={`${INDEX}${HOME}`} />
          </Switch>
        </Route>

        <Redirect to={`${INDEX}${HOME}`} />
      </Switch>
    </MainLayout>

    <HashRouter
      hashType="noslash"
      basename=""
    >
      <LoginScene path={`/${ROUTES.LOGIN}`} />

      <ResetPasswordScene path={`/${ROUTES.RESET_PASSWORD}`} />
    </HashRouter>
  </>
)

export { App }
