import React, { useEffect, useRef, useState } from "react"
import { withStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import MapCard from "Components/MapCard"
import Typography from "@material-ui/core/Typography"
import { motion, useAnimation } from "framer-motion"

const Styles = withStyles((theme) => ({
  scrollContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    columnGap: theme.spacing(3),
    paddingTop: theme.spacing(5),
    marginTop: -theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginBottom: -theme.spacing(5),
  },
  card: {
    width: 256,
    height: 192,
  },
}))

const ScrollRow = ({ classes, title, maps, onMapClick }) => {
  const [sliderWidth, setSliderWidth] = useState(0)
  const wrapperContainer = useRef(null)
  const scrollContainer = useRef(null)
  const controls = useAnimation()

  useEffect(() => {
    setSliderWidth(-(scrollContainer.current.scrollWidth - wrapperContainer.current.clientWidth))
  }, [])

  return (
    <Box
      paddingTop={5}
      paddingBottom={2}
      position="relative"
    >
      <Box paddingBottom={2.5}>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {title}
        </Typography>
      </Box>
      <Box
        ref={wrapperContainer}
        display="flex"
        position="relative"
      >
        <motion.div
          drag="x"
          dragConstraints={{
            right: 0,
            left: sliderWidth,
          }}
          ref={scrollContainer}
          className={classes.scrollContainer}
          animate={controls}
        >
          {maps.map((map) => (
            <MapCard
              map={map}
              key={map.id}
              title={map.title}
              link="#"
              image={map.image}
              className={classes.card}
              gameType={map.gameType}
              info={map.info}
              stats={map.stats}
              downloadsCount={map.downloadsCount}
              messagesCount={map.messagesCount}
              likesCount={map.likesCount}
              description={map.description}
              variant={map.variant}
              teamMode={map.mapSize}
              onClick={onMapClick}
            />
          ))}
        </motion.div>
      </Box>
    </Box>
  )
}

export default Styles(ScrollRow)
