import React from "react"
import { FormControlLabel, Grid, withStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import Button from "Theme/Button"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Checkbox from "Theme/Checkbox"

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    backgroundColor: "#35383E",
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#35383E",
    borderBottom: "1px solid #ffffff1f",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const weaponGroups = [
  {
    id: "сivilian",
    title: "Civilian",
    weapons: [
      {
        id: "gold-club",
        title: "Golf Club (7 Wood)",
      },
    ],
  },
  {
    id: "сivilian",
    title: "Forerunner",
    weapons: [
      {
        id: "binary-rifle",
        title: "Binary Rifle",
      },
      {
        id: "binary-rifle-variant",
        title: "Binary Rifle Variant",
      },
      {
        id: " boltshot",
        title: " Boltshot",
      },
      {
        id: " boltshot-variant",
        title: " Boltshot Variant",
      },
      {
        id: " incineration-cannon",
        title: " Incineration Cannon",
      },
      {
        id: " incineration-cannon-variant",
        title: " Incineration Cannon Variant",
      },
      {
        id: " light-rifle",
        title: " Light Rifle",
      },
      {
        id: " light-rifle-variant",
        title: " Light Rifle Variant",
      },
      {
        id: " scattershot",
        title: " Scattershot",
      },
      {
        id: " scattershot-variant",
        title: " Scattershot Variant",
      },
      {
        id: " sentinel-beam",
        title: " Sentinel Beam",
      },
      {
        id: " splinter-grenade",
        title: " Splinter Grenade",
      },
      {
        id: " splinter-turret",
        title: " Splinter Turret",
      },
      {
        id: " suppressor",
        title: " Suppressor",
      },
      {
        id: "suppressor-variant",
        title: "Suppressor Variant",
      },
    ],
  },
  {
    id: "covenant",
    title: "Covenant",
    weapons: [
      {
        id: "beam-rifle",
        title: "Beam Rifle",
      },
      {
        id: " beam-rifle-variant",
        title: " Beam Rifle Variant",
      },
      {
        id: " beam-rifle-halo-2",
        title: " Beam Rifle Halo 2",
      },
      {
        id: " carbine",
        title: " Carbine",
      },
      {
        id: " carbine-variant",
        title: " Carbine Variant",
      },
      {
        id: " energy-sword",
        title: " Energy Sword",
      },
      {
        id: " energy-sword-variant",
        title: " Energy Sword Variant",
      },
      {
        id: " fuel-rod-cannon",
        title: " Fuel Rod Cannon",
      },
      {
        id: " fuel-rod-cannon-variant",
        title: " Fuel Rod Cannon Variant",
      },
      {
        id: " hunter-arm-(wicked-grasp)",
        title: " Hunter Arm (Wicked Grasp)",
      },
      {
        id: " hunter-arm-(berserker's-claw)",
        title: " Hunter Arm (Berserker's claw)",
      },
      {
        id: "needler",
        title: "Needler",
      },
      {
        id: " needler-variant",
        title: " Needler Variant",
      },
      {
        id: " plasma-caster",
        title: " Plasma Caster",
      },
      {
        id: " plasma-caster-variant",
        title: " Plasma Caster Variant",
      },
      {
        id: " plasma-grenade",
        title: " Plasma Grenade",
      },
      {
        id: " plasma-pistol",
        title: " Plasma Pistol",
      },
      {
        id: " plasma-pistol-variant",
        title: " Plasma Pistol Variant",
      },
      {
        id: " plasma-turret",
        title: " Plasma Turret",
      },
      {
        id: " storm-rifle",
        title: " Storm Rifle",
      },
      {
        id: " storm-rifle-variant",
        title: " Storm Rifle Variant",
      },
    ],
  },
  {
    id: "brute",
    title: "Brute",
    weapons: [
      {
        id: "brute-plasma-rifle",
        title: "Brute Plasma Rifle",
      },
      {
        id: " brute-plasma-rifle-variant",
        title: " Brute Plasma Rifle Variant",
      },
      {
        id: "gravity-hammer",
        title: "Gravity Hammer",
      },
      {
        id: " gravity-hammer-variant",
        title: " Gravity Hammer Variant",
      },
    ],
  },
  {
    id: "unsc",
    title: "UNSC",
    weapons: [
      {
        id: "assault-rifle",
        title: "Assault Rifle",
      },
      {
        id: " assault-rifle-variant",
        title: " Assault Rifle Variant",
      },
      {
        id: " battle-rifle",
        title: " Battle Rifle",
      },
      {
        id: " battle-rifle-variant",
        title: " Battle Rifle Variant",
      },
      {
        id: " battle-rifle-halo-2",
        title: " Battle Rifle Halo 2",
      },
      {
        id: " chaingun-turret",
        title: " Chaingun Turret",
      },
      {
        id: " dmr",
        title: " DMR",
      },
      {
        id: " dmr-variant",
        title: " DMR Variant",
      },
      {
        id: " frag-grenade",
        title: " Frag Grenade",
      },
      {
        id: " gauss-turret",
        title: " Gauss Turret",
      },
      {
        id: " grenade-launcher",
        title: " Grenade Launcher",
      },
      {
        id: " grenade-launcher-variant",
        title: " Grenade Launcher Variant",
      },
      {
        id: " hydra-launcher",
        title: " Hydra Launcher",
      },
      {
        id: " hydra-launcher-variant",
        title: " Hydra Launcher Variant",
      },
      {
        id: " magnum",
        title: " Magnum",
      },
      {
        id: " magnum-variant",
        title: " Magnum Variant",
      },
      {
        id: " magnum-halo-ce",
        title: " Magnum Halo CE",
      },
      {
        id: " railgun",
        title: " Railgun",
      },
      {
        id: "railgun-variant",
        title: "Railgun Variant",
      },
      {
        id: " rocket-launcher",
        title: " Rocket Launcher",
      },
      {
        id: " rocket-launcher-variant",
        title: " Rocket Launcher Variant",
      },
      {
        id: " rocket-launcher-spnkr",
        title: " Rocket Launcher SPNKr",
      },
      {
        id: " rocket-launcher-spnkr-variant",
        title: " Rocket Launcher SPNKr Variant",
      },
      {
        id: " rocket-turret",
        title: " Rocket Turret",
      },
      {
        id: " saw",
        title: " SAW",
      },
      {
        id: " saw-variant",
        title: " SAW Variant",
      },
      {
        id: " shotgun",
        title: " Shotgun",
      },
      {
        id: " shotgun-variant",
        title: " Shotgun Variant",
      },
      {
        id: " smg",
        title: " SMG",
      },
      {
        id: " smg-variant",
        title: " SMG Variant",
      },
      {
        id: " sniper-rifle",
        title: " Sniper Rifle",
      },
      {
        id: " sniper-rifle-variant",
        title: " Sniper Rifle Variant",
      },
      {
        id: " spartan-laser",
        title: " Spartan Laser",
      },
      {
        id: " spartan-laser-variant",
        title: " Spartan Laser Variant",
      },
      {
        id: " weapon-pad:-custom-weapon",
        title: " Weapon Pad: Custom Weapon",
      },
    ],
  },
]

const powerUps = [
  {
    id: "overshield",
    title: "Overshield",
  },
  {
    id: " camo",
    title: " Camo",
  },
  {
    id: "damage-boost",
    title: "Damage Boost",
  },
  {
    id: " speed-boost",
    title: " Speed boost",
  },
]

const vehicles = [
  {
    id: "scorpion",
    title: "Scorpion",
  },
  {
    id: " mantis",
    title: " Mantis",
  },
  {
    id: " warthog",
    title: " Warthog",
  },
  {
    id: " rocket-warthog",
    title: " Rocket Warthog",
  },
  {
    id: " gauss-warthog",
    title: " Gauss Warthog",
  },
  {
    id: " gungoose",
    title: " Gungoose",
  },
  {
    id: " phaeton",
    title: " Phaeton",
  },
  {
    id: "wraith",
    title: "Wraith",
  },
  {
    id: " banshee",
    title: " Banshee",
  },
  {
    id: " ghost",
    title: " Ghost",
  },
  {
    id: " mongoose",
    title: " Mongoose",
  },
  {
    id: " wasp",
    title: " Wasp",
  },
  {
    id: " custom-forge-vehicle",
    title: " Custom Forge Vehicle",
  },
]

const ArmoryStep = ({ onNext }) => {
  const [expanded, setExpanded] = React.useState("unsc")

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleCheck = (weaponId, selected) => {
    console.log(`Weapon: ${weaponId} selected: ${selected}`)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={4}
      borderRadius={6}
      bgcolor="#35383E"
      minWidth={660}
    >
      {weaponGroups.map((weaponGroup) => (
        <Accordion
          key={weaponGroup.id}
          square
          expanded={expanded === weaponGroup.id}
          onChange={handleChange(weaponGroup.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="textPrimary">Weapons</Typography>
            <Box paddingLeft={0.5}>
              <Typography>({weaponGroup.title})</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              style={{
                width: "100%",
              }}
            >
              {weaponGroup.weapons.map((weapon) => (
                <Grid
                  key={weapon.id}
                  item
                  xs={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        onChange={(event) => handleCheck(weapon.id, event.target.checked)}
                      />
                    }
                    label={<Typography color="textPrimary">{weapon.title}</Typography>}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box
        p={2}
        pb={0}
      >
        <Typography color="textPrimary">Powerups</Typography>
      </Box>
      <Grid
        container
        style={{
          width: "100%",
          padding: 16,
        }}
      >
        {powerUps.map((powerUp) => (
          <Grid
            key={powerUp.id}
            item
            xs={6}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  onChange={(event) => handleCheck(powerUp.id, event.target.checked)}
                />
              }
              label={<Typography color="textPrimary">{powerUp.title}</Typography>}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        p={2}
        pb={0}
      >
        <Typography color="textPrimary">Vehicles</Typography>
      </Box>
      <Grid
        container
        style={{
          width: "100%",
          padding: 16,
        }}
      >
        {vehicles.map((vehicle) => (
          <Grid
            key={vehicle.id}
            item
            xs={6}
          >
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  onChange={(event) => handleCheck(vehicle.id, event.target.checked)}
                />
              }
              label={<Typography color="textPrimary">{vehicle.title}</Typography>}
            />
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        justifyContent="end"
        paddingTop={2}
      >
        <Button
          variant="contained"
          onClick={onNext}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default ArmoryStep
