
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as EmailIcon } from "../fontello/icons/svg/ic_email.svg"


const Email = ({ ...props }) => (
  <SvgIcon
    component={EmailIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Email
