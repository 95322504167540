
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as EditIcon } from "../fontello/icons/svg/ic_edit.svg"


const Edit = ({ ...props }) => (
  <SvgIcon
    component={EditIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Edit
