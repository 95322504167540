import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/styles/makeStyles"
import InfoLabel from "Theme/InfoText"

const useStyles = makeStyles((theme) => ({
  container: ({ mapImage }) => ({
    backgroundImage: `linear-gradient(
        180deg,
        rgba(32, 33, 38, 0.2) 0%,
        rgba(32, 33, 38, 0.6446) 44.01%,
        rgba(32, 33, 38, 0.92) 100%
      ),
      url(${mapImage})`,
  }),
}))

const ActivityEventCard = ({ title, prize, mapSize, mapImage, creator }) => {
  const classes = useStyles({ mapImage })
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      borderRadius="borderRadius"
      height="100%"
      paddingY={1}
      paddingX={2}
      className={classes.container}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h5"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          paddingTop={0.5}
        >
          <Typography variant="caption">{creator}</Typography>
          <Box
            display="flex"
            paddingLeft={1}
          >
            <Box paddingLeft={2}>
              <InfoLabel
                value={prize}
                markedValue
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ActivityEventCard
