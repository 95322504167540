
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as ContestIcon } from "../fontello/icons/svg/ic_contest.svg"


const Contest = ({ ...props }) => (
  <SvgIcon
    component={ContestIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Contest
