import { StepConnector, withStyles } from "@material-ui/core"


const CustomConnector = withStyles((theme) => ({
  root: {
    paddingBottom: 0,
  },
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: theme.palette.green.main,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: theme.palette.green.main,
    },
  },
  line: {
    border: 0,
    backgroundColor: theme.palette.grey.main,
    borderRadius: 1,
    width: 4,
    height: 50,
  },
  vertical: {
    marginLeft: 5.5,
    marginTop: -2,
    marginBottom: -2,
  },
}))(StepConnector)

export default CustomConnector
