import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Paper from "@material-ui/core/Paper"
import { default as MapImage1, default as MapImage4 } from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import CreatorCardsGrid from "Components/CreatorCardsGrid"
import EventCard from "Components/EventCard"
import GunImage from "Components/GunImage"
import MapCard from "Components/MapCard"
import MapsSubheaderTabs from "Components/MapsSubheaderTabs"
import PlateContainer from "Components/Plate/PlateContainer"
import PlateItem from "Components/Plate/PlateItem"
import ProfileHeader from "Components/ProfileHeader"
import { ROUTES } from "Scenes/constants"
import { Route } from "react-router"
import Button from "Theme/Button"
import { Core, Infection } from "Theme/icons"
import Typography from "Theme/Typography"
import AwardBadge from "Components/AwardBadge"
import FavoriteIcon from "Theme/icons/Favorite"
import HeartIcon from "Theme/icons/Heart"
import EventsIcon from "Theme/icons/Events"
import CollectionCard from "Components/CollectionCard"

const { INDEX, MAPS } = ROUTES

const maps = [
  {
    id: "map1",
    title: "Map1",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Strongholds",
    icon: Core,
    comments: 766,
    path: `${INDEX}${MAPS}/1`,
    liked: true,
    favorite: true,
    awarded: true,
  },
  {
    id: "map2",
    title: "Map2",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,
    favorite: true,
    awarded: true,

    path: `${INDEX}${MAPS}/2`,
  },
  {
    id: "map3",
    title: "Map3",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,
    favorite: true,
    path: `${INDEX}${MAPS}/3`,
  },
  {
    id: "map4",
    title: "Map4",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/4`,
  },
  {
    id: "map5",
    title: "Map5",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    liked: true,
    favorite: true,
    awarded: true,

    path: `${INDEX}${MAPS}/5`,
  },
  {
    id: "map6",
    title: "Map6",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,
    path: `${INDEX}${MAPS}/6`,
  },
]

const contest = {
  id: "1",
  title: "FORGEHUB Contest #9",
  type: "contest",
  ending: "11d",
  prize: "$1000",
  mapTypeIcon: "Elimination",
  mapType: "Race",
  mapSize: "4v4",
  mapImage: MapImage1,
  creator: "Forgehub",
}

const creatorMaps = [
  {
    id: "map1",
    title: "Map1",
    description: "Description1",
    image: MapImage1,
    mapSize: "4x4",
    rating: "4.9",
  },
  {
    id: "map2",
    title: "Map2",
    description: "Description2",
    image: MapImage2,
    mapSize: "5x5",
    rating: "2.1",
  },
  {
    id: "map3",
    title: "Map3",
    description: "Description3",
    image: MapImage3,
    mapSize: "6x10",
    rating: "3.3",
  },
]

const creators = [
  {
    id: "1",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,
    name: "Petr Pertovich",
    followersCount: 1213,
    mapsCount: 22,
    prefabsCount: 99,
    collectionsCount: 45,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
  {
    id: "2",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,

    name: "Ivan Ivanovich",
    followersCount: 21,
    mapsCount: 223,
    prefabsCount: 9,
    collectionsCount: 5,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
  {
    id: "3",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,
    name: "Petr Pertovich",
    followersCount: 145,
    mapsCount: 22,
    prefabsCount: 99,
    collectionsCount: 45,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
]

export default function Test({ path }) {
  return (
    <Route path={path}>
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h5">Plate Grid</Typography>
          <Divider />
        </Box>

        {/* #TAG */}
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <PlateContainer>
              <PlateItem
                component={MapCard}
                x={2}
                y={2}
                image={MapImage1}
                title={"Sir Alonne Grifball"}
                link="#"
                gameType="elimination"
                variant="primary"
                commentsCount={756}
                likesCount={756}
                downloads={124}
              />

              <PlateItem
                component={MapCard}
                x={2}
                y={1}
                image={MapImage2}
                title={"Tim Sieve"}
                link="#"
                gameType="assault"
                commentsCount={756}
                likesCount={756}
                downloads={124}
              />

              <PlateItem
                component={CollectionCard}
                x={1}
                y={1}
                title="Best covenant maps"
                mapsCount={10}
                likesCount="14k"
                teamMode={[8, 8]}
                maps={maps}
              />

              <PlateItem
                component={MapCard}
                x={1}
                y={1}
                image={MapImage4}
                title={"Sir Alonne Grifball"}
                link="#"
                gameType="stronghold"
                commentsCount={756}
                likesCount={756}
                downloads={124}
              />
            </PlateContainer>
          </Grid>
        </Grid>

        <Box my={4}>
          <Typography variant="h5">Typography</Typography>
          <Divider />
        </Box>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Typography
              variant="h1"
              gutterBottom
            >
              H1
            </Typography>
            <Typography
              variant="h2"
              gutterBottom
            >
              H2
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
            >
              H3
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
            >
              H4
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
            >
              H5
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
            >
              H6
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              variant="body1"
              gutterBottom
            >
              Body1
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
            >
              Body2
            </Typography>
            <Box>
              <Typography
                variant="body2bold"
                gutterBottom
              >
                Body2bold
              </Typography>
            </Box>
            <Typography
              variant="overline"
              gutterBottom
            >
              Overline
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              variant="h5"
              gutterBottom
            >
              Subhead
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
            >
              Subtitle1
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
            >
              Subtitle2
            </Typography>
            <Typography
              variant="button"
              gutterBottom
            >
              Button
            </Typography>
            <Box>
              <Typography
                variant="caption"
                gutterBottom
              >
                Caption
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box my={4}>
          <Typography variant="h5">Buttons</Typography>
          <Divider />
        </Box>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
        >
          <Grid
            item
            xs={4}
          >
            <Box
              component={Typography}
              variant="h6"
              display="block"
              m={2}
            >
              Outlined
            </Box>

            <Box p={1}>
              <Button
                variant="outlined"
                color="default"
              >
                outlined default
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="primary"
              >
                outlined primary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
              >
                outlined secondary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="error"
              >
                outlined error
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="secondary"
                disabled
              >
                disabled
              </Button>
            </Box>
          </Grid>
          <Divider
            flexItem
            orientation="vertical"
          />
          <Grid
            item
            xs={4}
          >
            <Box
              component={Typography}
              variant="h6"
              display="block"
              m={2}
            >
              Contained
            </Box>

            <Box p={1}>
              <Button
                variant="contained"
                color="default"
              >
                contained default
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                color="primary"
              >
                contained primary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                color="secondary"
              >
                contained secondary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                color="error"
              >
                contained error
              </Button>
            </Box>
          </Grid>
          <Divider
            flexItem
            orientation="vertical"
          />
          <Grid
            item
            xs={4}
          >
            <Box
              component={Typography}
              variant="h6"
              display="block"
              m={2}
            >
              Text
            </Box>

            <Box p={1}>
              <Button
                variant="text"
                color="default"
              >
                text default
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="text"
                color="primary"
              >
                text primary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="text"
                color="secondary"
              >
                text secondary
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="text"
                color="error"
              >
                text error
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box my={4}>
          <Typography variant="h5">Inputs</Typography>
          <Divider />
        </Box>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
            container
          >
            <Box p={1}>
              <OutlinedInput placeholder="OutlinedInput" />
            </Box>

            <Box p={1}>
              <OutlinedInput
                placeholder="OutlinedInput disabled"
                disabled
              />
            </Box>
          </Grid>
        </Grid>

        <Box my={4}>
          <Typography variant="h5">Guns</Typography>
          <Divider />
        </Box>

        <PlateContainer mb={4}>
          {[
            "Brute Gravity Hammer",
            "Brute Plasma Rifle Variant",
            "Brute Plasma Rifle",
            "Civilian Golf 1",
            "Covenant Beam Rifle Variant",
            "Covenant Beam Rifle",
            "Covenant Carbine Variant",
            "Covenant Carbine",
            "Covenant Energy Sword",
            "Covenant Hunter Arm",
            "Covenant Needler variant",
            "Covenant Needler",
            "Covenant Plasma Caster",
            "Covenant Plasma Grenade",
            "Covenant Plasma Pistol variant",
            "Covenant Plasma Pistol",
            "Covenant Storm Rifle",
            "Forerunner Binary Rifle",
            "Forerunner Boltshot",
            "Forerunner Light Rifle",
            "Forerunner Scattershot",
            "Forerunner Sentinel Beam",
            "Forerunner Splinter Grenade",
            "Forerunner Splinter Turret",
            "Forerunner Suppressor",
            "Forerunner incineration cannon",
            "UNSC Assault Rifle Variant",
            "UNSC Assault Rifle",
            "UNSC Battle Rifle Halo2",
            "UNSC Battle Rifle Variant",
            "UNSC Battle Rifle",
            "UNSC Chaingun Turret",
            "UNSC DMR Variant",
            "UNSC DMR",
            "UNSC Frag Granade",
            "UNSC Gauss Turret",
            "UNSC Grenade Launcher",
            "UNSC Hydra Launcher",
            "UNSC Magnum Halo CE",
            "UNSC Magnum",
            "UNSC Railgun",
            "UNSC Rocket Launcher SPNKr",
            "UNSC Rocket Launcher",
            "UNSC Rocket Turret",
            "UNSC SAW",
            "UNSC SMG Variant",
            "UNSC SMG",
            "UNSC Shotgun Variant",
            "UNSC Shotgun",
            "UNSC Sniper Rifle Variant",
            "UNSC Sniper Rifle",
            "UNSC Spartan Laser",
          ].map((item) => (
            <PlateItem
              key={item}
              component={Paper}
              x={1}
              y={1}
              p={2}
              display="flex"
              alignItems="center"
            >
              <Box
                width="50%"
                mr={2}
              >
                <GunImage name={item} />
              </Box>

              <Divider
                py={2}
                orientation="vertical"
                flexItem
              />

              <Box
                component={Typography}
                variant="body2"
                pl={2}
              >
                {item}
              </Box>
            </PlateItem>
          ))}
        </PlateContainer>

        <Box my={4}>
          <Typography variant="h5">Award Badges</Typography>
          <Divider />
        </Box>

        <Grid container>
          <Box
            component={Grid}
            xs={12}
            display="flex"
            justifyContent="flex-start"
          >
            <Box mx={1}>
              <AwardBadge
                color="green"
                icon={<FavoriteIcon fontSize="small" />}
              />
            </Box>
            <Box mx={1}>
              <AwardBadge
                color="red"
                icon={<HeartIcon fontSize="medium" />}
              />
            </Box>
            <Box mx={1}>
              <AwardBadge
                color="yellow"
                icon={<EventsIcon fontSize="large" />}
              />
            </Box>
          </Box>
        </Grid>

        <Box my={4}>
          <Typography variant="h5">Tabs</Typography>
          <Divider />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <MapsSubheaderTabs path={path} />
          </Grid>
        </Grid>

        <Box my={4}>
          <Typography variant="h5">Events</Typography>
          <Divider />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <EventCard data={contest} />
          </Grid>
        </Grid>
        <Box my={4}>
          <Typography variant="h5">Profile header</Typography>
          <Divider />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <ProfileHeader />
          </Grid>
        </Grid>
        <Box my={4}>
          <Typography variant="h5">CreatorCardsGrid</Typography>
          <Divider />
        </Box>
        <Box
          component={Grid}
          container
          pb={4}
        >
          <Grid
            item
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              <CreatorCardsGrid creators={creators} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Route>
  )
}
