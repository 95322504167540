import { FileUploader } from "react-drag-drop-files"
import { Download } from "Theme/icons"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import clsx from "clsx"
import Snackbar from "Components/Snackbar"
import Alert from "Components/Alert"
import { useState } from "react"

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"]

const errorMessageSize = "Maximum image size must be 5mb"
const errorMessageType = "Only GIF JPG PNG formats are supported"

const useStyles = makeStyles((theme) => ({
  uploadIcon: {
    transform: "rotate(180deg)",
    backgroundColor: theme.palette.dark.secondary,
    opacity: 0.75,
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    color: "#9298AA",
  },
  dropZone: {
    height: theme.spacing(14),
    width: theme.spacing(21),
    cursor: "pointer",
    border: "1px dashed rgba(146, 152, 170, 0.3)",
    opacity: 0.95,
    "&:hover": {
      borderColor: "rgba(146, 152, 170, 0.1)",
      "& > .uploadText": {
        color: "rgba(146, 152, 170, 0.3)",
      },
      "& > .uploadIcon": {
        backgroundColor: "rgba(146, 152, 170, 0.1)",
      },
    },
  },
}))



const UploadForm = ({ handleChange }) => {
  const classes = useStyles()
  const [error, setError] = useState("")


  const handleError = (typeOfError) => () => {
    setError(typeOfError)
  }

  const handleUpload = (files) => {
    setError("")
    handleChange(files)
  }

  return (
    <>
      <Snackbar open={Boolean(error)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <FileUploader
        handleChange={handleUpload}
        name="file"
        types={fileTypes}
        multiple
        maxSize={5}
        onSizeError={handleError(errorMessageSize)}
        onTypeError={handleError(errorMessageType)}
      >
        <Box
          borderRadius={8}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          flexDirection="column"
          className={classes.dropZone}
        >
          <Box className={clsx(classes.uploadIcon, "uploadIcon")}>
            <Download />
          </Box>
          <Box
            color="#fff"
            width="70%"
          >
            <Typography
              color="inherit"
              align="center"
            >
              Drag or click to upload images
            </Typography>
          </Box>
        </Box>
      </FileUploader>
    </>
  )
}

export default UploadForm
