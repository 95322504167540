import Box from "@material-ui/core/Box"
import Tabs from "@material-ui/core/Tabs"
import { useState } from "react"
import { useHistory } from "react-router"
import GameTypeIcon from "Components/GameTypeIcon"
import Tab from "Theme/Tab"
import Typography from "Theme/Typography"

const getParams = (location) => new URL(location).searchParams
const params = getParams(window.location)

const MapsSubheaderTabs = ({ path }) => {
  const [value, setValue] = useState(params.get("type"))
  const history = useHistory()

  const handleChange = (_, newValue) => {
    setValue(newValue)
    const newPath = `${path}${newValue ? `?type=${newValue}` : ""}`
    history.push(newPath)
  }

  return (
    <Box
      display="flex"
      ml={5}
    >
      <Tabs
        value={value}
        onChange={handleChange}
      >
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              All
            </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="all" />
            </Box>
          }
          row
          value={null}
        />
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              Core
            </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="core" />
            </Box>
          }
          row
          value={"core"}
        />
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              Infection
            </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="infection" />
            </Box>
          }
          row
          value={"infection"}
        />
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              Elimination
            </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="elimination" />
            </Box>
          }
          row
          value={"elimination"}
        />
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              Mini-game
            </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="mini-game" />
            </Box>
          }
          row
          value={"mini-game"}
        />
        <Tab
          label={
            <Typography
              variant="body2"
              color="textTertiary"
            >
              Race
              </Typography>
          }
          icon={
            <Box mr={1}>
              <GameTypeIcon type="race" />
            </Box>
          }
          row
          value={"race"}
        />
      </Tabs>
    </Box>
  )
}

export default MapsSubheaderTabs
