import { Box } from "@material-ui/core"
import React from "react"
import Button from "Theme/Button"
import SearchField from "Components/SearchField"
import { ROUTES } from "Scenes/constants"

const HeaderMainControls = () => (
  <>
    <Box width={215}>
      <SearchField />
    </Box>

    <Box ml={2}>
      <Button
        variant="contained"
        onClick={() => {
          window.location.hash = ROUTES.LOGIN
        }}
      >
        Login
      </Button>
    </Box>
  </>
)

export default HeaderMainControls
