import CardActions from "@material-ui/core/CardActions"
import { withStyles } from "@material-ui/core/styles"

const StyledCardActions = withStyles(_ => ({
  root: {
    zIndex: 1,
    padding: 0,
  },
}))(CardActions)


export default StyledCardActions
