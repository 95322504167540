import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core/styles"
import { App } from "Scenes/App"
import CssBaseline from "@material-ui/core/CssBaseline"
import { SlotProvider } from "react-view-slot"
import { theme } from "Theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import "./index.css"
import { createRoot } from "react-dom/client"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"

const container = document.getElementById("root")
const root = createRoot(container)
const queryClient = new QueryClient()

console.log("🎨", theme)

root.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SlotProvider>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </SlotProvider>
    </ThemeProvider>
  </>,
)
