import { Link, Route } from "react-router-dom"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "Theme/Typography"
import EventCard from "Components/EventCard"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import PlateContainer from "Components/Plate/PlateContainer"
import PlateItem from "Components/Plate/PlateItem"
// #TODO Cut this shit
import MapImage1 from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import { BackgroundPlug } from "Components/MainLayout"
import CommunityFavorites, {
  CommunityFavoritesDescriptionSlot,
  CommunityFavoritesImageSlot,
} from "Components/CommunityFavorites"
import PageBackground from "Components/PageBackground"
import ScrollRow from "Components/ScrollRow"
import { ParallaxItem } from "Components/Parallax"
import { ROUTES } from "Scenes/constants"
import { Race } from "Theme/icons"
import mapImage from "Assets/img/halo-map.jpeg"
import withStyles from "@material-ui/styles/withStyles"
import CollectionCard from "Components/CollectionCard"

const { COLLECTIONS, MAPS, INDEX, PREFABS, CONTESTS } = ROUTES

const favorites = [
  {
    month: "January",
    title: "Community Favorites",
    description:
      "Welcome back to the last of ForgeHub's Monthly Community Favorites of 2019 (I know its 2020 where a bit behind here...)",
    path: MAPS,
    image: MapImage1,
    index: 0,
  },
  {
    month: "February",
    title: "Community Favorites",
    description:
      "Welcome back to the last of ForgeHub's Monthly Community Favorites of 2019 (I know its 2020 where a bit behind here...)",
    path: PREFABS,
    image: MapImage2,
    index: 1,
  },
  {
    month: "March",
    title: "Community Favorites",
    description:
      "Welcome back to the last of ForgeHub's Monthly Community Favorites of 2019 (I know its 2020 where a bit behind here...)",
    path: COLLECTIONS,
    image: MapImage3,
    index: 2,
  },
]

const maps = [
  {
    id: "map1",
    title: "Map1",
    image: MapImage1,
    mapSize: "4x4",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map2",
    title: "Map2",
    image: MapImage2,
    mapSize: "5x5",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map3",
    title: "Map3",
    image: MapImage3,
    mapSize: "6x10",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map4",
    title: "Map4",
    image: MapImage1,
    mapSize: "4x4",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map5",
    title: "Map5",
    image: MapImage2,
    mapSize: "5x5",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map6",
    title: "Map6",
    image: MapImage3,
    mapSize: "6x10",
    author: "Petya",
    downloads: 123,
    likes: 88,
    gameType: "Race",
    icon: Race,
    path: `${INDEX}${MAPS}/1`,
  },
]

const events = [
  {
    id: "1",
    title: "FORGEHUB Contest #9",
    type: "contest",
    ending: "11d",
    prize: "$1000",
    mapType: "Elimination",
    mapSize: "4v4",
    mapImage: mapImage,
    creator: "Forgehub",
  },
  {
    id: "2",
    title: "Evolved",
    type: "contest",
    ending: "1d",
    prize: "$10",
    mapType: "Elimination",
    mapSize: "4v4",
    mapImage: mapImage,
    creator: "BattlePro",
  },
  {
    id: "3",
    title: "Grand Prix",
    type: "contest",
    ending: "90d",
    prize: "$666",
    mapType: "Elimination",
    mapImage: mapImage,
    creator: "Forgehub",
  },
]

const Styles = withStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
}))

const HomeScene = ({ classes, path }) => (
  <Route path={path}>
    <BackgroundPlug>
      <PageBackground
        height={570}
        imageElement={<CommunityFavoritesImageSlot />}
      />
    </BackgroundPlug>
    <Box>
      <Container maxWidth="lg">
        <Box
          component={Grid}
          mt={8}
          container
        >
          <Grid
            item
            xs={4}
          >
            <CommunityFavoritesDescriptionSlot />
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Box
          component={Grid}
          mb={5}
          container
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={7}
          >
            <ParallaxItem
              component={CommunityFavorites}
              favorites={favorites}
            />
          </Grid>
        </Box>
      </Container>

      <Box
        component={Container}
        fullwidth
      >
        <ScrollRow
          title="Trending Now"
          maps={maps}
        />
      </Box>

      <Box
        component={Container}
        fullwidth
      >
        <ScrollRow
          title="Featured"
          maps={maps}
        />
      </Box>

      <Box
        component={Container}
        fullwidth
      >
        <ScrollRow
          title="New Releases"
          maps={maps}
        />
      </Box>
    </Box>
    <Box
      pt={3}
      pb={4}
      mt={7.5}
      bgcolor="background.paper"
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Latest Events
          </Typography>
          <Box
            component={Link}
            to={`${INDEX}${CONTESTS}`}
            display="flex"
            alignItems="center"
            gridGap={16}
            className={classes.link}
          >
            <Typography
              variant="button"
              color="secondary"
            >
              View all
            </Typography>
            {/* TODO: use ForgeHub icons */}
            <ArrowForwardIcon
              color="secondary"
              fontSize="inherit"
            />
          </Box>
        </Box>
        <PlateContainer
          min="200px"
          max="290px"
          rowHeight="300px"
        >
          <PlateItem
            x={4}
            y={2}
          >
            <EventCard event={events[0]} />
          </PlateItem>
          <PlateItem
            x={2}
            y={1}
          >
            <EventCard event={events[1]} />
          </PlateItem>
          <PlateItem
            x={2}
            y={1}
          >
            <EventCard event={events[2]} />
          </PlateItem>
        </PlateContainer>
      </Container>
    </Box>
    <Box
      component={Container}
      fullwidth
    >
      <ScrollRow
        title="Infection"
        maps={maps}
      />
    </Box>
    <Box
      component={Container}
      fullwidth
    >
      <ScrollRow
        title="Halo Remakes"
        maps={maps}
      />
    </Box>
    <Box
      component={Container}
      fullwidth
      pb={8}
    >
      <ScrollRow
        title="Mini Games"
        maps={maps}
      />
    </Box>
    <Box
      pt={3}
      pb={4}
      mt={7.5}
      bgcolor="background.paper"
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Top Collections
          </Typography>
          <Box
            component={Link}
            to={`${INDEX}${COLLECTIONS}`}
            display="flex"
            alignItems="center"
            gridGap={16}
            className={classes.link}
          >
            <Typography
              variant="button"
              color="secondary"
            >
              View all
            </Typography>
            {/* TODO: use ForgeHub icons */}
            <ArrowForwardIcon
              color="secondary"
              fontSize="inherit"
            />
          </Box>
        </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={3}
          >
            <CollectionCard
              title="Big team battle"
              mapsCount={30}
              likesCount="24k"
              teamMode={[4, 4]}
              maps={maps}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <CollectionCard
              title="Big team battle"
              mapsCount={30}
              likesCount="24k"
              teamMode={[4, 4]}
              maps={maps}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <CollectionCard
              title="Big team battle"
              mapsCount={30}
              likesCount="24k"
              teamMode={[4, 4]}
              maps={maps}
            />
          </Grid>

          <Grid
            item
            xs={3}
          >
            <CollectionCard
              title="Big team battle"
              mapsCount={30}
              likesCount="24k"
              teamMode={[4, 4]}
              maps={maps}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </Route>
)

export default Styles(HomeScene)
