
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as WarningIcon } from "../fontello/icons/svg/ic_warning.svg"


const Warning = ({ ...props }) => (
  <SvgIcon
    component={WarningIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Warning
