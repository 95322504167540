import axios from "axios"
import { useMutation } from "react-query"

const baseUrl = "https://staging.forgehub.com/api2/login/"

const useSocialLogin = () => {
  const { mutate, isLoading } = useMutation((type) => (window.location.href = baseUrl.concat(type)))
  return {
    login: mutate,
    isLoading,
  }
}

export default useSocialLogin
