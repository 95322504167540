import { alpha, Avatar, Grid, makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import Typography from "Theme/Typography"
import Cell from "./Cell"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    borderColor: "#9298aa1f",
    boxShadow:
      "rgb(0 0 0 / 15%) -15px -15px 30px 0px, rgb(0 0 0 / 35%) 15px 15px 30px 0px",
    borderRadius: "0.5rem",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.BG3,
    height: "100%",
    justifyContent: "space-between",
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  iconButton: {
    backgroundColor: "#35BDE4",
    padding: 14,
    "&:hover": {
      backgroundColor: alpha("#35BDE4", 0.85),
    },
  },
}))

const CreatorCard = ({
  avatar,
  name,
  followersCount,
  mapsCount,
  prefabsCount,
  collectionsCount,
  experience,
  maps,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Avatar
            className={classes.avatar}
            src={avatar}
          />
          <Box
            display="flex"
            flexDirection="column"
            pl={1}
          >
            <Typography color="textPrimary">
              {name}
            </Typography>
            <Typography>Map Creator</Typography>
          </Box>
        </Box>
        <IconButton classes={{ root: classes.iconButton }}>
          <PersonAddIcon fontSize="small" />
        </IconButton>
      </Box>

      <Cell
        name="EXP"
        description={experience}
      />

      {/* <Box
        display="flex"
        alignItems="center"
        pl={1}
      >

        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: 10 }}
        />
        <Counter
          count={prefabsCount}
          label="prefabs"
        />
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: 10 }}
        />
        <Counter
          count={collectionsCount}
          label="collections"
        />
      </Box> */}

      <Grid
        container
        spacing={3}
      >
        {maps.map((map) => (
          <Grid
            item
            xs={4}
            key={map.id}
          >
            <Box
              component="img"
              src={map.image}
              borderRadius={8}
              width={"100%"}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CreatorCard
