import { createTheme } from "@material-ui/core/styles"

const {
  palette: { augmentColor },
} = createTheme()

// #TODO Cut
const BG1 = "#191A1E"
const BG2 = "#202126"
const BG3 = "#25272D"
const BG4 = "#2E3036"
const BG5 = "#36383F"
const BG6 = "#42454E"
const BG7 = "#4D515D"
const BG8 = "#9298AA"
const BG9 = "#B5B5BE"
const BG10 = "#FAFAFB"

const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: ["Poppins", "Avenir"].join(","),
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 36,
      lineHeight: "48px",
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 28,
      lineHeight: "32px",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 500,
    },
    body1: {
      fontFamily: "'Avenir', sans-serif",
      fontSize: 18,
      lineHeight: "32px",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "'Avenir', sans-serif",
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 400,
    },
    button: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: 600,
    },
    caption: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      lineHeight: "24px",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    subtitle1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      lineHeight: "16px",
      fontWeight: 500,
    },
    overline: {
      fontFamily: "'Avenir', sans-serif",
      fontSize: 10,
      lineHeight: "12px",
      fontWeight: 800,
    },
  },
  palette: {
    type: "dark",

    // Standart colors
    primary: {
      light: "#00C692",
      main: "#00AE70",
      dark: "#00964E",
      contrastText: "#fff",
    },
    secondary: {
      light: "#01BFE0",
      main: "#01A3D2",
      dark: "#0187C4",
      contrastText: "#fff",
    },
    background: {
      default: "#191A1E",
      paper: "#202126",
      dark: "#0f0f15",
      BG1,
      BG2,
      BG3,
      BG4,
      BG5,
      BG6,
      BG7,
      BG8,
      BG9,
      BG10,
    },
    text: {
      primary: "#FAFAFB",
      secondary: "#CBD1E2",
      tertiary: "#9298AA",
      disabled: "rgba(146, 152, 170, 0.5)",
      hint: "#92929D",
    },
    success: {
      ...augmentColor({ main: "#72B52B" }),
      contrastText: "#fff",
    },
    warning: {
      ...augmentColor({ main: "#F3982C" }),
      contrastText: "#fff",
    },
    error: {
      dark: "#FB3F3F",
      main: "#FC5A5A",
      light: "#FD7575",
      contrastText: "#fff",
    },

    action: {
      active: "#B5B5BE",
      hover: "#B5B5BE",
      selected: "#B5B5BE",
      disabledOpacity: 0.5,
      disabled: "#9298AA",
    },

    // Custom colors
    purple: {
      ...augmentColor({ main: "#A461D8" }),
      contrastText: "#fff",
    },
    green: {
      ...augmentColor({ main: "#3DD598" }),
      contrastText: "#fff",
    },
    red: {
      ...augmentColor({ main: "#FC5A5A" }),
      contrastText: "#fff",
    },
    dark: {
      ...augmentColor({ main: "#0F0F14", secondary: "#24252A" }),
      contrastText: "#FAFAFB",
    },
    white: {
      ...augmentColor({ main: "#FFF" }),
      contrastText: "#0F0F14",
    },
    grey: {
      ...augmentColor({ main: "#42454e", secondary: "#DFE3E9" }),
      contrastText: "#FAFAFB",
    },
  },

  shape: {
    borderRadius: 8,
  },

  props: {
    MuiTextField: {
      size: "small",
      variant: "outlined",
      fullWidth: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTypography: {
      color: "textSecondary",
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html, body": {
          backgroundColor: "#191A1E",
        },
      },
    },
    MuiFormControl: {
      root: {
        display: "block",
      },
    },
    MuiInputAdornment: {
      positionStart: {
        color: "#c2c2c4",
      },
    },
    MuiInputBase: {
      root: {
        transition: "all 0.2s ease",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        "&.Mui-focused": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "#FFFFFF",
        borderRadius: "6px",
        "& input": {
          borderRadius: "6px",
          transition: "all 0.2s ease",
          "&::placeholder": {
            color: "#4D515D",
            opacity: 1,
          },
        },
        "& fieldset": {
          border: "none",
          transition: "all 0.2s ease",
        },
        "&.Mui-focused": {
          "& fieldset": {
            "&.MuiOutlinedInput-notchedOutline": {
              border: "2px solid #01A3D2",
            },
          },
        },
      },
    },
  },
})

export default theme
