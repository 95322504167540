import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Button from "Theme/Button"
import {
  Microsoft as MicrosoftIcon,
  Google as GoogleIcon,
  Discord as DiscordIcon,
  Twitter as TwitterIcon,
} from "Theme/icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"
import useSocialLogin from "./hooks"

const useStyles = makeStyles((theme) => ({
  socialLink: {
    flex: 1,
    marginLeft: theme.spacing(2),
    backgroundColor: "#ffffff0d",
    "&:first-child": {
      marginLeft: 0,
    },
  },
  socialIcon: {
    color: theme.palette.text.secondary,
    width: theme.spacing(2),
    height: theme.spacing(2),
    overflow: "visible",
  },
}))

const SocialLinks = () => {
  const classes = useStyles()
  const { login, isLoading } = useSocialLogin()

  return (
    <Box display="flex">
      <Button
        variant="contained"
        className={classes.socialLink}
        onClick={() => login("microsoft")}
      >
        <MicrosoftIcon className={classes.socialIcon} />
      </Button>
      <Button
        variant="contained"
        className={classes.socialLink}
        onClick={() => login("google")}
      >
        <GoogleIcon className={classes.socialIcon} />
      </Button>
      <Button
        variant="contained"
        className={classes.socialLink}
        onClick={() => login("discord")}
      >
        <DiscordIcon className={classes.socialIcon} />
      </Button>
      <Button
        variant="contained"
        className={classes.socialLink}
        onClick={() => login("twitter")}
      >
        <TwitterIcon className={classes.socialIcon} />
      </Button>
      <Backdrop
        style={{ zIndex: 2 }}
        open={isLoading}
      >
          <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
  )
}

export default SocialLinks
