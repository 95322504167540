import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import { withStyles } from "@material-ui/core"
import { alpha } from "@material-ui/core/styles/colorManipulator"

const Styles = withStyles((theme) => ({
  root: {
    minWidth: 64,
    height: 64,
    background: alpha(theme.palette.background.dark, 0.25),
    backdropFilter: "blur(8px)",
    borderRadius: theme.spacing(2.5),
  },
}))

const InfoCard = ({ title, value, classes }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    p={1.5}
    className={classes.root}
  >
    {value}
    <Typography
      variant="caption"
      color="tertiary"
    >
      {title}
    </Typography>
  </Box>
)

export default Styles(InfoCard)
