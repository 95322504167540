import { Box, makeStyles } from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import clsx from "clsx"

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    zIndex: 1,
    color: "#fff",
    width: 15,
    height: 15,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${theme.palette.grey.main}`,
    fontSize: 12,
  },
  active: {
    backgroundColor: theme.palette.white.main,
    border: `2px solid ${theme.palette.white.main}`,
  },
  activeDot: {
    height: 4,
    width: 4,
    backgroundColor: theme.palette.green.main,
    borderRadius: "50%",
  },
  completed: {
    backgroundColor: theme.palette.green.main,
    border: `2px solid ${theme.palette.green.main}`,
  },
}))

function StepIcon(props) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed && <DoneIcon fontSize="inherit" />}
      {active && <Box className={classes.activeDot} />}
    </div>
  )
}

export default StepIcon
