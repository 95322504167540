import Box from "@material-ui/core/Box"
import MapImage0 from "Assets/img/halo-map.jpeg"
import { default as MapImage1, default as MapImage4 } from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import ActivityCard from "Components/ActivityCard"
import ActivityCollectionCard from "Components/ActivityCollectionCard"
import ActivityEventCard from "Components/ActivityEventCard"
import ActivityMapCard from "Components/ActivityMapCard"
import CommentCard from "Components/CommentCard"
import { Route } from "react-router"
import { Add, Events, Forums, Infection } from "Theme/icons"

const mapsImages = [MapImage1, MapImage2, MapImage3, MapImage4]

const contest = {
  id: "1",
  title: "FORGEHUB Contest #9",
  type: "contest",
  ending: "11d",
  prize: "$1000",
  mapTypeIcon: "Elimination",
  mapType: "Race",
  mapSize: "4v4",
  mapImage: MapImage0,
  creator: "Forgehub",
}

const comment = {
  author: "Sir Alonne Grifball",
  comment:
    "Praise the Forerunners! These all look GORGEOUS; getting all sweaty just imagining the tactical slaying brutaility that these playgrounds could offer bla-bla",
  time: "5 min ago",
  likes: 54,
}

const map = {
  title: "SS: The second encounter",
  image: MapImage3,
  gameType: "Assualt",
  icon: Infection,
  likes: 28,
  size: "8v8",
}

const collection = {
  title: "Best for 4v4",
  mapsCount: 16,
  mapSize: "4v4",
  likes: 16,
  mapsImages,
}

export default function Activity({ path }) {
  return (
    <Route path={`${path}`}>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box>Activity</Box>
        <Box display="flex">
          <Box padding={1}>
            <ActivityCard
              icon={Events}
              title="entered contest"
            >
              <ActivityEventCard
                title={contest.title}
                prize={contest.prize}
                mapSize={contest.mapSize}
                mapImage={contest.mapImage}
                creator={contest.creator}
              />
            </ActivityCard>
          </Box>
          <Box padding={1}>
            <ActivityCard
              icon={Forums}
              title="commented on a map"
            >
              <CommentCard
                author={comment.author}
                comment={comment.comment}
                time={comment.time}
                likes={comment.likes}
              />
            </ActivityCard>
          </Box>
          <Box padding={1}>
            <ActivityCard
              icon={Add}
              title="posted new map"
            >
              <ActivityMapCard
                title={map.title}
                image={map.image}
                icon={map.icon}
                gameType={map.gameType}
                size={map.size}
                likes={map.likes}
              />
            </ActivityCard>
          </Box>
          <Box padding={1}>
            <ActivityCard
              icon={Add}
              title="posted new collection"
            >
              <ActivityCollectionCard
                title={collection.title}
                mapsCount={collection.mapsCount}
                mapSize={collection.mapSize}
                likes={collection.likes}
                mapsImages={collection.mapsImages}
              />
            </ActivityCard>
          </Box>
        </Box>
      </Box>
    </Route>
  )
}
