import React, { useMemo } from "react"
import { withStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"

const Styles = withStyles(_ => ({
  background: {
    overflow: "hidden",
    position: "absolute",
    right: 0,
    left: 0,
    top: 0,
    zIndex: "0",
    maxHeight: "650px",

    "&:after": {
      content: "''",
      display: "block",
      background: `
        linear-gradient(
          180deg,
          rgba(25, 26, 30, 0.2) 60.8%,
          rgba(25, 26, 30, 0.78) 73.04%,
          #191A1E 88.61%)
        `,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
    },

    "&:before": {
      content: "''",
      display: "block",
      backdropFilter: "blur(20px)",
      height: "20%",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },

    "& > *": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}))

const PageBackground = ({ classes, imageElement, height = 350, ...otherProps }) => {
  const Image = useMemo((_) => React.cloneElement(imageElement), [imageElement])

  return (
    <Box
      className={classes.background}
      height={height}
      {...otherProps}
    >
      {Image}
    </Box>
  )
}

export default Styles(PageBackground)
