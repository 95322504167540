import React, { useState } from "react"
import { makeStyles, TextField  } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import Button from "Theme/Button"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import GameTypesGrid from "Components/GameTypesGrid"
import { Core, Elimination, Grifball, Infection, Minigame, Puzzle, Race, Support } from "Theme/icons"
import ImageIcon from "@material-ui/icons/Image"
import Tooltip from "Theme/Tooltip"
import { v4 } from "uuid"
import * as R from "ramda"

const gameTypesInitialState = [
  {
    label: "Core",
    icon: Core,
    secondarySelected: true,
    id: v4(),
  },
  {
    label: "Infection",
    icon: Infection,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Elimination",
    icon: Elimination,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Mini-game",
    icon: Minigame,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Race",
    icon: Race,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Grifball",
    icon: Grifball,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Aesthetic",
    icon: ImageIcon,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Puzzle",
    icon: Puzzle,
    secondarySelected: false,
    id: v4(),
  },
]



const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    color: theme.palette.grey.light,
  },
}))

const schema = Yup.object({
  waypoint: Yup.string().matches(
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i,
  ).trim().required(),
})

const isSecondary = R.propEq("secondarySelected", true)

const setSecondary = R.mergeLeft({
  secondarySelected: true,
  primarySelected: false,
})
const unsetSecondary = R.mergeLeft({
  secondarySelected: false,
  primarySelected: false,
})


const GeneralStep = ({ onNext }) => {
  const classes = useStyles()
  const [gameTypes, setGameTypes] = useState(gameTypesInitialState)
  const {
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      waypoint: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const handleNextStep = () => {
    if (isValid) {      
      onNext()
    } else {
      console.error(errors)
    }
  }

  const handleGameTypeSelect = (index) => () => {
    const selectedGameTypes = R.adjust(
      index,
      R.ifElse(isSecondary, unsetSecondary, setSecondary),
    )
    setGameTypes(selectedGameTypes)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={4}
      borderRadius={6}
      bgcolor="#35383E"
      minWidth={660}
      component="form"
    >
      <Typography>Select Category</Typography>
      <GameTypesGrid
        gameTypes={gameTypes}
        onSelect={handleGameTypeSelect}
        canAddNew={false}
      />

      <Box paddingY={1} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>Halo Waypoint Link</Typography>
        <Tooltip value="What is this?">
          <Box className={classes.icon}>
            <Support color="inherit" />
          </Box>
        </Tooltip>
      </Box>
      <Controller
        name="waypoint"
        control={control}
        render={({ field }) => (
          <TextField
            required
            variant="outlined"
            {...field}
          />
        )}
      />
      <Box
        display="flex"
        justifyContent="end"
        paddingTop={2}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          onClick={handleNextStep}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default GeneralStep
