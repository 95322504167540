import axios from "axios"
import { useMutation } from "react-query"

const useLogin = () => {
  const { mutate, isLoading } = useMutation((variables) =>
    axios.post("https://staging.forgehub.com/api2/login", variables).then((res) => res.data),
  )

  return {
    login: mutate,
    isLoading,
  }
}

export default useLogin
