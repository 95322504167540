import { LinearProgress, withStyles } from "@material-ui/core"


const Styles = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#2B2E33",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}))


export default Styles(LinearProgress)
