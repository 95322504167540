
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as LearningIcon } from "../fontello/icons/svg/ic_learning.svg"


const Learning = ({ ...props }) => (
  <SvgIcon
    component={LearningIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Learning
