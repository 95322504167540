
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as EyeIcon } from "../fontello/icons/svg/ic_eye.svg"


const Eye = ({ ...props }) => (
  <SvgIcon
    component={EyeIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Eye
