import { Avatar as MUIAvatar, withStyles } from "@material-ui/core"

const Styles = withStyles((theme) => ({
  avatar: {
    height: 128,
    width: 128,
  },
}))

const Avatar = ({ classes, ...props }) => (
  <MUIAvatar
    className={classes.avatar}
    {...props}
  />
)


export default Styles(Avatar)
