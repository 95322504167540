
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as SupportIcon } from "../fontello/icons/svg/ic_support.svg"


const Support = ({ ...props }) => (
  <SvgIcon
    component={SupportIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Support
