import { default as MUITypography } from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"

const Styles = withStyles((theme) => ({
  body2bold: {
    fontFamily: "'Avenir', sans-serif",
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 800,
  },
  colorTertiary: {
    color: theme.palette.text.tertiary,
  },
  button: {
    textTransform: "none",
  },
}))

export default Styles(MUITypography)
