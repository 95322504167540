import { v4 } from "uuid"

export const createImageItem = ({
  file = null,
  id,
  image = null,
  youTubeVideoId = null,
}) => ({
  file,
  image: image ? image : file ? URL.createObjectURL(file) : null,
  id: id ? id : v4(),
  isVideo: Boolean(youTubeVideoId),
})
