import { yupResolver } from "@hookform/resolvers/yup"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import AddIcon from "@material-ui/icons/Add"
import Alert from "Components/Alert"
import Snackbar from "Components/Snackbar"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Typography from "Theme/Typography"
import * as Yup from "yup"
import { createImageItem } from "./utils"


const useStyles = makeStyles((theme) => ({
  field: {
    maxWidth: theme.spacing(42),
    marginRight: theme.spacing(1),
  },
  input: {
    padding: theme.spacing(1.25, 1.75),
  },
  addFieldButton: {
    fontSize: theme.typography.body1.fontSize,
    textTransform: "none",
    padding: theme.spacing(0.125, 1.5),
    color: alpha(theme.palette.text.primary, 0.5),
  },
}))

const YouTubeBaseRegEx = /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/
const YouTubeRegEx =
  /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/

function YouTubeGetID(url) {
  const newUrl = url.split(YouTubeBaseRegEx)
  return newUrl[2] !== undefined
    ? newUrl[2].split(/[^0-9a-z_-]/i)[0]
    : newUrl[0]
}

const schema = Yup.object().shape({
  youtubeLink: Yup.string().matches(YouTubeRegEx, {
    message: "Check that the Youtube link is correct",
  }),
})

const LinkForm = ({ onSubmit }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const {
    control,
    getValues,
    formState: { isValid, errors },
    watch,
  } = useForm({
    defaultValues: {
      youtubeLink: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  const linkWatcher = watch("youtubeLink")

  const addVideo = () => {
    if (!isValid) {
      setOpen(true)
    } else {
      const youTubeVideoId = YouTubeGetID(getValues("youtubeLink"))
      const image = `https://i.ytimg.com/vi/${youTubeVideoId}/maxresdefault.jpg`

      const gridItem = createImageItem({
        youTubeVideoId,
        image,
      })

      onSubmit((prev) => [...prev, gridItem])
    }
  }

  useEffect(() => {
    setOpen(false)
  }, [linkWatcher])

  return (
    <>
      <Snackbar open={open}>
        <Alert severity="error">{errors?.youtubeLink?.message}</Alert>
      </Snackbar>
      <Typography>Youtube Link</Typography>
      <Box display="flex">
        <Controller
          control={control}
          name="youtubeLink"
          render={({ field }) => (
            <OutlinedInput
              {...field}
              fullWidth
              className={classes.field}
              classes={{
                input: classes.input,
              }}
              placeholder={"https://"}
            />
          )}
        />

        <Button
          startIcon={<AddIcon color="inherit" />}
          onClick={addVideo}
          className={classes.addFieldButton}
        >
          Add video link
        </Button>
      </Box>
    </>
  )
}

export default LinkForm
