import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"


const Comment = ({ name, avatar, text, date, votes }) => (
  <Box display="flex">
    <Box
      component="img"
      src={avatar}
      width={50}
      height={50}
      borderRadius={50}
    />
    <Box
      display="flex"
      flexDirection="column"
      flexBasis="100%"
      marginLeft={2}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">
          {name}
        </Typography>
        <Typography>
          {date}
        </Typography>
      </Box>
      <Box>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          {text}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Rating:
        </Typography>
        <Box paddingLeft={1}>
          <Typography color="textPrimary">
            {votes}{" "} votes
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
)


export default Comment
