import { Box } from "@material-ui/core"
import React from "react"
import { useLocation } from "react-router-dom"
import Tab from "Components/Tab"

const MapFilters = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  return (
    <Box display="flex">
      <Tab
        isActive={!params.get("type")}
        to={`${location.pathname}`}
        name="New"
      />

      <Tab
        isActive={params.get("type") === "trending"}
        to={`${location.pathname}?type=trending`}
        name="Trending"
      />

      <Tab
        isActive={params.get("type") === "featured"}
        to={`${location.pathname}?type=featured`}
        name="Featured"
      />
    </Box>
  )
}

export default MapFilters
