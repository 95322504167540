import React, {
  useMemo } from "react"
import _get from "lodash/get"
import { ReactComponent as BruteGravityHammer } from "./images/Gravity Hammer.svg"
import { ReactComponent as BrutePlasmaRifleVariant } from "./images/Plasma Rifle Variant.svg"
import { ReactComponent as BrutePlasmaRifle } from "./images/Plasma Rifle.svg"
import { ReactComponent as CivilianGolf1 } from "./images/Golf 1.svg"
import { ReactComponent as CovenantBeamRifleVariant } from "./images/Beam Rifle Variant.svg"
import { ReactComponent as CovenantBeamRifle } from "./images/Beam Rifle.svg"
import { ReactComponent as CovenantCarbineVariant } from "./images/Carbine Variant.svg"
import { ReactComponent as CovenantCarbine } from "./images/Carbine.svg"
import { ReactComponent as CovenantEnergySword } from "./images/Energy Sword.svg"
import { ReactComponent as CovenantHunterArm } from "./images/Hunter Arm.svg"
import { ReactComponent as CovenantNeedlervariant } from "./images/Needler variant.svg"
import { ReactComponent as CovenantNeedler } from "./images/Needler.svg"
import { ReactComponent as CovenantPlasmaCaster } from "./images/Plasma Caster.svg"
import { ReactComponent as CovenantPlasmaGrenade } from "./images/Plasma Grenade.svg"
import { ReactComponent as CovenantPlasmaPistolvariant } from "./images/Plasma Pistol variant.svg"
import { ReactComponent as CovenantPlasmaPistol } from "./images/Plasma Pistol.svg"
import { ReactComponent as CovenantStormRifle } from "./images/Storm Rifle.svg"
import { ReactComponent as ForerunnerBinaryRifle } from "./images/Binary Rifle.svg"
import { ReactComponent as ForerunnerBoltshot } from "./images/Boltshot.svg"
import { ReactComponent as Forerunnerincinerationcannon } from "./images/Incineration Cannon.svg"
import { ReactComponent as ForerunnerLightRifle } from "./images/Light Rifle.svg"
import { ReactComponent as ForerunnerScattershot } from "./images/Scattershot.svg"
import { ReactComponent as ForerunnerSentinelBeam } from "./images/Sentinel Beam.svg"
import { ReactComponent as ForerunnerSplinterGrenade } from "./images/Splinter Grenade.svg"
import { ReactComponent as ForerunnerSplinterTurret } from "./images/Splinter Turret.svg"
import { ReactComponent as ForerunnerSuppressor } from "./images/Suppressor.svg"
import { ReactComponent as UNSCAssaultRifleVariant } from "./images/Assault Rifle Variant.svg"
import { ReactComponent as UNSCAssaultRifle } from "./images/Assault Rifle.svg"
import { ReactComponent as UNSCBattleRifleHalo2 } from "./images/Battle Rifle Halo2.svg"
import { ReactComponent as UNSCBattleRifleVariant } from "./images/Battle Rifle Variant.svg"
import { ReactComponent as UNSCBattleRifle } from "./images/Battle Rifle.svg"
import { ReactComponent as UNSCChaingunTurret } from "./images/Chaingun Turret.svg"
import { ReactComponent as UNSCDMRVariant } from "./images/DMR Variant.svg"
import { ReactComponent as UNSCDMR } from "./images/DMR.svg"
import { ReactComponent as UNSCFragGranade } from "./images/Frag Granade.svg"
import { ReactComponent as UNSCGaussTurret } from "./images/Gauss Turret.svg"
import { ReactComponent as UNSCGrenadeLauncher } from "./images/Grenade Launcher.svg"
import { ReactComponent as UNSCHydraLauncher } from "./images/Hydra Launcher.svg"
import { ReactComponent as UNSCMagnumHaloCE } from "./images/Magnum Halo CE.svg"
import { ReactComponent as UNSCMagnum } from "./images/Magnum.svg"
import { ReactComponent as UNSCRailgun } from "./images/Railgun.svg"
import { ReactComponent as UNSCRocketLauncherSPNKr } from "./images/Rocket Launcher SPNKr.svg"
import { ReactComponent as UNSCRocketLauncher } from "./images/Rocket Launcher.svg"
import { ReactComponent as UNSCRocketTurret } from "./images/Rocket Turret.svg"
import { ReactComponent as UNSCSAW } from "./images/SAW.svg"
import { ReactComponent as UNSCShotgunVariant } from "./images/Shotgun Variant.svg"
import { ReactComponent as UNSCShotgun } from "./images/Shotgun.svg"
import { ReactComponent as UNSCSMGVariant } from "./images/SMG Variant.svg"
import { ReactComponent as UNSCSMG } from "./images/SMG.svg"
import { ReactComponent as UNSCSniperRifleVariant } from "./images/Sniper Rifle Variant.svg"
import { ReactComponent as UNSCSniperRifle } from "./images/Sniper Rifle.svg"
import { ReactComponent as UNSCSpartanLaser } from "./images/Spartan Laser.svg"


export const gunsImportsMap = {
  "Brute Gravity Hammer": BruteGravityHammer,
  "Brute Plasma Rifle Variant": BrutePlasmaRifleVariant,
  "Brute Plasma Rifle": BrutePlasmaRifle,
  "Civilian Golf 1": CivilianGolf1,
  "Covenant Beam Rifle Variant": CovenantBeamRifleVariant,
  "Covenant Beam Rifle": CovenantBeamRifle,
  "Covenant Carbine Variant": CovenantCarbineVariant,
  "Covenant Carbine": CovenantCarbine,
  "Covenant Energy Sword": CovenantEnergySword,
  "Covenant Hunter Arm": CovenantHunterArm,
  "Covenant Needler variant": CovenantNeedlervariant,
  "Covenant Needler": CovenantNeedler,
  "Covenant Plasma Caster": CovenantPlasmaCaster,
  "Covenant Plasma Grenade": CovenantPlasmaGrenade,
  "Covenant Plasma Pistol variant": CovenantPlasmaPistolvariant,
  "Covenant Plasma Pistol": CovenantPlasmaPistol,
  "Covenant Storm Rifle": CovenantStormRifle,
  "Forerunner Binary Rifle": ForerunnerBinaryRifle,
  "Forerunner Boltshot": ForerunnerBoltshot,
  "Forerunner Light Rifle": ForerunnerLightRifle,
  "Forerunner Scattershot": ForerunnerScattershot,
  "Forerunner Sentinel Beam": ForerunnerSentinelBeam,
  "Forerunner Splinter Grenade": ForerunnerSplinterGrenade,
  "Forerunner Splinter Turret": ForerunnerSplinterTurret,
  "Forerunner Suppressor": ForerunnerSuppressor,
  "Forerunner incineration cannon": Forerunnerincinerationcannon,
  "UNSC Assault Rifle Variant": UNSCAssaultRifleVariant,
  "UNSC Assault Rifle": UNSCAssaultRifle,
  "UNSC Battle Rifle Halo2": UNSCBattleRifleHalo2,
  "UNSC Battle Rifle Variant": UNSCBattleRifleVariant,
  "UNSC Battle Rifle": UNSCBattleRifle,
  "UNSC Chaingun Turret": UNSCChaingunTurret,
  "UNSC DMR Variant": UNSCDMRVariant,
  "UNSC DMR": UNSCDMR,
  "UNSC Frag Granade": UNSCFragGranade,
  "UNSC Gauss Turret": UNSCGaussTurret,
  "UNSC Grenade Launcher": UNSCGrenadeLauncher,
  "UNSC Hydra Launcher": UNSCHydraLauncher,
  "UNSC Magnum Halo CE": UNSCMagnumHaloCE,
  "UNSC Magnum": UNSCMagnum,
  "UNSC Railgun": UNSCRailgun,
  "UNSC Rocket Launcher SPNKr": UNSCRocketLauncherSPNKr,
  "UNSC Rocket Launcher": UNSCRocketLauncher,
  "UNSC Rocket Turret": UNSCRocketTurret,
  "UNSC SAW": UNSCSAW,
  "UNSC SMG Variant": UNSCSMGVariant,
  "UNSC SMG": UNSCSMG,
  "UNSC Shotgun Variant": UNSCShotgunVariant,
  "UNSC Shotgun": UNSCShotgun,
  "UNSC Sniper Rifle Variant": UNSCSniperRifleVariant,
  "UNSC Sniper Rifle": UNSCSniperRifle,
  "UNSC Spartan Laser": UNSCSpartanLaser,
}


const GunImage = ({
  name,
  ...otherProps
}) => {
  
  const GunSVGComponent = useMemo(_ =>
    _get(gunsImportsMap, name)
  , [name])

  return (
    <GunSVGComponent {...otherProps} />
  )
}


export default GunImage
