import axios from "axios"
import { useMutation } from "react-query"

const useSetNewPassword = () => {
  const { mutate, isLoading } = useMutation((variables) =>
    axios.post("https://staging.forgehub.com/api2/login/restore", variables).then((res) => res.data),
  )

  return {
    setNewPassword: mutate,
    isLoading,
  }
}

export default useSetNewPassword
