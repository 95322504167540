
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as MoveIcon } from "../fontello/icons/svg/ic_move.svg"


const Move = ({ ...props }) => (
  <SvgIcon
    component={MoveIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Move
