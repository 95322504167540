
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as CalendarIcon } from "../fontello/icons/svg/ic_calendar.svg"


const Calendar = ({ ...props }) => (
  <SvgIcon
    component={CalendarIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Calendar
