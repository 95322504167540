import { Box, makeStyles, Typography } from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const useStyles = makeStyles({
  root: {
    fontSize: 12,
    display: "flex",
    cursor: "pointer",
    transition: ".3s ease all",
    alignItems: "center",
    "&:hover": {
      opacity: 0.7,
    },
  },
})

const BackButton = ({ onBack }) => {
  const classes = useStyles()
  return (
    <Box
      onClick={onBack}
      className={classes.root}
    >
      <ArrowBackIosIcon fontSize="inherit" />
      <Typography color="textPrimary">Back</Typography>
    </Box>
  )
}

export default BackButton
