import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"


const CarouselButtons = ({ onPrev, onNext, currentValue, maxValue }) => (
  <Box
    display="flex"
    alignItems="center"
  >
    <Box paddingRight={1}>
      <IconButton
        size="small"
        onClick={onPrev}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={onNext}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
    <Box>
      <Typography
        variant="body2"
        color="textPrimary"
        display="inline"
      >
        {currentValue}{" "}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        display="inline"
      >
        / {maxValue}
      </Typography>
    </Box>
  </Box>
)


export default CarouselButtons
