import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"

const useStyles = makeStyles(theme => ({
  img: {
    backgroundColor: "transparent",
    height: theme.spacing(5),
    filter: "invert(1)",

  },
}))


const PowerCard = ({ src, name, details }) => {
  const classes = useStyles()
  return (
    <Box>
      <img
        className={classes.img}
        src={src}
        alt="power"
      />
      <Typography
        variant="body2"
        color="textPrimary"
      >
        {name}
      </Typography>
      <Typography variant="body">{details}</Typography>
    </Box>
  )
}

export default PowerCard
