import React from "react"
import SnackbarMUI from "@material-ui/core/Snackbar"

const Snackbar = ({
  open,
  onClose,
  autoHideDuration = 6000,
  children,
  ...props
}) => (
  <SnackbarMUI
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    {...props}
  >
    {children}
  </SnackbarMUI>
)


export default Snackbar


