import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"


const Styles = withStyles(_ => ({}))


const CommentCard = ({ author, comment, time }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    paddingX={1}
    height="100%"
  >
    <Box>
      <Box paddingLeft={2.125}>
        <Typography
          variant="body2bold"
          color="secondary"
        >
          {author}
        </Typography>
      </Box>
      <Box display="flex">
        <Divider
          orientation="vertical"
          flexItem
        />
        <Box
          component={Typography}
          variant="body2"
          color="textPrimary"
          paddingLeft={2}
        >
          {comment}
        </Box>
      </Box>
    </Box>
    <Box
      display="flex"
      justifyContent="space-between"
      paddingTop={3}
    >
      <Typography variant="caption">{time}</Typography>
    </Box>
  </Box>
)

export default Styles(CommentCard)
