import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as ShareIcon } from "../fontello/icons/svg/ic_share.svg"


const Share = ({ ...props }) => (
  <SvgIcon
    component={ShareIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Share
