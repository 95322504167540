import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  tab: {
    borderBottom: "2px solid transparent",
    transition: "0.3s ease all",
    display: "flex",
    padding: theme.spacing(1.5, 2.5),
  },
  active: {
    borderColor: "#35bde4",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
}))

const Tab = ({ isActive, to, name, Icon }) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.link}
      to={to}
    >
      <Box
        className={clsx({
          [classes.tab]: true,
          [classes.active]: isActive,
        })}
      >
        {Icon && (
          <Box mr={1}>
            <Icon color={isActive ? "inherit" : "secondary"} />
          </Box>
        )}
        <Typography variant="body2bold">
          { name }
        </Typography>
      </Box>
    </Link>
  )
}

export default Tab
