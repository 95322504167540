
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as DropdownIcon } from "../fontello/icons/svg/ic_dropdown.svg"


const Dropdown = ({ ...props }) => (
  <SvgIcon
    component={DropdownIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Dropdown
