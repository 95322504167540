import { useDebounce } from "rooks"
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import { alpha } from "@material-ui/core/styles/colorManipulator"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { HeaderTitleSlot,
  HeaderControlsSlot } from "./slots"


const useStyles = makeStyles((theme) => ({
  root: {
    position: "static",
    transition: theme.transitions.create(
      ["width", "margin, background-color", "box-shadow", "backdrop-filter"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      },
    ),
    backgroundColor: "transparent",
    boxShadow: theme.shadows[0],

    "&$opaque":{
      zIndex: theme.zIndex.appBar,
      position: "sticky",
      boxShadow: theme.shadows[4],
    },
  },
  subheader: {},
  opaque: {
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: "blur(20px)",

    "& $subheader": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
  },
  toolbarControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    width: "100%",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  headerButton: {
    minWidth: theme.spacing(2),
    fontSize: `${theme.spacing(1.5)}px`,
    marginLeft: theme.spacing(2),
  },
}))

const Header = () => {
  const classes = useStyles()
  const [isOpaque, setOpaque] = useState(false)
  const debouncedSetOpaque = useDebounce(setOpaque, 300)

  useEffect(() => {
    const layout = document.getElementById("layout")

    if (layout) {
      layout.addEventListener("scroll", () => {
        debouncedSetOpaque(layout.scrollTop > 110)
      })
    }
  }, [debouncedSetOpaque])

  return (
    <AppBar
      classes={{
        root: clsx(
          classes.root,
          isOpaque && classes.opaque,
        ),
      }}
    >

      <Toolbar className={classes.toolbar}>
        <HeaderTitleSlot />

        <Box className={classes.toolbarControls}>
          <HeaderControlsSlot />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
export * from "./slots"
