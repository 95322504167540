
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as PortfolioIcon } from "../fontello/icons/svg/ic_portfolio.svg"


const Portfolio = ({ ...props }) => (
  <SvgIcon
    component={PortfolioIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Portfolio
