import { useState } from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { Filter } from "@material-ui/icons"
import MapImage1 from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import Autocomplete from "Components/Autocomplete"
import CreatorCardsGrid from "Components/CreatorCardsGrid"
import InfoBox from "Components/InfoBox"
import MapCardsGrid from "Components/MapCardsGrid"
import MapFilters from "Components/MapFilters"
import MapSortDrawer from "Components/MapSortDrawer"
import MapsSubheaderTabs from "Components/MapsSubheaderTabs"
import { ROUTES } from "Scenes/constants"
import { Redirect, Route, Switch } from "react-router-dom"
import { BackgroundPlug } from "Components/MainLayout"
import { Core, Infection } from "Theme/icons"
import Typography from "Theme/Typography"
import SingleMap from "./SingleMap"

const { MAPS, INDEX } = ROUTES

const maps = [
  {
    id: "map1",
    title: "Map1",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Strongholds",
    icon: Core,
    comments: 766,
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map2",
    title: "Map2",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/2`,
  },
  {
    id: "map3",
    title: "Map3",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/3`,
  },
  {
    id: "map4",
    title: "Map4",
    image: MapImage1,
    mapSize: "4v4",
    rating: "4.9",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/4`,
  },
  {
    id: "map5",
    title: "Map5",
    image: MapImage2,
    mapSize: "5v5",
    rating: "2.1",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/5`,
  },
  {
    id: "map6",
    title: "Map6",
    image: MapImage3,
    mapSize: "6v10",
    rating: "3.3",
    downloads: 756,
    likes: "15k",
    gameType: "Assualt",
    icon: Infection,
    comments: 766,

    path: `${INDEX}${MAPS}/6`,
  },
]

const creatorMaps = [
  {
    id: "map1",
    title: "Map1",
    description: "Description1",
    image: MapImage1,
    mapSize: "4x4",
    rating: "4.9",
  },
  {
    id: "map2",
    title: "Map2",
    description: "Description2",
    image: MapImage2,
    mapSize: "5x5",
    rating: "2.1",
  },
  {
    id: "map3",
    title: "Map3",
    description: "Description3",
    image: MapImage3,
    mapSize: "6x10",
    rating: "3.3",
  },
]

const creators = [
  {
    id: "1",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,
    name: "Petr Pertovich",
    followersCount: 1213,
    mapsCount: 22,
    prefabsCount: 99,
    collectionsCount: 45,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
  {
    id: "2",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,

    name: "Ivan Ivanovich",
    followersCount: 21,
    mapsCount: 223,
    prefabsCount: 9,
    collectionsCount: 5,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
  {
    id: "3",
    avatar: `${process.env.PUBLIC_URL}/Oval.png`,
    name: "Petr Pertovich",
    followersCount: 145,
    mapsCount: 22,
    prefabsCount: 99,
    collectionsCount: 45,
    awardsCount: 22,
    experience: "1 Yrs",
    maps: creatorMaps,
  },
]

const sortTypes = [
  {
    label: "Highest Rated",
    value: "highest-rated",
  },
  {
    label: "Most Viewed",
    value: "most-viewed",
  },
  {
    label: "Most Liked",
    value: "most-liked",
  },
  {
    label: "Most Downloaded",
    value: "most-downloaded",
  },
]


const MapsScene = ({ path }) => {
  const [isSortDrawerOpen, setSortDrawerOpen] = useState(false)

  return (
    <Route path={path}>
      <Switch>
        <SingleMap path={`${path}/:id`} />

        <Route path={path}>
          <BackgroundPlug>
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              height="134px"
              bgcolor="background.paper"
            />
          </BackgroundPlug>

          <Box mb={4}>
            <MapsSubheaderTabs path={path} />
          </Box>

          <Container>
            <MapSortDrawer
              open={isSortDrawerOpen}
              onClose={() => setSortDrawerOpen(false)}
            />

            <MapCardsGrid />

            <Box
              display="flex"
              justifyContent="space-between"
              mx={2}
            >
              <MapFilters />
              <Box
                display="flex"
                alignItems="center"
              >
                <Box width={150}>
                  <Autocomplete
                    placeholder="Sort By..."
                    options={sortTypes}
                  />
                </Box>
                <Box pl={2}>
                  <IconButton
                    size="small"
                    onClick={() => setSortDrawerOpen(true)}
                  >
                    <Filter />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box
              paddingY={1}
              mx={2}
            >
              <InfoBox
                label="Sort By:"
                value="Views"
              />
            </Box>
            <Grid container>
              <MapCardsGrid maps={maps} />
            </Grid>
            <Box
              pt={5}
              pl={2}
            >
              <Box pb={3}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                >
                  Highlighted Creators
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
              >
                <CreatorCardsGrid creators={creators} />
              </Grid>
            </Box>
          </Container>
        </Route>
        <Redirect
          exact
          to={`${path}/${MAPS}`}
        />
      </Switch>
    </Route>
  )
}

export default MapsScene
