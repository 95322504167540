import Box from "@material-ui/core/Box"

export default function PlateContainer({
  min = "250px",
  max = "1fr",
  gap = 24,
  rowHeight = "inherit",
  scroll = false,
  children,
  ...props
}) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(auto-fit, minmax(${min}, ${max}))`}
      gridGap={gap}
      gridAutoFlow={scroll ? "column" : "dense"}
      gridAutoColumns={`minmax(${min},${max})`}
      gridTemplateRows={rowHeight}
      {...props}
    >
      {children}
    </Box>
  )
}
