import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/styles/withStyles"

const Styles = withStyles((theme) => ({
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}))

const ActivityCard = ({ classes, title, icon: Icon, children }) => (
  <Box
    display="flex"
    flexDirection="column"
    borderRadius="borderRadius"
    bgcolor="background.BG3"
    padding={2}
    height={256}
  >
    <Box
      display="flex"
      alignItems="center"
      paddingBottom={1}
    >
      <Icon className={classes.icon} />
      <Typography variant="body2">{title}</Typography>
    </Box>
    {children}
  </Box>
)

export default Styles(ActivityCard)
