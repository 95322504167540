import { ROUTES } from "Scenes/constants"
import { Redirect, Route, Switch } from "react-router-dom"
import SignUpDrawer from "Components/SignUpDrawer"
import SignUpForm from "Components/SignUpForm"
import { DrawerPlug } from "Components/MainLayout"

const { LOGIN, WELCOME } = ROUTES

const AuthScene = ({ path }) => (
  <Route path={path}>
    <DrawerPlug>
      <SignUpDrawer />
    </DrawerPlug>

    <Switch>
      <Route
        exact
        path={`${path}/${LOGIN}`}
      >
        <SignUpForm />
      </Route>

      <Route path={`${path}/${LOGIN}/${WELCOME}`}>
        <></>
      </Route>

      <Redirect
        exact
        to={`${path}/${LOGIN}`}
      />
    </Switch>
  </Route>
)


export default AuthScene
