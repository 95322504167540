import React, { useState, useCallback, useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import Typography from "Theme/Typography"
import Dialogue from "Theme/Dialogue"
import Box from "@material-ui/core/Box"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "Theme/Button"
import Checkbox from "Theme/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "Theme/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"
import SocialLogin from "Components/SocialLogin"
import {
  Email as EmailIcon,
  Username as UsernameIcon,
  Password as PasswordIcon,
  SignUp as SignUpIcon,
  Check as CheckIcon,
} from "Theme/icons"
import { ReactComponent as DialogueImage } from "./images/dialogue-image.svg"
import { Link } from "react-router-dom"
import { ROUTES } from "Scenes/constants"
import useRegister from "./hooks"

const { LOGIN } = ROUTES

const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: "450px",
    width: "30%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(3),

    "& > *": {
      marginTop: theme.spacing(5),
    },
  },
  formLabel: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    gap: theme.spacing(1.5),
  },
  signUpIcon: {
    overflow: "visible",
    width: theme.spacing(2.5),
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  textField: {
    "& input::placeholder": {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
    "& svg": {
      color: theme.palette.text.tertiary,
    },
  },
  loginLink: {
    ...theme.typography.button,
    textDecoration: "none",
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    textTransform: "none",
  },
  adornmentIcon: {
    overflow: "visible",
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  submitControl: {
    color: theme.palette.secondary.contrastText,
  },
  control: {
    "& span > :first-child": {
      color: theme.palette.secondary.light,
      marginRight: theme.spacing(1.5),
    },
  },
  privacyControl: {
    marginTop: theme.spacing(3),
  },
}))

const schema = Yup.object({
  login: Yup.string().required("Please enter your username"),
  email: Yup.string().email("Invalid email format").required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
  privacy: Yup.boolean().required("Please confirm that you read Prvacy Policy"),
})

const GMAIL_URL = "https://gmail.com"

const SignUpForm = () => {
  const classes = useStyles()
  const [isDialogueOpen, setIsDialogueOpen] = useState(false)
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: "",
      email: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { register, isLoading } = useRegister()

  const fields = useMemo(
    () => [
      { fieldName: "email", placeholder: "Email", Icon: EmailIcon, type: "email" },
      { fieldName: "login", placeholder: "Username", Icon: UsernameIcon },
      {
        fieldName: "password",
        placeholder: "Password",
        Icon: PasswordIcon,
        type: "password",
      },
    ],
    [],
  )

  const onSubmit = useCallback(() => {
    const data = getValues()
    register(data, {
      onSuccess: () => setIsDialogueOpen(true),
    })
  }, [register, getValues])

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          variant="h4"
          className={classes.formLabel}
        >
          <SignUpIcon
            className={classes.signUpIcon}
            color="secondary"
          />
          <span>Sign Up</span>
        </Typography>

        <SocialLogin />

        <Box className={classes.inputGroup}>
          {fields.map(({ fieldName, placeholder, Icon, type }) => (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors[fieldName]}
                  placeholder={placeholder}
                  variant="outlined"
                  type={type || "text"}
                  helperText={
                    errors[fieldName]?.message ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: "none" }}
                      >
                        {errors[fieldName].message}
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          className={classes.adornmentIcon}
                          {...(fieldName === "password" ? { style: { height: "10px" }, fontSize: "small" } : {})}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          ))}
        </Box>
        <FormControl className={classes.privacyControl}>
          <Controller
            name="privacy"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Checkbox />}
                label={
                  <Typography
                    variant="body2"
                    color={errors.privacy ? "error" : "tertiary"}
                  >
                    I agree to the <strong>Terms&#38;Conditions</strong> and <strong>ForgeHub Privacy</strong>
                  </Typography>
                }
              />
            )}
          />
        </FormControl>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            variant="body2"
            style={{
              marginRight: "17px",
              color: "#CBD1E2",
            }}
          >
            <span>Have an account?</span>
            <Link
              className={classes.loginLink}
              to={{
                hash: "#login",
              }}
              onClick={() => {
                window.location.hash = LOGIN
              }}
            >
              Login
            </Link>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            type="submit"
          >
            <Typography
              variant="inherit"
              className={classes.submitControl}
            >
              Sign Up
            </Typography>
            <CheckIcon
              className={classes.signUpIcon}
              color="textPrimary"
            />
          </Button>
        </Box>
      </form>
      <Dialogue
        image={<DialogueImage />}
        title="Verify your Account"
        description="Thanks for Joining ForgeHub. We just sent a verification link to your email"
        open={isDialogueOpen}
        onClose={() => setIsDialogueOpen(false)}
        button={
          <Button
            variant="outlined"
            color="secondary"
            className={classes.control}
            onClick={() => {
              window.location.href = GMAIL_URL
            }}
          >
            <Typography variant="h6">
              Go to your <strong>gmail.com</strong> inbox
            </Typography>
            <EmailIcon />
          </Button>
        }
      />
      <Backdrop
        style={{ zIndex: 2 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default SignUpForm
