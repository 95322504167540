
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as FavoriteIcon } from "../fontello/icons/svg/ic_favorite.svg"


const Favorite = ({ ...props }) => (
  <SvgIcon
    component={FavoriteIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Favorite
