import React, { useCallback, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import { Close, SignUp, Login } from "Theme/icons"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"
import Typography from "Theme/Typography"
import Button from "Theme/Button"
import SocialLogin from "Components/SocialLogin"
import { ROUTES } from "Scenes/constants"
import BackgroundImage from "./images/login-background.jpg"
import useLogin from "./hooks"

const { AUTH, LOGIN } = ROUTES

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    cursor: "default",
    height: "100%",
    borderRadius: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
  },
  content: {
    width: "50%",
  },
  closeIcon: {
    width: "16px",
    height: "16px",
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(5.5),
    right: theme.spacing(5.5),
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    gap: theme.spacing(4),
  },
  loginIcon: {
    color: theme.palette.text.primary,
    overflow: "visible",
    width: theme.spacing(2),
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  textField: {
    "& input::placeholder": {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  link: {
    ...theme.typography.button,
    textDecoration: "none",
    color: theme.palette.secondary.main,
    textTransform: "none",
  },
  adornmentIcon: {
    overflow: "visible",
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  control: {
    color: theme.palette.text.primary,
  },
  signUpControl: {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    bottom: theme.spacing(3),
  },
}))

const schema = Yup.object({
  login: Yup.string().required("Please enter your username"),
  password: Yup.string().required("Please enter your password"),
})

const LoginForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { login, isLoading } = useLogin()

  const fields = useMemo(
    () => [
      { fieldName: "login", placeholder: "Email or Username" },
      {
        fieldName: "password",
        placeholder: "Password",
        type: "password",
      },
    ],
    [],
  )

  const onSubmit = useCallback(() => {
    const data = getValues()
    login(data, {
      onSuccess: () => {
        window.location.hash = ""
        window.location.search = ""
        window.location.pathname = "/home"
      },
    })
  }, [login, getValues])

  return (
    <Dialog
      maxWidth={false}
      open
    >
      <DialogContent className={classes.root}>
        <Close
          onClick={() => {
            history.push("")
          }}
          className={classes.closeIcon}
        />

        <Box
          position="relative"
          width={400}
          height={400}
        >
          <Box
            position="relative"
            className={classes.background}
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.signUpControl}
            onClick={() => {
              window.location.hash = ""
              window.location.pathname = `/${AUTH}/${LOGIN}`
            }}
          >
            <Typography
              variant="inherit"
              className={classes.control}
            >
              Create New Account
            </Typography>
            <SignUp className={classes.loginIcon} />
          </Button>
        </Box>
        <Box className={classes.content}>
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography
              variant="h4"
              color="textPrimary"
            >
              Sign in
            </Typography>

            <SocialLogin />

            <Box className={classes.inputGroup}>
              {fields.map(({ fieldName, placeholder, type }) => (
                <Controller
                  key={fieldName}
                  name={fieldName}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors[fieldName]}
                      placeholder={placeholder}
                      variant="outlined"
                      type={type || "text"}
                      helperText={
                        errors[fieldName]?.message ? (
                          <Typography
                            variant="body2bold"
                            color="error"
                            style={{ textTransform: "none" }}
                          >
                            {errors[fieldName].message}
                          </Typography>
                        ) : null
                      }
                      InputProps={{
                        className: classes.textField,                       
                      }}
                    />
                  )}
                />
              ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
              marginTop="40px"
            >
              <Typography variant="body2">
                <Link
                  className={classes.link}
                  to="reset-password"
                >
                  Forgot password?
                </Link>
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
              >
                <Typography
                  variant="inherit"
                  className={classes.control}
                >
                  Login
                </Typography>
                <Login className={classes.loginIcon} />
              </Button>
            </Box>
          </form>
        </Box>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export { LoginForm }
