import Box from "@material-ui/core/Box"
import { Route } from "react-router"

export default function Information({ path }) {
  return (
    <Route path={`${path}`}>
      <Box>Information</Box>
    </Route>
  )
}
