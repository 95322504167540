import React from "react"
import Typography from "Theme/Typography"
import Box from "@material-ui/core/Box"
import { withStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const Styles = withStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: theme.spacing(100),
    border: "1px solid rgba(146, 152, 170, 0.12)",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    width: "fit-content",
  },
  button: {
    transition: "all 0.2s ease 0s",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    fontSize: theme.spacing(2),
    color: "#24242D",
    "&:hover": {
      color: "#A1A3A5",
    },
    transition: "all 0.2s ease 0s",
  },
}))

const InfoBox = ({ classes, label, value }) => (
  <Box className={classes.root}>
    <Typography>
      {label}
    </Typography>
    <Box
      pl={1}
      pr={0.3}
    >
      <Typography>
        {value}
      </Typography>
    </Box>
    <IconButton
      size="small"
      className={classes.button}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  </Box>
)


export default Styles(InfoBox)
