import * as React from "react"
import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import Typography from "Theme/Typography"
import Divider from "@material-ui/core/Divider"
import Button from "Theme/Button"
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core"
import Autocomplete from "Components/Autocomplete"


const sortTypes = [
  {
    label: "Highest Rated",
    value: "highest-rated",
  },
  {
    label: "Most Viewed",
    value: "most-viewed",
  },
  {
    label: "Most Liked",
    value: "most-liked",
  },
  {
    label: "Most Downloaded",
    value: "most-downloaded",
  },
]

const Styles = withStyles((theme) => ({
  applyButton: {
    padding: theme.spacing(0.75),
    minWidth: theme.spacing(2),
    fontSize: `${theme.spacing(0.5)}px`,
  },
}))

const MapSortDrawer = ({ classes, open, onClose }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding={3}
        width={300}
      >
        <Box pb={3}>
          <Box pb={2}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
            >
              Sort maps
            </Typography>
          </Box>
          <Autocomplete
            placeholder="Sort By..."
            options={sortTypes}
          />
        </Box>

        <Box pb={2}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
          >
            Filter maps
          </Typography>
        </Box>

        <Box pb={1}>
          <Typography variant="body1">Game</Typography>
          <Autocomplete options={sortTypes} />
        </Box>

        <Box pb={1}>
          <Typography variant="body1">Game type</Typography>
          <Autocomplete options={sortTypes} />
        </Box>

        <Box pb={1}>
          <Typography variant="body1">Canvas</Typography>
          <Autocomplete options={sortTypes} />
        </Box>

        <Box pb={1}>
          <Typography variant="body1">Forger</Typography>
          <Autocomplete options={sortTypes} />
        </Box>

        <Box>
          <FormControlLabel
            control={
              <Checkbox color="#62CCE9" />
            }
            label="Featured Only"
          />
        </Box>

        <Box py={2}>
          <Divider />
        </Box>

        <Button
          variant="contained"
          classes={classes.applyButton}
        >
          Apply filters
        </Button>
      </Box>
    </Drawer>
  )

export default Styles(MapSortDrawer)
