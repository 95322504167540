import React from "react"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/styles/makeStyles"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    display: "inline-block",

    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      height: "1px",
      width: "51%",
      transform: "skew(0deg, -20deg)",
    },

    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "-2px",
      right: 0,
      height: "1px",
      width: "50%",
      transform: "skew(0deg, 20deg)",
    },

  },
  body: {
    position: "relative",
    padding: theme.spacing(1, 0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  icon: {},
  yellow: {
    borderTopColor: "#FF9D00",

    "&:before, &:after": {
      background: "#FFD85C",
    },

    "& $icon": {
      color: "#FFFBD7",
      filter: `drop-shadow(0px 0px 2px rgba(255, 158, 1, 0.65))
        drop-shadow(0px 0px 12px #FF9E01)
        drop-shadow(0px 0px 8px #FF9E01)
        drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))`,
    },
  },
  green: {
    borderTopColor: "#3CC48E",

    "&:before, &:after": {
      background: "#B6ED7C",
    },

    "& $icon": {
      color: "#DAFFF0",
      filter: `drop-shadow(0px 0px 8px #3EC48D)
        drop-shadow(0px 0px 4px #429A8B)
        drop-shadow(0px 0px 12px #46C288)
        drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))`,
    },
  },
  red: {
    borderTopColor: "#EF3E88",

    "&:before, &:after": {
      background: "#EF7743",
    },

    "& $icon": {
      color: "#FFE1ED",
      filter: `drop-shadow(0px 0px 8px #F2497B)
        drop-shadow(0px 0px 12px #FF4989)
        drop-shadow(0px 0px 12px #F04088)
        drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08))`,
    },
  },
}))

const AwardBadge = ({
  color,
  icon,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.root, classes[color])}
      {...otherProps}
    >
      <Box className={classes.body}>
        {
          React.cloneElement(
            icon,
            {
              className: clsx(icon.props.className, classes.icon),
            },
          )
        }
      </Box>
    </Box>
  )
}


export default AwardBadge
