import { makeStyles } from "@material-ui/core"
import Button from "Theme/Button"
import clsx from "clsx"
import { memo } from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.main,
    marginRight: theme.spacing(1),
    color: theme.palette.white.main,
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "'Avenir', sans-serif",
    transition: "0.3s ease all",
    border: "2px solid transparent",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4D515D",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  selected: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  unselected: {
    opacity: "0.5",
  },
}))

 const RadioPicker = ({ value, selected, unselected, onClick }) => {
 const classes = useStyles()
 return (
   <Button
     className={clsx(classes.root, {
       [classes.selected]: selected ,
       [classes.unselected]: unselected ,
     })}
     onClick={onClick}
   >
     {value}
   </Button>
 )
}

export default memo(RadioPicker)
