import {
  Box,
  Chip,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import FavoriteIcon from "@material-ui/icons/Favorite"
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"
import StarIcon from "@material-ui/icons/Star"
import Button from "Theme/Button"


const useStyles = makeStyles((theme) => ({
  bookmarkButton: {
    color: theme.palette.text.secondary,
    backgroundColor: "rgb(255 255 255 / 8%)",
    transition: "0.3s ease",
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  donateText: {
    marginLeft: theme.spacing(0.5),
    textAlign: "center",
  },
  downloadButton: {
    padding: theme.spacing(0.75),
    minWidth: theme.spacing(2),
    fontSize: `${theme.spacing(1.5)}px`,
    height: "65%",
  },
  downloadButtonChip: {
    fontSize: `${theme.spacing(1.5)}px`,
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "rgba(97,97,97, 0.55)",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  firstLine: {
    color: theme.palette.secondary.main,
    display: "flex",
  },
  secondLineInsight: {
    marginRight: theme.spacing(1),
  },
  scroll: {
    overflowX: "auto",
    overflowY: "hidden",
    paddingBottom: 8,

    "&::-webkit-scrollbar": {
      width: "10px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.primary,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(97,97,97, 0.55)",
      borderRadius: "5px",
    },
  },
  image: {
    borderRadius: 5,
    cursor: "pointer",
  },
}))

const SubHeader = () => {
  const classes = useStyles()

  return (
    <Box
      position={"relative"}
      top={-85}
    >
      <Box
        display={"flex"}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Dusk: Ro Sham Bo
          </Typography>
          <IconButton
            aria-label="bookmark"
            focusRipple
            className={classes.bookmarkButton}
          >
            <BookmarkIcon
              color="inherit"
              fontSize="small"
            />
          </IconButton>
        </Box>
        <Box
          fontSize={18}
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          width={300}
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <TrackChangesIcon fontSize="inherit" />
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.donateText}
            >
              donate
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <FavoriteIcon
              fontSize="inherit"
              color="secondary"
            />
            <Typography
              variant="body2"
              color="textPrimary"
              className={classes.donateText}
            >
              558
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={classes.downloadButton}
          >
            download{" "}
            <Chip
              className={classes.downloadButtonChip}
              size="small"
              label="27.2K"
            />
          </Button>
        </Box>
      </Box>
      <Box
        display={"flex"}
        height={28}
      >
        <Typography
          variant="body1"
          className={classes.firstLine}
        >
          Nano
        </Typography>
        <Divider
          orientation="vertical"
          variant="middle"
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <RadioButtonCheckedIcon
            fontSize="inherit"
            color="secondary"
            className={classes.secondLineInsight}
          />
          <Typography
            variant="body1"
            color="textPrimary"
          >
            4v4
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
        />
        <Box display={"flex"}>
          <Box
            mr={1}
            display="flex"
            alignItems={"center"}
          >
            <StarIcon
              fontSize="inherit"
              color="secondary"
            />
          </Box>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            88
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SubHeader
