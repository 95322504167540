import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"


const Cell = ({ name, description }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      pl={1}
    >
      <Box
        display="inline"
        pr={1}
      >
        <Typography color="textPrimary">{name}</Typography>
      </Box>
      <Typography>{description}</Typography>
    </Box>
  </Box>
)


export default Cell
