import { Box, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import clsx from "clsx"
import Alert from "Components/Alert"
import GameTypeCard from "Components/GameTypeCard"
import Snackbar from "Components/Snackbar"
import { useEffect, useRef, useState } from "react"
import { Plus, Send } from "Theme/icons"


const useStyles = makeStyles((theme) => ({
  addTypeRoot: {
    backgroundColor: "unset",
    border: `2px solid ${theme.palette.grey.main}`,
    "&:hover": {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      "&>.addTypeInfoContainer_info": {
        backgroundColor: theme.palette.secondary.main,
        color: "#ffffff",
      },
    },
  },
  rootActive: {
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  addTypeInfoContainer: {
    backgroundColor: "unset",
  },
  addTypeInfoContainerActive: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
  },
  input: {
    transition: "0.2s ease opacity",
    position: "absolute",
    opacity: 0,
    zIndex: -1,
    bottom: 0,
    transform: "translateY(50%)",
    width: "150%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
    },
  },
  inputError: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F44336",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F44336",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F44336",
    },
  },
  inputNotchedOutline: {
    borderColor: theme.palette.secondary.main,
    fontSize: 14,
    zIndex: 1,
    backgroundColor: "#2C2E34",
  },
  inputFieldRoot: {
    paddingRight: 0,
  },
  sendButton: {
    zIndex: 2,
    fontSize: 22,
    padding: theme.spacing(0.6),
    paddingRight: theme.spacing(0.4),
    marginRight: theme.spacing(0.3),
    borderRadius: theme.spacing(0.5),
  },
  inputFieldInput: {
    zIndex: 2,
    fontSize: 14,
  },
  sendButtonActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  inputActive: {
    zIndex: theme.zIndex.modal + 1,
    opacity: 1,
  },
  modalBox: {
    zIndex: -1,
    position: "fixed",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
  },
  modalBoxActive: {
    zIndex: theme.zIndex.modal,
  },
}))

const hasDuplicate = (customGameType, gameTypes) =>
  gameTypes.some(
    (gameType) =>
      gameType.label.toLowerCase() === customGameType.trim().toLowerCase(),
  )

const gameTypeNameRegExp = new RegExp("^[A-Za-z0-9-.]*$")

const GameTypeAddTypeCard = ({
  gameTypes,
  addCustomGameType,
  setCustomGameTypeInputValue,
}) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState()
  const [gameTypeValue, setGameTypeValue] = useState("")
  const [isValidGameType, setIsValidGameType] = useState(true)
  const inputRef = useRef()

  const handleClick = () => {
    if (!modalOpen) {
      setModalOpen(true)
    }
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleGameTypeChange = (e) => {
    if (!isValidGameType) {
      setIsValidGameType(true)
    }
    setGameTypeValue(e.target.value)
  }

  const handleSubmit = () => {
    const validLength = gameTypeValue.length > 2
    const validName = gameTypeNameRegExp.test(gameTypeValue)
    const uniqueName = !hasDuplicate(gameTypeValue, gameTypes)

    if (!(validLength && validName && uniqueName)) {
      setIsValidGameType(false)
      inputRef.current.focus()
      setCustomGameTypeInputValue(gameTypeValue)

      if (!validLength) {
        showAlert("Gametype name length must be more than 2 characters")
      } else if (!validName) {
        showAlert(
          "Sorry, only letters (a-z) numbers (0-9), hyphens (-) and periods (.) are allowed",
        )
      } else if (!uniqueName) {
        showAlert("Already exist")
      }
    } else {
      addCustomGameType(gameTypeValue)
      setModalOpen(false)
      setGameTypeValue("")
    }
  }

  const showAlert = (message) => {
    setAlertOpen(true)
    setAlertMessage(message)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  useEffect(() => {
    if (modalOpen) {
      inputRef.current.focus()
    }
  }, [modalOpen])

  return (
    <GameTypeCard
      label="Add more"
      icon={Plus}
      onClick={handleClick}
      rootClassName={clsx(classes.addTypeRoot, {
        [classes.rootActive]: modalOpen,
      })}
      iconContainerClassName={clsx(
        classes.addTypeInfoContainer,
        {
          [classes.addTypeInfoContainerActive]: modalOpen,
        },
        "addTypeInfoContainer_info",
      )}
    >
      <Box
        className={clsx(classes.modalBox, {
          [classes.modalBoxActive]: modalOpen,
        })}
        onClick={handleModalClose}
      />
      <TextField
        value={gameTypeValue}
        onChange={handleGameTypeChange}
        variant="outlined"
        autoFocus
        className={clsx(classes.input, {
          [classes.inputActive]: modalOpen,
          [classes.inputError]: !isValidGameType,
        })}
        placeholder="Enter gametype"
        InputProps={{
          classes: {
            root: classes.inputFieldRoot,
            notchedOutline: classes.inputNotchedOutline,
            input: classes.inputFieldInput,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                onClick={handleSubmit}
                className={clsx(classes.sendButton, classes.sendButtonActive)}
              >
                <Send
                  fontSize="inherit"
                  color="inherit"
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
      />
      <Snackbar
        open={alertOpen}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </GameTypeCard>
  )
}


export default GameTypeAddTypeCard
