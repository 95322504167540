import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import ImageCard from "Components/ImageCard"
import * as R from "ramda"
import { useCallback, useState } from "react"
import Button from "Theme/Button"
import Typography from "Theme/Typography"
import SortableList from "./SortableList"

import LinkForm from "./LinkForm"
import UploadForm from "./UploadForm"
import { createImageItem } from "./utils"

const useStyles = makeStyles((theme) => ({
  imageCardContainer: {
    minWidth: theme.spacing(39),
    height: theme.spacing(24),
  },
  paper: {
    padding: theme.spacing(3, 4),
  },
  list: {
    marginBottom: theme.spacing(4),
  },
}))


function MediaStep({ onNext, setDrawerPreviewData }) {
  const classes = useStyles()
  const [gridMap, setGridMap] = useState([])


  const handleChange = useCallback((files) => {
    const arrFiles = [...files]

    const grid = arrFiles.map((file) => createImageItem({ file }))
    setGridMap((gridMap) => [...gridMap, ...grid])
  }, [])

  const handleNext = () => {
    const [gridItem] = gridMap
    setDrawerPreviewData({
      src: gridItem.image,
    })
    onNext()
  }

  const handleRemoveByIndex = (index) => () =>
    setGridMap((prev) => R.remove(index, 1, prev))

  const onSortEnd = ({ oldIndex, newIndex }) =>
    setGridMap((prev) => R.move(oldIndex, newIndex, prev))

  const [gridItem] = gridMap

  return (
    <Paper className={classes.paper}>
      <Box display="flex">
        <ImageCard
          src={gridItem?.image}
          rootClass={classes.imageCardContainer}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          ml={3}
        >
          <Typography
            variant="body2"
            color="textPrimary"
          >
            Cover Image
          </Typography>
          <Typography color="textSecondary">
            This image will display as the map thumbnail in all listings. 1600 x
            1200px ideal for hi-res.
          </Typography>
        </Box>
      </Box>
      <Box
        mb={2}
        mt={3}
      >
        <Typography
          variant="body2"
          color="textPrimary"
        >
          Map Images
        </Typography>
        <Typography color="textSecondary">
          Drag and drop images to place them in the order you&apos;d like them
          to appear.
        </Typography>
      </Box>
      <SortableList
        items={gridMap}
        onSortEnd={onSortEnd}
        onClick={handleRemoveByIndex}
        axis="xy"
        distance={1}
        className={classes.list}
        uploadComponent={<UploadForm handleChange={handleChange} />}
      />
      <Box mb={2.5}>
        <LinkForm onSubmit={setGridMap} />
      </Box>
      <Box
        display="flex"
        justifyContent="end"
      >
        <Button
          disabled={gridMap.length === 0}
          variant="contained"
          onClick={handleNext}
        >
          Continue
        </Button>
      </Box>
    </Paper>
  )
}

export default MediaStep
