import React, { useCallback, useRef, useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Typography from "Theme/Typography"
import IconButton from "@material-ui/core/IconButton"
import { ROUTES } from "Scenes/constants"
import { Link, useLocation } from "react-router-dom"
import { Box } from "@material-ui/core"
import Button from "Theme/Button"
import GavelIcon from "@material-ui/icons/Gavel"
import { format } from "date-fns"
import {
  ChevronLeft,
  ChevronRight,
  Collection as CollectionIcon,
  Events as EventsIcon,
  Forums as ForumsIcon,
  Home as HomeIcon,
  Map as MapsIcon,
} from "Theme/icons"
import { ReactComponent as Logo } from "Components/MainDrawer/images/logo.svg"
import { ReactComponent as LogoWithText } from "Components/MainDrawer/images/logo-text.svg"
import DrawerListItem from "Components/DrawerListItem"
import RssFeedIcon from "@material-ui/icons/RssFeed"
import ExternalLink from "Components/ExternalLink"
import YouTubeIcon from "@material-ui/icons/YouTube"
import TwitterIcon from "@material-ui/icons/Twitter"

const { INDEX, HOME, EVENTS, FORUMS, MAPS, COLLECTIONS, FORGE_MAP, BLOG, HELP, TERMS, PRIVACY } = ROUTES

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: theme.spacing(30),
  },
  drawerPaper: {
    width: theme.spacing(30),
    justifyContent: "space-between",
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9.25),
  },
  drawerPinButton: {
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  socialLink: {
    color: theme.palette.text.secondary,
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  submitMapButton: {
    height: theme.spacing(5),
    minWidth: "unset",
    width: theme.spacing(6),
    marginLeft: 0,
  },
  submitMapButtonBig: {
    width: theme.spacing(24),
  },
}))

function MainDrawer() {
  const classes = useStyles()
  const location = useLocation()
  const drawerRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [flap, setFlap] = useState(false)

  const handleDrawerToggle = useCallback(
    (event) => {
      if (!flap) {
        setIsOpen((isOpen) => {
          if (event.type === "mouseover" && !isOpen) {
            return !isOpen
          }
          if (event.type === "mouseleave" && isOpen) {
            return !isOpen
          }
          return isOpen
        })
      }
    },
    [setIsOpen, flap],
  )

  const handleFlapToggle = useCallback(() => {
    setIsOpen((_) => true)
    setFlap((flap) => !flap)
  }, [setIsOpen, setFlap])

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, isOpen ? classes.drawerOpen : classes.drawerClose)}
      classes={{
        paper: clsx(classes.drawerPaper, isOpen ? classes.drawerOpen : classes.drawerClose),
      }}
      onMouseOver={handleDrawerToggle}
      onMouseLeave={handleDrawerToggle}
      ref={drawerRef}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX={3}
          paddingTop={3}
          paddingBottom={1}
        >
          <Box
            component={Link}
            display="flex"
            alignItems="center"
            to={`${INDEX}${HOME}`}
          >
            {isOpen ? <LogoWithText /> : <Logo />}
          </Box>
          <IconButton
            onClick={handleFlapToggle}
            className={classes.drawerPinButton}
          >
            {flap ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <List>
          <DrawerListItem
            title="Home"
            to={`${INDEX}${HOME}`}
            icon={HomeIcon}
            selected={location.pathname === `${INDEX}${HOME}`}
            minimized={!isOpen}
          />
          <DrawerListItem
            title="Events"
            to={`${INDEX}${EVENTS}`}
            icon={EventsIcon}
            selected={location.pathname === `${INDEX}${EVENTS}`}
            minimized={!isOpen}
          />
          <DrawerListItem
            title="Forums"
            to={`${INDEX}${FORUMS}`}
            icon={ForumsIcon}
            selected={location.pathname === `${INDEX}${FORUMS}`}
            minimized={!isOpen}
          />
          <Box
            component={DrawerListItem}
            paddingTop={3}
            title="Maps"
            to={`${INDEX}${MAPS}`}
            icon={MapsIcon}
            selected={location.pathname === `${INDEX}${MAPS}`}
            minimized={!isOpen}
          />
          <DrawerListItem
            title="Collections"
            to={`${INDEX}${COLLECTIONS}`}
            icon={CollectionIcon}
            selected={location.pathname === `${INDEX}${COLLECTIONS}`}
            minimized={!isOpen}
          />
        </List>
        <Box
          component={Link}
          to={`${INDEX}${FORGE_MAP}`}
          paddingX={isOpen ? 3 : 1.625}
          paddingY={2}
          className={classes.link}
        >
          <Button
            variant="contained"
            className={clsx(classes.submitMapButton, isOpen && classes.submitMapButtonBig)}
          >
            {isOpen ? "Submit map" : <GavelIcon fontSize="small" />}
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          display={isOpen ? "flex" : "none"}
          flexDirection="column"
          paddingX={3}
          paddingY={1}
        >
          <Box
            component={Typography}
            variant="body2"
            paddingBottom={1}
          >
            <Link
              to={`${INDEX}/${BLOG}`}
              className={classes.link}
            >
              Blog
            </Link>
          </Box>
          <Box
            component={Typography}
            variant="body2"
            paddingBottom={1}
          >
            <Link
              to={`${INDEX}/${HELP}`}
              className={classes.link}
            >
              Help Center
            </Link>
          </Box>
          <Box
            component={Typography}
            variant="body2"
            paddingBottom={1}
          >
            <Link
              to={`${INDEX}/${TERMS}`}
              className={classes.link}
            >
              Terms and Conditions
            </Link>
          </Box>
          <Box
            component={Typography}
            variant="body2"
          >
            <Link
              to={`${INDEX}/${PRIVACY}`}
              className={classes.link}
            >
              Privacy Policy
            </Link>
          </Box>
        </Box>
        <Box
          paddingX={isOpen ? 3 : 1.75}
          paddingBottom={3}
        >
          <Box
            display="flex"
            flexDirection={isOpen ? "row" : "column"}
            paddingTop={1}
            paddingBottom={2}
          >
            <Box
              component={ExternalLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingRight={isOpen ? 2 : "unset"}
              paddingBottom={isOpen ? "unset" : 1}
              to="https://www.forgehub.com/twitter"
              className={classes.socialLink}
            >
              <TwitterIcon fontSize="small" />
            </Box>
            <Box
              component={ExternalLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingRight={isOpen ? 2 : "unset"}
              paddingBottom={isOpen ? "unset" : 1}
              to="https://www.forgehub.com/youtube"
              className={classes.socialLink}
            >
              <YouTubeIcon fontSize="small" />
            </Box>
            <Box
              component={ExternalLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingRight={isOpen ? 2 : "unset"}
              paddingBottom={isOpen ? "unset" : 1}
              to="https://www.forgehub.com/rss"
              className={classes.socialLink}
            >
              <RssFeedIcon fontSize="small" />
            </Box>
          </Box>
          <Box display="flex">
            <Box display={isOpen ? "inline" : "none"}>
              <Typography variant="body2">ForgeHub&nbsp;</Typography>
            </Box>
            <Typography variant="body2">© {format(new Date(), "yyyy")}</Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default MainDrawer
