import React, {
  useMemo } from "react"
import _get from "lodash/get"
import CanvasIcon from "Theme/icons/Canvas"
import CoreIcon from "Theme/icons/Core"
import EliminationIcon from "Theme/icons/Elimination"
import InfectionIcon from "Theme/icons/Infection"
import MinigameIcon from "Theme/icons/Minigame"
import RaceIcon from "Theme/icons/Race"


const gameTypeMap = {
  "all": CanvasIcon,
  "core": CoreIcon,
  "infection": InfectionIcon,
  "elimination": EliminationIcon,
  "mini-game": MinigameIcon,
  // #TODO Add Icons
  "race": RaceIcon,
  "griftball": RaceIcon,
  "aesthetics": RaceIcon,
  "puzzle": RaceIcon,
}


const GameTypeIcon = ({ type, ...otherProps }) => {

  const Icon = useMemo(_ =>
    _get(gameTypeMap, type) || CoreIcon
  , [type])

  return (
    <Icon {...otherProps} />
  )
}


export default GameTypeIcon
