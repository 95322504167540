import Card from "@material-ui/core/Card"
import { withStyles } from "@material-ui/core/styles"


const StyledCard = withStyles(theme => ({
  root: {
    position: "relative",
  },
}))(Card)


export default StyledCard
