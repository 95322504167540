import { Box, IconButton, makeStyles } from "@material-ui/core"
import { YouTube } from "@material-ui/icons"
import clsx from "clsx"
import { Closs } from "Theme/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(14),
    width: theme.spacing(21),
    position: "relative",
    borderRadius: 5,
  },
  image: {
    backgroundImage: ({ src }) => `url(${src})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  iconButton: {
    position: "absolute",
    color: theme.palette.grey.secondary,
    fontSize: 16,
    top: 10,
    right: 10,
    padding: 5,
    background: theme.palette.dark.secondary,
    opacity: 0.65,
  },
  youTube: {
    color: "#FF0000",
    width: "fit-content",
    position: "relative",
    margin: theme.spacing(1, 0.5),
  },
  fakeBG: {
    backgroundColor: "#fff",
    position: "absolute",
    width: 8,
    height: 8,
    zIndex: 1,
    top: 9,
    left: 11,
  },
  iconWrapper: {
    position: "relative",
    zIndex: "2",
    width: "fit-content",
  },
}))

const ImageCard = ({
  src = `${process.env.PUBLIC_URL}/placeholder_img.svg`,
  icon: Icon = Closs,
  rootClass,
  iconClass,
  handleClick,
  isVideo,
}) => {
  const classes = useStyles({ src })

  return (
    <Box className={clsx(classes.root, classes.image, rootClass)}>
      {isVideo && (
        <Box className={classes.youTube}>
          <Box className={classes.fakeBG} />
          <Box className={classes.iconWrapper}>
            <YouTube color="inherit" />
          </Box>
        </Box>
      )}
      {handleClick && (
        <IconButton
          className={clsx(classes.iconButton, iconClass)}
          onClick={handleClick}
        >
          <Icon
            color="inherit"
            fontSize="inherit"
          />
        </IconButton>
      )}
    </Box>
  )
}

export default ImageCard
