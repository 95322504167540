import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import FavoriteIcon from "@material-ui/icons/Favorite"
import MailIcon from "@material-ui/icons/Mail"
import TwitterIcon from "@material-ui/icons/Twitter"
import makeStyles from "@material-ui/styles/makeStyles"
import MapImage1 from "Assets/img/map1.png"
import MapImage2 from "Assets/img/map2.png"
import MapImage3 from "Assets/img/map3.png"
import Comment from "Components/Comment"
import GoProCard from "Components/GoProCard"
import MapCard from "Components/MapCard"
import TopPlacement from "Components/TopPlacement"
import { ROUTES } from "Scenes/constants"
import { Redirect, Route, Switch } from "react-router-dom"
import Button from "Theme/Button"
import Typography from "Theme/Typography"
const { COMMUNITY_FAVORITES } = ROUTES

const { MAPS, INDEX } = ROUTES

const maps = [
  {
    id: "map1",
    title: "Map1",
    description: "Description1",
    image: MapImage1,
    mapSize: "4x4",
    rating: "4.9",
    path: `${INDEX}${MAPS}/1`,
  },
  {
    id: "map2",
    title: "Map2",
    description: "Description2",
    image: MapImage2,
    mapSize: "5x5",
    rating: "2.1",
    path: `${INDEX}${MAPS}/2`,
  },
  {
    id: "map3",
    title: "Map3",
    description: "Description3",
    image: MapImage3,
    mapSize: "6x10",
    rating: "3.3",
    path: `${INDEX}${MAPS}/3`,
  },
  {
    id: "map4",
    title: "Map4",
    description: "Description4",
    image: MapImage1,
    mapSize: "4x4",
    rating: "4.9",
    path: `${INDEX}${MAPS}/4`,
  },
  {
    id: "map5",
    title: "Map5",
    description: "Description5",
    image: MapImage2,
    mapSize: "5x5",
    rating: "2.1",
    path: `${INDEX}${MAPS}/5`,
  },
]

const mapImages = [MapImage1, MapImage2, MapImage3]

const useStyles = makeStyles((theme) => ({
  // footer
  monthText: {
    color: "#37B7DA",
    fontSize: theme.spacing(3),
  },
  footerIcon: {
    opacity: 0.6,
    fontSize: theme.spacing(2.5),
    paddingRight: theme.spacing(0.7),
  },
  // container
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    padding: theme.spacing(6),
    backgroundColor: "#35383E",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  textContainer: {
    paddingLeft: theme.spacing(17),
    paddingRight: theme.spacing(17),
  },
  text: {
    lineHeight: theme.spacing(0.2),
    color: "#ffffff",
    fontSize: theme.spacing(2.4),
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  // coordinator
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderRadius: theme.spacing(9),
  },
  coordinatorLabel: {
    lineHeight: theme.spacing(0.21),
  },
  coordinatorButton: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(0.5),
    backgroundColor: "#2B2E33",
  },
  coordinatorIcon: {
    fontSize: theme.spacing(2),
  },
  // comments
  commentsContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(15),
    paddingRight: theme.spacing(15),
    backgroundColor: "#2B2E33",
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  allCommentsButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: theme.spacing(1.6),
  },
}))


const CommunityFavoritesScene = function({ path }) {
  const classes = useStyles()
  return (
    <Route path={path}>
      <Switch>
         <Route path={path}>
    {/* header */}
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY={10}
    >
      <Typography className={classes.monthText}>February</Typography>
      <Box paddingY={3}>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          Community favorites
        </Typography>
      </Box>
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
        >
          <AccessTimeIcon className={classes.footerIcon} />
          <Typography>June 11, 2014</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginLeft={2}
        >
          <FavoriteIcon className={classes.footerIcon} />
          <Typography>558</Typography>
        </Box>
      </Box>
    </Box>

    {/* container */}
    <Box className={classes.container}>
      {/* coordinator */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="absolute"
        maxWidth={100}
        top={-35}
      >
        <Box
          className={classes.avatar}
          component="img"
          src="https://pickaface.net/gallery/avatar/MissGriffith529ca4c121402.png"
        />
        <Box paddingY={1}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Angu Tamba
          </Typography>
        </Box>
        <Typography className={classes.coordinatorLabel}>Community</Typography>
        <Typography className={classes.coordinatorLabel}>
          Coordinator
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          pt={2}
        >
          <IconButton className={classes.coordinatorButton}>
            <TwitterIcon className={classes.coordinatorIcon} />
          </IconButton>
          <IconButton className={classes.coordinatorButton}>
            <MailIcon className={classes.coordinatorIcon} />
          </IconButton>
        </Box>
      </Box>

      {/* coordinator text */}
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
          Welcome back to yet another (late) ForgeHub's Community Favorite Maps!
          Deja vu, right? Allow me to extend a solemn "oops" on behalf of
          ForgeHub staff for missing the month of August's community favorites
          results. It was all Foge's fault. All of it. Nobody else's. Nobody.
          Else.
          <br /> <br />
          More realistically, Foge is the one who handed us their proverbial
          paddle once we realized we were haphazardly thrashing down - Blam!-
          Creek. Everybody say "thank you, Foge" on three! 1... 2...
        </Typography>
      </Box>

      {/* Nominations */}
      <Box className={classes.textContainer}>
        <Divider className={classes.divider} />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          Nominations
        </Typography>
        <Typography className={classes.text}>
          Anyways, here are the nominations and winners for the month of
          January!
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        marginTop={3}
      >
        {maps.map((map) => (
          <MapCard
            key={map.id}
            title={map.title}
            description={map.description}
            mapSize={map.mapSize}
            rating={map.rating}
            image={map.image}
            path={map.path}
          />
        ))}
        <Box
          margin={2}
          height={200}
          width={200}
        >
          <GoProCard />
        </Box>
      </Box>

      {/* Top placements */}
      <Box className={classes.textContainer}>
        <Divider className={classes.divider} />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          Top Placements
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <TopPlacement
          place="3rd"
          images={mapImages}
          mapSize="4v4"
          mapRating="10"
          pro={true}
        />
        <TopPlacement
          place="2nd"
          images={mapImages}
          mapSize="5v5"
          mapRating="5.5"
        />
        <TopPlacement
          place="1st"
          images={mapImages}
          mapSize="2v2"
          mapRating="3.2"
          pro={true}
        />
      </Box>

      {/* Cowboys */}
      <Box className={classes.textContainer}>
        <Divider className={classes.divider} />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          So Long Space Cowboys
        </Typography>
        <Typography className={classes.text}>
          Congrats to all nominations and winners this month and be sure to tune
          back in next month (or later this month... we'll get better!) to check
          out more Community Favorites.
        </Typography>
      </Box>
    </Box>
    {/* Top Rated comments */}
    <Box className={classes.commentsContainer}>
      <Box
        display="flex"
        flexDirection="column"
        marginBottom={3}
      >
        <Typography
          color="textPrimary"
          variant="subtitle1"
        >
          Top rated comments
        </Typography>
        <Typography>32 comments</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Comment
          name="Olivia Arribas"
          avatar="https://pickaface.net/gallery/avatar/MissGriffith529ca4c121402.png"
          text="Bheap Box."
          votes={5}
          date="2 hours ago at 1:42 PM"
        />
        <Divider className={classes.divider} />
        <Comment
          name="Artiom Tarasov"
          avatar="https://pickaface.net/gallery/avatar/MissGriffith529ca4c121402.png"
          text="Praise the Forerunners! These all look GORGEOUS; getting all sweaty just imagining the tactical slaying brutality that these playgrounds could offer!! Congratulations to those mentioned. LONG LIVE FORGE!!"
          votes={3}
          date="5 hours ago at 2:22 PM"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
      >
        <Button className={classes.allCommentsButton}>Read all comments</Button>
      </Box>
    </Box>
  </Route>
        <Redirect
          exact
          to={`${path}/${COMMUNITY_FAVORITES}`}
        />
      </Switch>
    </Route>
  )
}

export default CommunityFavoritesScene
