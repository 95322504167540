
import SvgIcon from "@material-ui/core/SvgIcon"
import { ReactComponent as SignUpIcon } from "../fontello/icons/svg/ic_sign-up.svg"


const SignUp = ({ ...props }) => (
  <SvgIcon
    component={SignUpIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default SignUp
