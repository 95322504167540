import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "Theme/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { Close } from "Theme/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      5,
    )}px`,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    gap: theme.spacing(5),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  description: {
    textAlign: "left",
    marginRight: theme.spacing(3),
  },
  closeIcon: {
    width: "16px",
    height: "16px",
    color: theme.palette.secondary.main,
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(5.5),
    right: theme.spacing(5.5),
    cursor: "pointer",
  },
}))

function Dialogue({ image, title, description, button, open, onClose }) {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent className={classes.root}>
        <Close
          onClick={onClose}
          className={classes.closeIcon}
        />
        <Box
          position="relative"
          height="200px"
        >
          {image}
        </Box>
        <Box className={classes.content}>
          <Typography
            variant="h4"
            color="textPrimary"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.description}
          >
            {description}
          </Typography>
        </Box>
        <Box>{button}</Box>
      </DialogContent>
    </Dialog>
  )
}

export default Dialogue
