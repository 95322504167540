import React, {
  useMemo } from "react"
import { Box } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import {
  BackgroundSlot,
  DrawerSlot,
  FooterSlot,
  HeaderSlot } from "./slots"


const Styles = withStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flexGrow: 1,
  },
  drawer: {},
  body: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: "1 1",
    overflowX: "hidden",
    maxHeight: "100vh",

    "& > *": {
      zIndex: "2",
    },
  },
}))


const MainLayout = ({
  children,
  bodyElement = Box,
  classes,
}) => {

const Body = useMemo(_ => bodyElement || Box, [bodyElement])

return (
  <Box className={classes.root}>
    <Box className={classes.drawer}>
      <DrawerSlot />
    </Box>

    <Body
      pb={3}
      className={classes.body}
      id="layout"
    >
      <BackgroundSlot />

      <HeaderSlot />

      <Box
        component={"main"}
        className={classes.content}
      >
        {children}
      </Box>

      <FooterSlot />
    </Body>
  </Box>
)}


export default Styles(MainLayout)
export * from "./slots"
