import React, { useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "Theme/TextField"
import Button from "Theme/Button"
import Typography from "Theme/Typography"
import { Close as CloseIcon, Email as EmailIcon, Username as UsernameIcon, Password as PasswordIcon } from "Theme/icons"
import ReCAPTCHA from "react-google-recaptcha"
import { ReactComponent as DialogueImage } from "./images/dialogue-image.svg"
import { useCallback } from "react"
import Dialogue from "Theme/Dialogue"
import useResetPassword from "./hooks"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  closeIcon: {
    width: "16px",
    height: "16px",
    zIndex: 1,
    position: "absolute",
    top: theme.spacing(5.5),
    right: theme.spacing(5.5),
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    gap: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    minWidth: "320px",
    transition: "all 0.2s ease 0s",

    "& input::placeholder": {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  adornmentIcon: {
    overflow: "visible",
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  control: {
    marginRight: theme.spacing(1.5),
    whiteSpace: "nowrap",
  },
}))

const GMAIL_URL = "https://gmail.com"

const LostPasswordForm = function() {
  const classes = useStyles()
  const history = useHistory()
  const [isDialogueOpen, setIsDialogueOpen] = useState(false)
  const { resetPassword, isLoading } = useResetPassword()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: "",
    },
  })
  const captchaRef = useRef(null)

  const onSubmit = useCallback(() => {
    const data = getValues()
    const token = captchaRef.current.getValue()
    if (!token) {
      captchaRef.current.reset()
      return
    }
    resetPassword(data, {
      onSuccess: () => {
        captchaRef.current.reset()
        setIsDialogueOpen(true)
      },
    })
  }, [resetPassword, getValues])

  return isDialogueOpen ? (
    <Dialogue
      image={<DialogueImage />}
      title="Verify your Account"
      description="Thanks for Joining ForgeHub. We just sent a verification link to your email"
      open={isDialogueOpen}
      onClose={() => history.push("")}
      button={
        <Button
          variant="outlined"
          color="secondary"
          className={classes.control}
          onClick={() => {
            window.location.href = GMAIL_URL
          }}
        >
          <Typography
            color="textSecondaryLight"
            variant="h6"
          >
            Go to your <strong>gmail.com</strong> inbox
          </Typography>
          <EmailIcon />
        </Button>
      }
    />
  ) : (
    <Dialog
      maxWidth={false}
      id="id"
      open
    >
      <DialogContent className={classes.root}>
        <CloseIcon
          onClick={() => {
            history.push("")
          }}
          className={classes.closeIcon}
        />
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            color="textPrimary"
          >
            Lost Password
          </Typography>
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              We'll email you a link to reset your password
            </Typography>
            <Controller
              name="login"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Email or Username"
                  variant="outlined"
                  error={!!errors.login}
                  helperText={
                    errors.login ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: "none" }}
                      >
                        Email or Username is required
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <UsernameIcon className={classes.adornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="start"
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
            >
              Reset Password
              <PasswordIcon
                style={{ height: "10px", overflow: "visible" }}
                fontSize="small"
              />
            </Button>
          </Box>
          <ReCAPTCHA
            sitekey={process.env.SITE_KEY}
            ref={captchaRef}
            theme="dark"
          />
        </form>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export default LostPasswordForm
