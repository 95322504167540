import Box from "@material-ui/core/Box"
import Alert from "Components/Alert"
import GameTypesGrid from "Components/GameTypesGrid"
import PlayersCountSlider from "Components/PlayersCountSlider"
import RadioPicker from "Components/RadioPicker"
import Snackbar from "Components/Snackbar"
import * as R from "ramda"
import React, { memo, useState } from "react"
import Button from "Theme/Button"
import * as Icons from "Theme/icons"
import Typography from "Theme/Typography"
import { v4 } from "uuid"

const gameTypesRaw = [
  {
    label: "CTF",
    icon: "Canvas",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Strongholds",
    icon: "Core",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Assault",
    icon: "Infection",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Oddball",
    icon: "Minigame",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "King of the Hill",
    icon: "Minigame",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
  {
    label: "Ricochet",
    icon: "Elimination",
    primarySelected: false,
    secondarySelected: false,
    id: v4(),
  },
]

const optimalPlayersCountOptions = R.pipe(
  R.range,
  R.map((int) => ({
    value: `${int}x${int}`,
    label: `${int}x${int}`,
    selected: false,
    unselected: false,
  })),
)(1, 9)

const isPrimary = R.propEq("primarySelected", true)
const isSecondary = R.propEq("secondarySelected", true)
const setPrimary = R.mergeLeft({
  secondarySelected: false,
  primarySelected: true,
})
const setSecondary = R.mergeLeft({
  secondarySelected: true,
  primarySelected: false,
})
const unsetSecondary = R.mergeLeft({
  secondarySelected: false,
  primarySelected: false,
})
const setDoNotShake = R.mergeLeft({
  doShake: undefined,
})
const setShakeCallback = (label) => R.adjust(R.findIndex(R.propEq("label", label)), setDoNotShake)
const anyPrimary = R.any(isPrimary)
const toCompare = R.pipe(R.trim, R.toLower)
const compare = (value) => (arg) => toCompare(value) === arg
const getIcon = (name) => Icons[name]
const adapter = (valueToCompare) =>
  R.map(
    R.applySpec({
      label: R.prop("label"),
      icon: R.pipe(R.prop("icon"), getIcon),
      doShake: R.pipe(
        R.prop("label"),
        toCompare,
        R.ifElse(compare(valueToCompare), setShakeCallback, () => undefined),
      ), //(obj)
      primarySelected: R.prop("primarySelected"),
      secondarySelected: R.prop("secondarySelected"),
      id: R.prop("id"),
    }),
  )

const DetailsStep = ({ onNext }) => {
  const [alertOpen, setAlertOpen] = useState()
  const [alertMessage, setAlertMessage] = useState()
  const [playersCount, setPlayersCount] = React.useState([4, 12])
  const [optimalPlayers, setOptimalPlayers] = useState(optimalPlayersCountOptions)

  const [customGameTypeInputValue, setCustomGameTypeInputValue] = useState("")
  const [gameTypes, setGameTypes] = useState(adapter(customGameTypeInputValue)(gameTypesRaw))

  const addCustomGameType = (newGameTypeLabel) => {
    // Set the new game type as the selected one as secondary
    // const dataToSet = adapter(newGameTypeLabel)([
    //   ...gameTypes,
    //   {
    //     label: newGameTypeLabel,
    //     icon: "Minigame",
    //     primarySelected: false,
    //     secondarySelected: true,
    //     id: v4(),
    //   },
    // ])
    // setGameTypes(dataToSet)
  }

  const handleGameTypeSelect = (index) => () => {
    const selectedGameTypes = R.ifElse(
      anyPrimary,
      R.adjust(index, R.ifElse(isSecondary, unsetSecondary, setSecondary)),
      R.adjust(index, setPrimary),
    )(gameTypesRaw)

    setGameTypes(adapter(customGameTypeInputValue)(selectedGameTypes))
  }

  // const allowContinue = R.and(
  //   R.or(anyPrimary(gameTypes), anySecondary(gameTypes)),
  //   R.any(isSelected)(optimalPlayers),
  // )

  const allowContinue = true

  const handlePlayersCountChange = (event, newValue) => {
    setPlayersCount(newValue)
  }

  const handleNextStep = () => {
    if (!anyPrimary(gameTypes)) {
      setAlertMessage("Select Primary type")
      setAlertOpen(true)
    } else {
      onNext()
    }
  }

  const handleOptimalPlayersCountClick = (index) => () => {
    const selectedOptimalPlayers = R.pipe(
      R.map(R.mergeRight(R.__, { unselected: true, selected: false })),
      R.adjust(index, R.mergeRight(R.__, { unselected: false, selected: true })),
    )(optimalPlayers)

    setOptimalPlayers(selectedOptimalPlayers)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={4}
      borderRadius={6}
      bgcolor="#36383F"
      minWidth={660}
    >
      <Typography>Player count</Typography>
      <PlayersCountSlider
        value={playersCount}
        onChange={handlePlayersCountChange}
      />
      <Box paddingY={2}>
        <Typography>Optimal player count</Typography>
        {optimalPlayers.map((option, index) => (
          <RadioPicker
            key={option.value}
            {...option}
            onClick={handleOptimalPlayersCountClick(index)}
          />
        ))}
      </Box>
      <Typography>What are the other supported gametypes? Select all that apply.</Typography>
      <GameTypesGrid
        gameTypes={gameTypes}
        onSelect={handleGameTypeSelect}
        canAddNew
        setCustomGameTypeInputValue={setCustomGameTypeInputValue}
        addCustomGameType={addCustomGameType}
      />
      <Box
        display="flex"
        justifyContent="end"
        paddingTop={2}
      >
        <Button
          variant="contained"
          onClick={handleNextStep}
          disabled={!allowContinue}
        >
          Continue
        </Button>
      </Box>
      <Snackbar
        open={alertOpen}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default memo(DetailsStep)
