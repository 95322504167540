import axios from "axios"
import { useMutation } from "react-query"

const useRegister = () => {
  const { mutate: register, isLoading } = useMutation((variables) =>
    axios.post("https://staging.forgehub.com/api2/register", variables).then((res) => res.data),
  )

  return {
    register,
    isLoading,
  }
}

export default useRegister
