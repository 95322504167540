import { Box, Chip, makeStyles, Paper } from "@material-ui/core"
import AchievementIcon from "./icon.svg"

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundImage: `url(${AchievementIcon})`,
    backgroundSize: "cover",
  },
  chip: {
    transform: "translate(-50%, 30%)",
  },
}))

function Achievement({ iconProps, count = 0 }) {
  const classes = useStyles()
  return (
    <Box display="flex">
      <Paper>
        <Box
          height={54}
          width={54}
          maxWidth={54}
          m={0.5}
          className={classes.icon}
          position="relative"
          {...iconProps}
        >
        <Box
          position="absolute"
          bottom={"-20%"}
          left="50%"
          className={classes.chip}
        >
          <Chip
            size="small"
            label={count}
          />
        </Box>
        </Box>
      </Paper>

      {/* <SvgIcon
        component={AchievementIconSVG}
        viewBox="0 0 1000 1000"
        {...iconProps}
      /> */}
    </Box>
  )
}

export default Achievement
