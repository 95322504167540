
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as XboxIcon } from "../fontello/icons/svg/ic_xbox.svg"


const Xbox = ({ ...props }) => (
  <SvgIcon
    component={XboxIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Xbox
