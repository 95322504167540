import { Avatar, Box, makeStyles, Typography } from "@material-ui/core"
import React from "react"


const useStyles = makeStyles((theme) => ({
  capsule: {
    background:
      "linear-gradient(90deg, rgba(48,176,233,1) 0%, rgba(31,123,252,1) 100%)",
    width: "fit-content",
    padding: theme.spacing(0.125, 1),
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    marginLeft: theme.spacing(1),
  },
}))

function UserCard({ src, alt, name, description }) {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      p={2}
    >
      <Box pt={0.25}>
        <Avatar
          alt={alt}
          src={src}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        ml={1}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography variant="subtitle1">
            {name}
          </Typography>
          <Box className={classes.capsule}>
            <Typography variant="subtitle1">
              Pro
            </Typography>
          </Box>
        </Box>
        <Typography>{ description }</Typography>
      </Box>
    </Box>
  )
}

export default UserCard
