import { Route, Switch } from "react-router-dom"
import LostPasswordForm from "Components/LostPasswordForm"
import SetPasswordForm from "Components/SetPasswordForm"
import { ROUTES } from "Scenes/constants"

const ResetPasswordScene = function({ path }) {
  return (
    <Route path={path}>
      <Switch>
        <Route
          exact
          path={`${path}`}
        >
          <LostPasswordForm />
        </Route>

        <Route path={`${path}/${ROUTES.SET_PASS}`}>
          <SetPasswordForm />
        </Route>
      </Switch>
    </Route>
  )
}

export default ResetPasswordScene
