
import SvgIcon from "Theme/SvgIcon"
import { ReactComponent as HomeIcon } from "../fontello/icons/svg/ic_home.svg"


const Home = ({ ...props }) => (
  <SvgIcon
    component={HomeIcon}
    viewBox="0 0 1000 1000"
    {...props}
  />
)


export default Home
