import { Slider, withStyles } from "@material-ui/core"

const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 4,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#ffffff",
    border: `9px solid ${theme.palette.secondary.main}`,
    marginTop: -10,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 4,
    opacity: 0.5,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundColor: "#2C2E34",
    opacity: 1,
  },
}))(Slider)

export default CustomSlider
