import { Box, withStyles } from "@material-ui/core"

const Styles = withStyles(theme => ({
  icons: {
    color: theme.palette.text.tertiary,
    fontSize: 20,
    "& svg:nth-child(n)": {
      marginRight: theme.spacing(3),
    },

  },

}))

const IconGroup = ({ classes, children }) => (
  <Box
    mt={1}
    display="flex"
    className={classes.icons}
  >
    {children}
  </Box>
)

export default Styles(IconGroup)
